import classNames from 'classnames';
import React from 'react';

const GroupRadio = ({ name, children, value, onChange, disabled, orientation }) => {
    const classes = classNames('group-radio', {
        [`group-radio--${orientation}`]: orientation,
    });

    const handleChange = (event) => {
        onChange?.(event);
    };

    return (
        <div className={classes} name={name}>
            {
                !value ?
                    React.Children.map(children, (child) => React.cloneElement(child, { name, onChange: handleChange, disabled }))
                    : React.Children.map(children, (child) => React.cloneElement(child, { name, onChange: handleChange, disabled, checked: child.props.value === value }))
            }
        </div>
    );
};
export default GroupRadio;