import classNames from "classnames";
import TextField from "../../atoms/text-field";
import { Grid } from "../../templates/grid";

export const BuyDate = ({ className }) => {
    const classes = classNames('buy-date', {
        [`${className}`]: className
    });
    return (
        <Grid className={classes} columns={2}>
            <div className="buy-date__date">
                <span className="buy-date__date__title">Fecha de la compra</span>
                <TextField
                    className="buy-date__date__input"
                    label="Fecha de la compra"
                    placeholder="dd/mm/aaaa"
                    leftIcon={{
                        icon: 'icon-calendar',
                    }} />
            </div>
            <div className="buy-date__time">
                <span className="buy-date__time__title">Hora de la compra</span>
                <TextField className="buy-date__time__input" label="Hora de la compra" placeholder="hh:mm" leftIcon={{
                    icon: 'icon-clock',
                }} />
            </div>
            <div className="buy-date__money">
                <span className="buy-date__money__title">Monto de la compra</span>
                <TextField className="buy-date__money__input" label="Monto de la compra" placeholder="$" leftIcon={{
                    icon: 'icon-cash',
                }} />
            </div>
            <span className="buy-date__currency">COP</span>
        </Grid>
    )
};