import classNames from "classnames";
import { ReactComponent as PdlItemImage } from "../../../resources/images/pdl-item.svg";
import { useEffect, useRef } from "react";

export const PdlItem = ({ index, name, role, quatityOfStars = 0, width = 144, height = 86 }) => {

    const itemRef = useRef(null);
    const classes = classNames('pdl-item', {
        [`pdl-item--${role}`]: role,
        [`pdl-item-no-index`]: !index,
    });

    useEffect(() => {
        const updateStars = (quantity) => {
            const allVectors = itemRef?.current.querySelectorAll('[id*="Vector"]');
            allVectors.forEach((element) => {
                element.classList.add('inactive');
                element.classList.remove('active');
            });

            if (quantity < 6) {
                for (let i = 0; i < quantity; i++) {
                    const element = itemRef?.current?.querySelector(`#Vector_${12 - i}`);
                    if (element) {
                        element.classList.remove('inactive');
                        element.classList.add('active');
                    }
                }
            } else {
                allVectors.forEach((element) => {
                    element.classList.remove('inactive');
                    element.classList.add('active');
                });
            }
        };

        updateStars(quatityOfStars);
    }, [quatityOfStars]);

    return (
        <li className={classes} ref={itemRef} style={{
            width: `${width}px`,
            height: `${height}px`,
        }}>
            {index && <div className="pdl-item__header">
                <span className="pdl-item__header-index">{index}.</span>
                <span className="pdl-item__header-name">{name}</span>
            </div>}
            <PdlItemImage className="pdl-item__body" width={width} height={height}/>
        </li>
    );
};