import { useContext } from "react";
import { ReactComponent as TimeManagementMedal } from "../../../resources/images/time-management-medal.svg";
import { UserContext } from "../../../middleware/providers/user-context";
import Paragraph from "../../atoms/paragraph";
import MedalSwitch from "../../molecules/medal-switch";
import { ROLES } from "../../../middleware/utils/constants";

export const RoomsMedalList = ({ hostWinners, medal }) => {
    const { user } = useContext(UserContext);

    return (<div className="assignation-meda-list">
        <div className="assignation-meda-list__info">
            <TimeManagementMedal className={user?.role} width="33.75" height="29.9" />
            <Paragraph size="s" align="center">
                Presiona SÍ, para otorgar la medalla en equipo de <b>Justo a Tiempo</b> equipo; y NO, en caso contrario.
            </Paragraph>
        </div>
        {hostWinners?.[0]?.challenges.map((host, index) => index < 3 && <MedalSwitch name={host.nameChallenge} disabled={index > 0} onClick={(value) => {
        }} />)}
    </div>);
};