import { useContext, useState } from "react";
import { Grid } from "../../templates/grid";
import CustomDatePicker from "../date-picker";
import { UserContext } from "../../../middleware/providers/user-context";
import { formatDateOf } from "../../../middleware/utils/formatDate";

export const GridDatePicker = ({ selectedValues }) => {
    const { user } = useContext(UserContext);

    const [isDatePickerOpen, setIsDatePickerOpen] = useState([{
        isOpen: false,
        date: new Date(),
        dateSelected: false,
    }, {
        isOpen: false,
        time: new Date(),
        timeSelected: false,
        isTimePicker: true,
    },
    {
        isOpen: false,
        date: new Date(),
        dateSelected: false,
    }, {
        isOpen: false,
        time: new Date(),
        timeSelected: false,
        isTimePicker: true,
    },
    {
        isOpen: false,
        date: new Date(),
        dateSelected: false,
    }, {
        isOpen: false,
        time: new Date(),
        timeSelected: false,
        isTimePicker: true,
    },
    {
        isOpen: false,
        date: new Date(),
        dateSelected: false,
    }, {
        isOpen: false,
        time: new Date(),
        timeSelected: false,
        isTimePicker: true,
    },
    ]);
    const [indexSelected, setIndexSelected] = useState(null);

    const handleDateChange = (date) => {
        console.log('date', date);
        const tempIsDatePickerOpen = isDatePickerOpen.map((datePicker, i) => {
            if (i === indexSelected) {
                let selected = {};
                if (isDatePickerOpen[indexSelected]?.isTimePicker) {
                    selected = {
                        timeSelected: true,
                    }
                } else {
                    selected = {
                        dateSelected: true,
                    }
                }

                return {
                    ...datePicker,
                    date: date,
                    ...selected,
                    isOpen: false,
                }
            }
            return datePicker;
        });
        setIsDatePickerOpen(tempIsDatePickerOpen);
    };

    const handleSelectedDatePicker = (index) => {
        console.log('index', index);
        const tempIsDatePickerOpen = isDatePickerOpen.map((datePicker, i) => {
            if (i === index) {
                return {
                    ...datePicker,
                    isOpen: true,
                }
            }
            return {
                ...datePicker,
                isOpen: false,
            }
        });
        setIsDatePickerOpen(tempIsDatePickerOpen);
        setIndexSelected(index);
    };

    const handleCloseDatePicker = () => {
        const tempIsDatePickerOpen = isDatePickerOpen.map((datePicker) => {
            return {
                ...datePicker,
                isOpen: false,
            }
        });
        setIsDatePickerOpen(tempIsDatePickerOpen);
    };

    const getMin = () => {
        if (isDatePickerOpen[indexSelected]?.isTimePicker) {
            return;
        }
        return new Date(isDatePickerOpen[indexSelected]?.date.getFullYear(), isDatePickerOpen[indexSelected]?.date.getMonth(), isDatePickerOpen[indexSelected]?.date.getDate() + 8);
    }

    const getMax = () => {
        if (isDatePickerOpen[indexSelected]?.isTimePicker) {
            return;
        }
        return new Date(isDatePickerOpen[indexSelected]?.date.getFullYear(), isDatePickerOpen[indexSelected]?.date.getMonth(), isDatePickerOpen[indexSelected]?.date.getDate() + 15);
    };

    return (
        <>
            <Grid className="grid-date-picker" columns={2}>
                {isDatePickerOpen?.map((datePicker, index) => <>
                    {!datePicker.isTimePicker ? <span className="grid-date-picker__item__date" onClick={() => handleSelectedDatePicker(index)}>
                        {datePicker.dateSelected ? formatDateOf(datePicker?.date.toLocaleDateString('es')) : 'Día/Mes/Año'}
                    </span>
                        :
                        <span className="grid-date-picker__item__time" onClick={() => handleSelectedDatePicker(index)}>
                            {datePicker.timeSelected ? datePicker?.date.toLocaleTimeString('es') : 'Hora'}
                        </span>}
                </>)}
            </Grid >
            <CustomDatePicker
                isOpen={isDatePickerOpen[indexSelected]?.isOpen}
                onSelect={handleDateChange}
                time={isDatePickerOpen[indexSelected]?.date}
                min={getMin()}
                max={getMax()}
                role={user?.role?.indexOf('new') > -1 ? user?.role?.split(' ')?.[1] : user?.role}
                isTimePicker={isDatePickerOpen[indexSelected]?.isTimePicker}
                onCancel={handleCloseDatePicker}
            />
        </>
    );
};