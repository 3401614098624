import React, { useContext, useEffect, useState } from "react";

import Paragraph from "../../atoms/paragraph";
import Button from "../../atoms/button";

import "./index.scss";

import { UserContext } from "../../../middleware/providers/user-context";
import {  ROLES } from "../../../middleware/utils/constants";

import { getCaptainAndHostWithAspirantsByDirector } from "../../../middleware/services/useQueryListOfNewHosts";
const circle = {
  host: "⚪",
  "new host": "⚪",
  coordinator: "🔴",
  "new coordinator": "🔴",
  director: "🟡",
  captain: "🔵",
};
export const ChallengeHonorThird = ({ setStep, step }) => {
  const { user } = useContext(UserContext);

  const [dataDirector, setDataDirector] = useState([]);
  useEffect(() => {
    if (user?._id) {
      fetchData(user?._id);
    }
  }, [user]);
  const fetchData = async (idUser) => {
    const dataDirector = await getCaptainAndHostWithAspirantsByDirector(idUser);
    setDataDirector(dataDirector);
  };

 

  return (
    <>
      <Paragraph size="s" align="justify">
        Es fundamental que cumplas con tu palabra no solo para lograr tus metas
        personales y tu PDL sino también para permitir que todo el equipo ACDC
        pueda realizar con éxito su PDL. De este compromiso de honor depende
        que:
      </Paragraph>
      <Paragraph size="s" align="left">
        <>
          {dataDirector &&
            dataDirector[0]?.children[0].children?.map((item) => (
              <>
                {item.children?.map(
                  (host) =>
                    host.role.startsWith("new host") && (
                      <>
                        {ROLES[host.role]?.circle}
                        <b> {host.name} </b>
                        <br />
                        logres generar hasta USD 80.000 al mes.
                        <br />
                        <br />
                      </>
                    )
                )}
              </>
            ))}
          {dataDirector &&
            dataDirector[0]?.children[0].children?.map((item) => (
              <>
                {ROLES[item.role]?.circle}
                <b> {item.name} </b>
                <br />
                logres generar hasta USD 80.000 al mes.
                <br />
                <br />
              </>
            ))}
          Tu {ROLES[dataDirector[0]?.children[0]?.role]?.circle}
          <b>{dataDirector[0]?.children[0]?.name} </b>
          <br />
          logres generar hasta USD 80.000 al mes.
          <br />
          <br />
          {ROLES[dataDirector[0]?.role]?.circle} <b>{dataDirector[0]?.name} </b>
          <br />
          logres generar hasta USD 80.000 al mes.
          <br />
          <br />
        </>
      </Paragraph>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <Button onClick={() => setStep(step + 1)}>Continuar</Button>
    </>
  );
};
