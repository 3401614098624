import { ReactComponent as PdlBadge } from '../../../resources/images/pdl-badge.svg';
import { PdlItem } from '../../atoms/pdl-item';

const Pdl = ({ role, purpose, destination, legacy, quatityOfStars }) => {
    return (
        <div className={`pdl pdl--${role}`}>
            <PdlItem role={role} quatityOfStars={quatityOfStars} width={192} height={111}/>
            <div className='pdl__purpose'>
                <div className='title  pdl__purpose__title'>
                    <span className='icon icon--purpose'></span>
                    Propósito
                </div>
                <p className='pdl__purpose__text'>
                    {purpose ? purpose : 'Aún no has proporcionado esta información.'}
                </p>
            </div>
            <div className='pdl__destination'>
                <div className='title pdl__destination__title'>
                    <span className='icon icon--destination'></span>
                    Destino
                </div>
                <p className='pdl__destination__text'>
                    {destination ? destination : 'Aún no has proporcionado esta información.'}
                </p>
            </div>
            <div className='pdl__legacy'>
                <div className='title pdl__legacy__title'>
                    <span className='icon icon--legacy'></span>
                    Legado
                </div>
                <p className='pdl__legacy__text'>
                    {legacy ? legacy : 'Aún no has proporcionado esta información.'}
                </p>
            </div>
        </div>
    );
};

export default Pdl;