import classNames from "classnames";

export const ReadingSection = ({ children, style, size }) => {
    const classes = classNames('reading-section', {
        [`reading-section--${size}`]: size,
    });
    return (
        <div className={classes} style={style}>
            <div className="reading-section__content">{children}</div>
        </div>
    )
};