import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../middleware/providers/user-context";
import { useParams } from "react-router-dom";
import Step from "../../molecules/step";
import Paragraph from "../../atoms/paragraph";
import Button from "../../atoms/button";
import Modal from "../../atoms/modal";

import NewCoordinator from "../../../resources/images/new-coordinator.png";
import ToolsScreenCoordinator from "../../../resources/images/tools-screen-coordinator.png";
import NewHost from "../../../resources/images/new-host.png";
import Vector from "../../../resources/images/Vector.png";
import ArrowSmall from "../../../resources/images/Arrow-small.png";
import ConfirmHostGif from "../../../resources/images/confirm-host-gif.png";
import TableNewDirector from "../../../resources/images/table_new_director.png";
import TableNewDirectorAngry from "../../../resources/images/table_new_director_angry.png";

import CalendarDirector from "../../../resources/images/calendar-director.png";
import GoodHandDirector from "../../../resources/images/good-hand-director.png";
import Book from "../../../resources/images/book.png";
import {
  getCandidates,
  getCaptainAndHostWithAspirants,
  getGeneralUserTable,
  getListOfHostWithTheirSelectApplicants,
  postAwardindMedalsToTheRooms,
} from "../../../middleware/services/epaCoordinatorServices";

import {
  getHostWinners,
  saveResponseChallengeEpaRequirements,
} from "../../../middleware/services/common";
import Card from "../../atoms/card";
import { ChallengeHonorFirst } from "./ChallengeHonorFirst";
import { ChallengeHonorSecond } from "./ChallengeHonorSecond";
import { ChallengeHonorThird } from "./ChallengeHonorThird";
import { ChallengeHonorFourth } from "./ChallengeHonorFourth";
import { ChallengeHonorFive } from "./ChallengeHonorFive";
import { ChallengeHonorSix } from "./ChallengeHonorSix";
import { ChallengeHonorSeven } from "./ChallengeHonorSeven";

import { PossibleAvailableDates } from "../../pages/epa/director/PossibleAvailableDates/PossibleAvailableDates";
import { NewCoordinatorsSchedule } from "./NewCoordinatorsSchedule";
import { FinishHonor } from "./FinishHonor";

import { FinishHumildad } from "./FinishHumildad";
import { FinishHambre } from "./FinishHambre";
import { Toggle } from "../../atoms/switch";
import {
  getCaptainAndHostWithAspirantsByDirector,
  QueriesListOfNewHosts,
  QueriesputAddToWhatSapp,
} from "../../../middleware/services/useQueryListOfNewHosts";
import {
  QueriesListOfCoordinatorsWithTherNewHosts,
  QueriesputMarkGiftGiving,
} from "../../../middleware/services/useQueryListOfNewCoordinatorsWithTheirNewHosts";
import Checkbox from "../../atoms/checkbox";
import { QueriesEpaDateSaveDateAvailable } from "../../../middleware/services/useQueryEpaSaveDateAvailableServices";
import { QueriesEpaDateCoordinatingSchedulesById } from "../../../middleware/services/useQueryEpaDateCoordinatingSchedules";

const circle = {
  host: "⚪",
  "new host": "⚪",
  coordinator: "🔴",
  "new coordinator": "🔴",
  director: "🟡",
  captain: "🔵",
};
const DirectorEpaEndDay = ({
  finishChallenge,
  step,
  setStep,
  challenge,
  ...props
}) => {
  const { user, setscheduleParam } = useContext(UserContext);
  const { data } = QueriesEpaDateCoordinatingSchedulesById(user._id);
  console.log(data);
  const mutationEpaDateSaveDateAvailable = QueriesEpaDateSaveDateAvailable(
    user._id
  );
  const { challengeName } = useParams();
  const { data: listOfNewHosts } = QueriesListOfNewHosts(user?._id);
  const [dataDirector, setDataDirector] = useState([]);
  useEffect(() => {
    if (user?._id) {
      fetchData(user?._id);
    }
  }, [user]);
  const fetchData = async (idUser) => {
    const listDirector = await getCaptainAndHostWithAspirantsByDirector(idUser);
    setDataDirector(listDirector);
  };

  console.log("listOfNewHosts");
  console.log(dataDirector);

  const { data: ListOfCoordinatorsWithTherNewHosts } =
    QueriesListOfCoordinatorsWithTherNewHosts(user?._id);
console.log("ListOfCoordinatorsWithTherNewHosts")
    console.log(ListOfCoordinatorsWithTherNewHosts)
  const [modal, setModal] = useState(false);
  const [selectedToggle, setSelectedToggle] = useState("");
  const [selectedGift, setSelectedGift] = useState("");
  const [countToggle, setCountToggle] = useState(0);
  const [countGift, setCountGift] = useState(0);
  const [value, setValue] = useState(false);
  const [accept, setAccept] = useState(false);
  const [acceptDate, setAcceptDate] = useState({
    data: [],
  });
  const [inputDate, setInputDate] = useState({
    date: "",
    hour: "",
    status: true,
  });

  useEffect(() => {
    if (ListOfCoordinatorsWithTherNewHosts) {
      let total = ListOfCoordinatorsWithTherNewHosts.reduce((count, item) => {
        return item.giftGiving === true ? count + 1 : count;
      }, 0);
      setCountGift(total);
    }
  }, [ListOfCoordinatorsWithTherNewHosts]);

  const [openModal, setOpenModal] = useState(false);
  const [testOne, setTestOne] = useState(false);
  const [modalValidate, setModalValidate] = useState(false);
  const [testgifmodal, setTestGifmodal] = useState(false);
  const mutateDate = QueriesputAddToWhatSapp();
  const mutationGift = QueriesputMarkGiftGiving();
  const hadlerToggle = (id) => {
    setSelectedToggle({ id: id, status: true, property: "" });
    setCountToggle(countToggle + 1);
    setValue(true);
  };
  const handlerGift = (gift) => {
    setCountGift(countGift + 1);
    setSelectedGift({
      id: gift?._id,
      status: gift.giftGiving,
      property: "string",
    });
  };
  useEffect(() => {
    if (selectedToggle) {
      mutateDate.mutate({ ...selectedToggle });
    }
  }, [selectedToggle]);
  useEffect(() => {
    if (selectedGift) {
      mutationGift.mutate({ ...selectedGift });
    }
  }, [selectedGift]);

  const [nameCoordinator, setNameCoordinator] = useState("");
  const [idCoordinator, setIdCoordinator] = useState("");
  const handlerAccept = (row, id, name) => {
    setIdCoordinator(id);
    setNameCoordinator(name);
    setInputDate({
      date: row.date,
      hour: row.hour,
      status: true,
    });

    setModal(true);
  };
  const handlerSaveDate = (inputDate) => {
    setAcceptDate({ data: [...acceptDate.data, inputDate] });

    setAccept(true);
    setModal(false);
  };
  const handlerNext = () => {
    mutationEpaDateSaveDateAvailable.mutate(acceptDate);
    setStep(step + 1);
  };
  const handlerEdit = (row) => {
    setscheduleParam(row);
    setStep(step + 3);
  };
  const handlerDeleteDate = (row) => {
    setAccept(false);
    setModal(false);
  };

  const [acceptData, setAcceptData] = useState();
  const [modalSchedule, setModalSchedule] = useState(false);

  return (
    <>
      {/* desafion-Honor */}
      {challengeName === "Desafío Honor" && (
        <>
          <Step
            order={1}
            visibility={step === 1 ? "visible" : "hidden"}
            className="epa-end-day-container"
          >
            <ChallengeHonorFirst setStep={setStep} step={step} />
          </Step>
          <Step
            order={2}
            visibility={step === 2 ? "visible" : "hidden"}
            className="epa-end-day-container"
          >
            <ChallengeHonorSecond setStep={setStep} step={step} />
          </Step>
          <Step
            order={3}
            visibility={step === 3 ? "visible" : "hidden"}
            className="epa-end-day-container"
          >
            <ChallengeHonorThird setStep={setStep} step={step} />
          </Step>
          <Step
            order={4}
            visibility={step === 4 ? "visible" : "hidden"}
            className="epa-end-day-container"
          >
            <ChallengeHonorFourth setStep={setStep} step={step} />
          </Step>
          <Step
            order={5}
            visibility={step === 5 ? "visible" : "hidden"}
            className="epa-end-day-director"
          >
            <ChallengeHonorFive setStep={setStep} step={step} />
          </Step>
          <Step
            order={6}
            visibility={step === 6 ? "visible" : "hidden"}
            className="epa-end-day-director"
          >
            <ChallengeHonorSix
              setOpenModal={setOpenModal}
              setStep={setStep}
              step={step}
            />
          </Step>

          <Step
            order={7}
            visibility={step === 7 ? "visible" : "hidden"}
            className="epa-end-day-director"
          >
            <ChallengeHonorSeven setStep={setStep} step={step} />
          </Step>
          <Modal
            isOpen={openModal}
            className="agreements-honon-ok-modal director"
          >
            <img
              src={GoodHandDirector}
              alt="Good Hand"
              className="hand-honor-director"
              width={142}
              height={142}
            />
            <Paragraph size="s" align="center">
              ¡Excelente!
              <br />
              <br />
              Ahora que ya hiciste el Acuerdo de honor, continúa con el video
              para realizar las actividades de la siguiente pausa.
              <br />
              <ul>
                <li className="li-honor">
                  <i className="i-honor">.</i> <span>Ingreso a la Meet.</span>{" "}
                </li>
                <li className="li-honor">
                  <i className="i-honor">.</i> Definir fecha del EPA.
                </li>
              </ul>
            </Paragraph>

            <Button
              className="honor-button"
              onClick={() => setOpenModal(false)}
              size="small"
            >
              ¡Vale!
            </Button>
          </Modal>
          <Step
            order={8}
            visibility={step === 8 ? "visible" : "hidden"}
            className="epa-end-day-container"
          >
            <span className="title fontWeight">
              Confirmación de fechas para el EPA
            </span>
            <Paragraph size="s" align="center" className="p-size">
              Es momento de definir la fecha y hora del EPA de los
              Coordinadores:
            </Paragraph>
            {data?.map((item, index) => (
              <div className="card-host">
                <div className="card-host-header">
                  <img
                    src={NewCoordinator}
                    alt="new-host"
                    width={80}
                    height={80}
                  />
                  <div className="coordinator-info">
                    <p className="coordinator-name">{item.nameCoordinator}</p>
                    <span className="status nuevo">
                      {circle.coordinator}Nuevo(a) Coordinador(a)
                    </span>
                  </div>
                </div>
                <div className="card-body">
                  <input
                    type="text"
                    className="epa-hungry-date"
                    value={item.schedule[7].date}
                  />
                  <input
                    type="text"
                    className="epa-hungry-hour"
                    value={item.schedule[7].hour}
                  />
                </div>
                <div className="card-btn">
                  <span
                    className="btn-date edit"
                    onClick={() => handlerEdit(item.schedule)}
                  >
                    Editar
                  </span>
                  <span
                    className="btn-date accept"
                    onClick={() =>
                      handlerAccept(
                        item.schedule[index],
                        item?._id,
                        item.nameCoordinator
                      )
                    }
                  >
                    {" "}
                    {idCoordinator === item?._id ? "Aceptado" : "Aceptar"}
                  </span>
                </div>
              </div>
            ))}
            <Button
              className="director"
              onClick={handlerNext}
              disabled={acceptDate.data.length >= 2 ? false : true}
            >
              Continuar EPA
            </Button>
          </Step>
          <Modal isOpen={modal} className="agreements-modal">
            <br />
            <br />
            <Paragraph size="xs" align="center">
              ¿Estás seguro(a) que puedes asistir al EPA de tu Coordinador(a)?
              <br />
              <br />
              {nameCoordinator}
              <br />
              <br />
              <div className="card-body">
                <input
                  type="text"
                  className="epa-hungry-date"
                  value={inputDate.date}
                />
                <input
                  type="text"
                  className="epa-hungry-hour"
                  value={inputDate.hour}
                />
              </div>
            </Paragraph>
            <div className="card-btn">
              <Button
                className=" accept-modal "
                onClick={() => handlerSaveDate(inputDate)}
                size="small"
              >
                Si
              </Button>
              <Button
                className=" accept-modal "
                onClick={() => handlerDeleteDate(inputDate)}
                size="small"
              >
                No
              </Button>
            </div>
          </Modal>
          <Step
            order={9}
            visibility={step === 9 ? "visible" : "hidden"}
            className="epa-end-day-container"
          >
            <NewCoordinatorsSchedule
              setModalSchedule={setModalSchedule}
              setAcceptData={setAcceptData}
              setStep={setStep}
              step={step}
            />
          </Step>

          <Modal isOpen={modalSchedule} className="agreements-modal">
            {acceptData?.map((event, index) => (
              <>
                <Paragraph size="s" align="center" className="event-modal-text">
                  <span onClick={() => setModalSchedule(false)}>
                    {event.day}
                  </span>
                </Paragraph>
              </>
            ))}
          </Modal>

          <Step
            order={10}
            visibility={step === 10 ? "visible" : "hidden"}
            className="epa-end-day-container"
          >
            <FinishHonor finishChallenge={finishChallenge} />
          </Step>
          <Step
            order={11}
            visibility={step === 11 ? "visible" : "hidden"}
            className="epa-end-day-container"
          >
            <PossibleAvailableDates setStep={setStep} step={step} />
          </Step>
        </>
      )}
      {/* desafion-Humildad */}
      {challengeName === "Desafío Humildad" && (
        <>
          <Step
            order={1}
            visibility={step === 1 ? "visible" : "hidden"}
            className="epa-end-day-container"
          >
            <br />
            <br />
            <img
              src={CalendarDirector}
              alt="new-host"
              width={129}
              height={129}
            />
            <Paragraph size="s" align="center">
              Ahora que ya agendaste la fecha y hora del EPA, continúa en la
              Meet con tu equipo para realizar las actividades de las siguientes
              pausas del video.
            </Paragraph>
            <br />
            <Paragraph size="s" align="center">
              En este momento los Nuevos Coordinadores, deben estar realizando
              la invitación y agregando al grupo de whatsapp a los Nuevos(as)
              Anfitriones(as)
            </Paragraph>
            <br />
            <br />
            <Button size="small" style="ghost">
              Ver video
            </Button>
            <Button onClick={() => setStep(step + 1)}>Continuar</Button>
          </Step>
          <Step
            order={2}
            visibility={step === 2 ? "visible" : "hidden"}
            className="epa-end-day-container"
          >
            <Paragraph size="s" align="center">
              A continuación deberás activar el switch de cada Nuevo(a)
              Anfitrión(a) para confirmar que ya se encuentran agregados al
              grupo de WhatsApp correspondiente.{" "}
            </Paragraph>

            {dataDirector?.map((captain) =>
              captain.children.map((director) =>
                director.children.map((coordinator) => (
                    coordinator.children.map((host) => host.role.startsWith("new")&&
                        <div className="user-card">
                    <div className="user-info">
                      <img
                        src={NewHost}
                        alt="new-host"
                        width={80}
                        height={80}
                        className="user-avatar"
                      />

                      <div className="user-details">
                        <span className="user-name">{host.name}</span>
                        <div className="user-status">
                          <span className="status-dot"></span>
                          <span className="status-text">
                            Nuevo(a) Anfitrión(a)
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="toggle-switch">
                      <Toggle
                        value={value}
                        className="switch-input"
                        onClick={() => hadlerToggle(host?._id)}
                      />
                    </div>
                  </div>
                    )
                  
                ))
              )
            )}
            {}
            <Button
              className="director"
              onClick={() => setStep(step + 1)}
             // disabled={countToggle === listOfNewHosts?.length ? false : true}
            >
              Continuar EPA
            </Button>
          </Step>
          <Step
            order={3}
            visibility={step === 3 ? "visible" : "hidden"}
            className="epa-end-day-container"
          >
            <br />
            <div>
              <img src={TableNewDirector} />
              <Paragraph align="left" className="gift-text" size="s">
                “No existen secretos para alcanzar el éxito. Este es el
                resultado de la preparación, el trabajo arduo y de aprender de
                los fracasos”.
              </Paragraph>
            </div>
            <div className="phrase-container">
              <img src={Book} alt="Book" className="book" />
              <Paragraph className="gift-text-phrase" size="s" align="left">
                Fragmento tomado del Libro
                <br />
                <b>Lanzando una Revolución sobre el Liderazgo.</b>
              </Paragraph>
            </div>
            <br />
            <br />
            <Button>Ver video </Button>
            <Button
              size="small"
              style="ghost"
              onClick={() => setStep(step + 1)}
            >
              Continuar
            </Button>
          </Step>
          <Step
            order={4}
            visibility={step === 4 ? "visible" : "hidden"}
            className="epa-end-day-container"
          >
            <span className="title">Confirmación Compras y obsequios</span>
            <div className="avatar-host">
              <img src={ConfirmHostGif} className="avatar-host-img" />
            </div>

            <span className="title-gift-span">¡Nuevo(a) Director(a)!</span>
            <Paragraph align="center" size="s" className="p-size">
              En este momento los dos Nuevos Anfitriones de cada Nuevo(a)
              Coordinador(a) deben comprar el contenido de ACDC y recibir el
              obsequio 🎁 que deben comprar los Nuevos(as) Coordinadores(as).
            </Paragraph>
            <Paragraph align="center" size="s">
              En la siguiente pantalla podrás confirmar cuando las compras de
              los Nuevos(a) Coordinadores(as) y los Nuevos(as) Anfitriones (as)
              se hayan realizado .
            </Paragraph>
            <br />
            <Button size="small" style="ghost">
              Manual de compra
            </Button>
            <Button className="btn-gift-2" onClick={() => setStep(step + 1)}>
              Continuar
            </Button>
          </Step>
          <Step
            order={5}
            visibility={step === 5 ? "visible" : "hidden"}
            className="epa-end-day-container"
          >
            {ListOfCoordinatorsWithTherNewHosts &&
              ListOfCoordinatorsWithTherNewHosts?.map((coordinator) => coordinator.role.startsWith("new coordinator") && coordinator.paymentForAudiovisualContentAcdc==="APPROVED" && coordinator.paymentLevelsAcdcPlatform==="APPROVED"
              &&(
                <>
                  <div className="user-list-checkbox">
                    <div className="user-card-checkbox">
                      <div className="user-info-checkbox">
                        <img
                          src={NewCoordinator}
                          alt="new-host"
                          width={80}
                          height={80}
                          className="user-avatar-checkbox"
                        />
                        <div className="user-details-checkbox">
                          <span className="user-name-checkbox">
                            {coordinator.name}
                          </span>
                          <p className="user-action-checkbox">
                            Dió los obsequios{" "}
                            <img
                              src={Vector}
                              className="action-icon-checkbox"
                            />
                            <img
                              src={Vector}
                              className="action-icon-checkbox"
                            />
                          </p>
                        </div>
                      </div>

                      <Checkbox
                        className="user-status-checkbox-3"
                        checked={coordinator.giftGiving}
                        onChange={() => handlerGift(coordinator)}
                      />
                    </div>
                    {coordinator.hosts?.map(
                      (host) => host.role==="new host" &&(
                          <>
                            <div className="user-card-checkbox">
                              <div className="user-info-checkbox">
                                <img
                                  src={NewHost}
                                  alt="new-host"
                                  width={80}
                                  height={80}
                                  className="user-avatar-checkbox"
                                />
                                <div className="user-details-checkbox">
                                  <span className="user-name-checkbox">
                                    {host.name}
                                  </span>
                                  <p className="user-action-checkbox-2">
                                    Se unió al Canal de Youtube
                                  </p>
                                </div>
                              </div>

                              <div className="user-status-checkbox-2">
                                <div className="status-check-checkbox-3">
                                  &#10004;
                                </div>
                              </div>
                            </div>
                          </>
                        )
                    )}
                  </div>
                </>
              ))}
            <Button
              className="director"
              onClick={() => setTestGifmodal(true)}
              disabled={
                countGift === ListOfCoordinatorsWithTherNewHosts?.length
                  ? false
                  : true
              }
            >
              Continuar EPA
            </Button>
          </Step>
          <Modal
            isOpen={testgifmodal}
            className="agreements-gif-modal director"
          >
            <div className="avatar-modal-host">
              <img src={ConfirmHostGif} />
            </div>
            <span className="title-gift-span">¡Super!</span>
            <br />
            <Paragraph size="s" align="center">
              Los Anfitriones ya tienen acceso al Contenido y a la Plataforma
              ACDC con el regalo 🎁 que les hizo el Nuevo(a) Coordinador(a),
              ahora ell@s pueden acceder a los siguientes niveles para aplicar
              la estrategia. Mañana iniciarán con el día de Inducción junto con
              tu equipo.
            </Paragraph>
            <br />

            <Button
              className="btn-gift-0"
              onClick={() => {
                setTestGifmodal(false);
                setStep(step + 1);
              }}
            >
              ¡Vale!
            </Button>
          </Modal>
          <Step
            order={6}
            visibility={step === 6 ? "visible" : "hidden"}
            className="epa-end-day-container"
          >
            <FinishHumildad finishChallenge={finishChallenge} />
          </Step>
        </>
      )}

      {challengeName === "Desafío Hambre" && (
        <>
          <Step
            order={1}
            visibility={step === 1 ? "visible" : "hidden"}
            className="epa-end-day-container"
          >
            <br />
            <div>
              <img src={TableNewDirectorAngry} />
              <Paragraph align="left" className="text-hungry" size="s">
                “Un líder eficaz no es aquel que es amado o admirado, sino que
                tiene seguidores que hacen las cosas correctas y se hace
                responsable por los resultados obtenidos en las vidas de las
                personas a las que dirige y forma.”
              </Paragraph>
            </div>

            <div className="phrase-container">
              <img src={Book} alt="Book" className="book" />
              <Paragraph size="s" align="left" className="gift-text-phrase ">
                Fragmento tomado del Libro
                <br />
                <b>Lanzando una Revolución sobre el Liderazgo.</b>
              </Paragraph>
            </div>
            <Button onClick={() => setStep(step + 1)}>Continuar</Button>
          </Step>

          <Step
            order={2}
            visibility={step === 2 ? "visible" : "hidden"}
            className="epa-end-day-container"
          >
            <span className="title">¡Momento de los agradecimientos!</span>
            <img src={ToolsScreenCoordinator} alt="ToolsScreenCoordinator" />
            <Paragraph align="center" size="s">
              💡 Nuevo(a) Director(a), es momento de que los Nuevos Anfitriones
              comiencen a agrecer a sus Validadores.
            </Paragraph>
            <Paragraph align="center" size="s">
              Mañana en el Día de Inducción, iniciarán la Convocatoria.
              Requerirán el link de registro al EPA.
            </Paragraph>
            <Paragraph align="center" size="s">
              A continuación vas a generar el link de la convocatoria que usarán
              para aplicar el ABC. Recuerda que los Anfitriones podrán acceder
              al enlace que generaste desde su perfil en la Plataforma ACDC.
            </Paragraph>
            <br />
            <br />
            <Button className="director" onClick={() => setTestOne(true)}>
              Generar Links
            </Button>
          </Step>
          <Modal isOpen={testOne} className="agreements-modal ">
            <br />
            <span className="title">¡Super!</span>
            <br />
            <Paragraph size="s" align="center">
              El link ha sido generado.
            </Paragraph>
            <br />
            <Paragraph size="s" align="center">
              Tus Nuevos Anfitriones podrán acceder a él para convocar a sus
              validadores y que se agenden al EPA en la fecha y hora acordadas.
            </Paragraph>
            <br />
            <br />
            <Button
              className="btn-gift small"
              onClick={() => {
                setStep(step + 1);
                setTestOne(false);
              }}
            >
              ¡Vale!
            </Button>
          </Modal>
          <Step
            order={3}
            visibility={step === 3 ? "visible" : "hidden"}
            className="epa-end-day-container"
          >
            <br />
            {ListOfCoordinatorsWithTherNewHosts &&
              ListOfCoordinatorsWithTherNewHosts.map((item) => (
                <>
                  <div className="user-container-hungry">
                    <div className="user-card-hungry">
                      <div className="user-header-hungry">
                        <img
                          src={NewCoordinator}
                          alt="Paula Inés"
                          width={80}
                          height={80}
                          className="avatar-hambre"
                        />
                        <div className="user-details-hungry">
                          <span className="user-name-hungry">{item.name}</span>
                          <p className="user-role-hungry">
                            Nuevo(a) Coordinador(a)
                          </p>
                        </div>
                      </div>
                      <div className="arrow-hungry">
                        <img src={ArrowSmall} className="arrow-small" />
                      </div>
                    </div>
                    {item.selectedDatesForEpa?.map((epeDate) => (
                      <>
                        <div className="epa-section-hungry">
                          <div className="epa-title-hungry">EPA:</div>
                          <div className="card-body-epa">
                            <input
                              type="text"
                              className="epa-hungry-date"
                              value={epeDate.date}
                              disabled
                            />
                            <input
                              type="text"
                              className="epa-hungry-hour"
                              value={epeDate.hour}
                              disabled
                            />
                          </div>
                        </div>
                      </>
                    ))}

                    {item.hosts?.map((host) => (
                      <>
                        <div className="user-card-h">
                          <div className="user-header-h">
                            <img
                              src={NewHost}
                              alt="Luis David"
                              width={80}
                              height={80}
                              className="avatar-h"
                            />
                            <div className="user-info-h">
                              <span className="user-name-h">{host.name}</span>
                              <p className="user-role-h">
                                Nuevo(a) Anfitrión(a)
                              </p>
                            </div>
                          </div>

                          <div className="task-section-h">
                            <div className="task-h">
                              <span className="tastk-span">
                                Entrenamiento completado
                              </span>

                              <div className="checkbox-container-h">
                                <div className="user-status-checkbox">
                                  <div className="status-check-checkbox">
                                    &#10004;
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="task-h">
                              <span className="tastk-span">
                                Agradecimientos Realizados:
                              </span>
                              <span className="task-count-h">4</span>
                            </div>
                          </div>

                          <button className="validate-button-h">
                            Ver validadores
                          </button>
                        </div>
                        <br />
                      </>
                    ))}
                  </div>
                </>
              ))}

            <Button onClick={() => setModalValidate(true)}>Siguiente</Button>
          </Step>

          <Modal isOpen={modalValidate} className="agreements-checkbox-modal">
            <div className="lista-validadores">
              <div
                className="validador"
                onClick={() => {
                  setStep(step + 1);
                  setModalValidate(false);
                }}
              >
                <div className="checkbox-validador"></div>
                <div className="nombre-validador">Nombre Validador #1</div>
              </div>
              <div
                className="validador"
                onClick={() => {
                  setStep(step + 1);
                  setModalValidate(false);
                }}
              >
                <div className="checkbox-validador"></div>
                <div className="nombre-validador">Nombre Validador #2</div>
              </div>
              <div
                className="validador"
                onClick={() => {
                  setStep(step + 1);
                  setModalValidate(false);
                }}
              >
                <div className="checkbox-validador"></div>
                <div className="nombre-validador">Nombre Validador #3</div>
              </div>
              <div
                className="validador"
                onClick={() => {
                  setStep(step + 1);
                  setModalValidate(false);
                }}
              >
                <div className="checkbox-validador"></div>
                <div className="nombre-validador">Nombre Validador #4</div>
              </div>
              <div
                className="validador"
                onClick={() => {
                  setStep(step + 1);
                  setModalValidate(false);
                }}
              >
                <div className="checkbox-validador"></div>
                <div className="nombre-validador">Nombre Validador #5</div>
              </div>
              <div
                className="validador"
                onClick={() => {
                  setStep(step + 1);
                  setModalValidate(false);
                }}
              >
                <div className="checkbox-validador"></div>
                <div className="nombre-validador">Nombre Validador #6</div>
              </div>
            </div>
          </Modal>
          <Step
            order={4}
            visibility={step === 4 ? "visible" : "hidden"}
            className="epa-end-day-container"
          >
            <FinishHambre finishChallenge={finishChallenge} />
          </Step>
        </>
      )}
    </>
  );
};

export default DirectorEpaEndDay;
