import Background from "../../templates/background";
import { ReactComponent as MainLogo } from '../../../resources/images/main-logo.svg';
import { useNavigate, useParams } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import axios from "axios";
import { API, ROLES } from "../../../middleware/utils/constants";
import Menu from "../../organisms/menu";
import Tab from '../../atoms/tab';
import Tabs from '../../molecules/tabs';
import { UserContext } from "../../../middleware/providers/user-context";
import ValidatorInfo from "../../molecules/validator-info";
import RegisterReference from "../../molecules/register-reference";
import Modal from "../../atoms/modal";
import Paragraph from "../../atoms/paragraph";
import Button from "../../atoms/button";
import { TabsContext } from "../../../middleware/providers/tabs-context";
import CandidateProgress from "../../molecules/candidate-progress";

const ValidatorInfoPage = () => {

    const { index, idChallenge } = useParams();
    const { user } = useContext(UserContext);
    const navigate = useNavigate();

    const [menuShow, setMenuShow] = useState(false);
    const [validator, setValidator] = useState({});
    const { activeTab, setActiveTab } = useContext(TabsContext);
    const [challenges, setChallenges] = useState([]);
    const [activeChallenge, setActiveChallenge] = useState(null);
    const [path, setPath] = useState('');

    const handleMouseMove = (e) => {
        e.stopPropagation();
        const mouseY = e.clientY;
        const windowHeight = window.innerHeight;

        if (mouseY < windowHeight / 2) {
            setMenuShow(false);
        }
    };

    useEffect(() => {
        setPath(window.location.pathname.split('/')[1]);
        if (index && user) {
            setValidator(user?.personalReferences[index - 1]);
        }
    });

    useEffect(() => {
        axios.get(`${API.PROGRESS_POINT.url}`).then((response) => {
            const data = response.data.data;
            if (data?.message !== 'This user has no challenges yet') {
                setChallenges(data);
                const active = data?.find((challenge, index) => {
                    if (data[index - 1]?.status && !challenge?.status) {
                        return challenge;
                    }
                }) || data[0];

                setActiveChallenge(active);
            }
        }).catch((error) => {
            console.warn(error);
            setChallenges([]);
        });
    }, []);

    return (
        <Background className="validator-info-page" overlay={false}>
            <header>
                <span className="icon-chevron-back icon left" onClick={() => navigate(`/missions/${index}`)} onKeyDown={() => { }}></span>
                <div className={`logo logo--${(user?.role) || 'candidate'}`}>
                    <MainLogo />
                </div>
                <span className="icon-btn-menu icon right" onClick={() => setMenuShow(!menuShow)} onKeyDown={() => { }}></span>
            </header>
            <h4 className="title">Misiones Diarias</h4>
            {path !== 'register-reference' && <Tabs >
                <Tab active={activeTab === 0} onClick={() => {
                    setActiveTab(0);
                    navigate(`/missions`);
                }}>Mi Progreso</Tab>
                {
                    (user?.role === 'candidate' || user?.role === 'new candidate') && ['filter day', 'alignment day', 'epa day', 'end of epa'].find((day) => activeChallenge?.day === day) &&
                    <Tab active={activeTab === 1} onClick={() => {
                        setActiveTab(1);
                    }}>Mis Validadores</Tab>
                }
                {
                    user?.role === 'host' && ['induction day', 'rules day'].find((day) => activeChallenge?.day === day) &&
                    <Tab active={activeTab === 1} onClick={() => {
                        setActiveTab(1);
                        navigate(`/missions`);
                    }}>Mis Validadores</Tab>
                }
                {
                    (user?.role === 'host' || user?.role === 'new host') && !['induction day', 'rules day'].find((day) => activeChallenge?.day === day) &&
                    <Tab active={activeTab === 1} onClick={() => {
                        setActiveTab(1);
                    }}>Mis Aspirantes</Tab>
                }
                {
                    (user?.role === 'coordinator' || user?.role === 'new coordinator') &&
                    <Tab active={activeTab === 1} onClick={() => {
                        setActiveTab(1);
                    }}>Mis Anfitriones</Tab>
                }
                {
                    (user?.role === 'coordinator' || user?.role === 'new director') &&
                    <Tab active={activeTab === 1} onClick={() => {
                        setActiveTab(1);
                    }}>Mis Anfitriones</Tab>
                }
                <Tab active={activeTab === 2} onClick={() => {
                    setActiveTab(2);
                    navigate(`/missions/2`);
                }}>Mi Desempeño</Tab>
            </Tabs>}
            {path === 'validator-info' && validator && <ValidatorInfo
                name={`${validator?.name} ${validator?.lastName || ''}`}
                role={validator?.training}
                nameRole={ROLES[validator?.training]?.roleName || 'Validador(a)'}
                range={7}
                token={validator?.token}
                idChallenge={idChallenge}
                idUser={validator?._id}
                sent={validator?.sent}
                perceptionValidator={validator?.perceptionValidator}
                featuredSkills={validator?.featuredSkills}
                skills={validator?.skills}
                other={validator?.other}
                experience={validator?.experience}
                status={validator?.status}
                processStatus={user?.personalReferences?.[index - 1]?.processStatus}
                challenge={activeChallenge}
                purpose={validator?.purpose}
                destination={validator?.destination}
                legacy={validator?.legacy}
            />}
            {path === 'register-reference' && <RegisterReference />}
            {path === 'progress' && <CandidateProgress />}
            <Menu role={(user?.role)} isShow={menuShow} onTouchMove={handleMouseMove} onClick={() => setMenuShow(false)} />
            <Modal isOpen={false}>
                <span className="title">¿Estás seguro(a) que deseas descartar este Validador(a)?</span>
                <Paragraph size="s" align="center">Dejará de ser parte de tus validadores</Paragraph>
                <div className="buttons">
                    <Button size="small">Sí</Button>
                    <Button size="small">No</Button>
                </div>
            </Modal>
        </Background>
    );
};

export default ValidatorInfoPage;