import React, { memo, useContext, useEffect, useRef, useState } from "react";
import { UserContext } from "../../../middleware/providers/user-context";
import { componentMap, ROLES } from "../../../middleware/utils/constants";
import * as SERVICES from "../../../middleware/services/useQueryListOfNewHosts";
import { v4 as uuidv4 } from "uuid";

const AuxComponent = ({ brick, renderMap, idUser, idChallenge }) => {
  const [bricks, setBricks] = useState([]);

  const fetchData = async () => {
    const data = await SERVICES[brick.bricks]?.(idUser, idChallenge);
    setBricks(data);
  };

  useEffect(() => {
    fetchData();
  }, [brick]);

  const Component = componentMap[brick.type];
  return (
    <Component {...brick.props}>
      {bricks ? bricks?.map((brick) => renderMap(brick)) : brick.children}
    </Component>
  );
};

export const DynamicChallenge = ({
  challenge,
  step,
  setStep,
  setChangeDay,
  setChangeRole,
  finishChallenge,
}) => {
  const { user } = useContext(UserContext);
  const [updateBricks, setUpdateBricks] = useState("");
  const [selectedId, setSelectedId] = useState("");

  const currentChallenge =
    ROLES[user?.role].challenges[challenge?.day]?.[
    challenge?.typeChalleges - 1
    ];
  const [bricks, setBricks] = useState(currentChallenge?.bricks?.(user?._id));
  const [hasFetched, setHasFetched] = useState(false);

  const dynamicData = useRef(null);
  const elementRef = useRef(null);

  const findEvent = (bricks, id, split) => {
    if (typeof bricks === "string") {
      return bricks;
    }

    return bricks?.map((brickFind) => {
      if (
        [id, split[0]].includes("x") &&
        brickFind.id?.indexOf(brickFind.id) > -1
      ) {
        let object = {};
        if (brickFind.props.items) {
          object["items"] = brickFind.props.items?.map((item) => {
            if (item.id === split[1]) {
              return {
                ...item,
                disabled: item.disabled ? false : true,
              };
            }
            return item;
          });
        }

        return {
          ...brickFind,
          props: {
            ...brickFind?.props,
            isOpen: false,
            ...object,
          },
        };
      }
      if (
        brickFind.id === id ||
        brickFind.id === split[0] ||
        brickFind.id === selectedId
      ) {
        let object = {};
        if (brickFind.type === "listModal" || brickFind.type === "modal") {
          const items = brickFind.props.items?.map((item) => {
            if (split[1] !== "") {
              return {
                ...item,
                disabled: !(item.name === split[1]),
              };
            }

            return item;
          });
          object = {
            ...object,
            isOpen: true,
            items,
          };
        }
        if (brickFind.type === 'packagesInfoModal') {
          object = {
            ...object,
            isOpen: true,
          }
        }
        const value =
          brickFind.id === selectedId && brickFind.type === "textField"
            ? split[1]
            : undefined;
        const counter =
          brickFind.id === "continuePutAddToWhatSapp" &&
            brickFind.type === "button" &&
            split[1] === "true"
            ? brickFind.counter + 1
            : brickFind.counter - 1;
        let disabled =
          brickFind.id === "continuePutAddToWhatSapp" &&
            brickFind.type === "button" &&
            counter === 2
            ? false
            : true;
        if (split[0] === "continueButton") {
          disabled = false;
          console.log("disabled", disabled);
        }

        return {
          ...brickFind,
          props: {
            ...brickFind?.props,
            ...object,
            onClose: () => setUpdateBricks("x"),
            value,
            disabled,
          },
          counter,
        };
      }
      if (brickFind.bricks) {
        brickFind.bricks = findEvent(brickFind.bricks, id, split);
      }

      return brickFind;
    });
  };

  useEffect(() => {
    const split = updateBricks.split("-");
    if (updateBricks !== "") {
      const newBricks = findEvent(bricks, updateBricks, split);

      setBricks(newBricks);
    } else {
      setBricks(currentChallenge?.bricks?.(user?._id));
    }
  }, [updateBricks]);

  const fetchDynamicData = async () => {
    const data = await SERVICES[dynamicData.current]?.(
      user?._id,
      challenge?._id
    );

    setBricks([...bricks, ...data]);
    setHasFetched(true);
  };

  useEffect(() => {
    if (dynamicData.current && !hasFetched) {
      fetchDynamicData();
    }
  }, [dynamicData.current]);

  const renderMap = (brick, index) => {
    const uuid = uuidv4();
    const Component = componentMap[brick.type];
    if (typeof brick === "string") {
      dynamicData.current = brick;
    }
    if (typeof brick?.bricks === "string") {
      return (
        <AuxComponent
          brick={brick}
          renderMap={renderMap}
          idUser={user?._id}
          idChallenge={challenge?._id}
        />
      );
    }
    if (brick.props?.order) {
      const newProps = {
        ...brick.props,
        visibility: brick.props.order === step ? "visible" : "hidden",
      };
      brick.props = newProps;
    }
    if (brick?.action === "next") {
      const newProps = {
        ...brick.props,
        onClick: () => setStep(step + 1),
      };
      brick.props = newProps;
    }
    if (brick?.action === 'nextSimulate') {
      const newProps = {
        ...brick.props,
        onClick: () => {
          const currentRole = Object.keys(ROLES).findIndex((role) => role === user?.role);
          const nextRole = Object.keys(ROLES)[currentRole + 1].split(' ')[1] || Object.keys(ROLES)[currentRole + 1];
          const newRole = ROLES[nextRole]?.roleName;
          setChangeDay(`Simulación Nuevo(a) ${newRole}`);
          setChangeRole(nextRole);
          setStep(step + 1);
        },
      };
      brick.props = newProps;
    }
    if (brick?.action === 'nextFinishSimulate') {
      const newProps = {
        ...brick.props,
        onClick: () => {
          const currentRole = Object.keys(ROLES).findIndex((role) => role === user?.role);
          const nextRole = Object.keys(ROLES)[currentRole - 1].split(' ')[1] || Object.keys(ROLES)[currentRole - 1];
          setChangeRole(nextRole);
          setStep(step + 1);
        },
      };
      brick.props = newProps;
    }
    if (brick?.action === "finish") {
      const newProps = {
        ...brick.props,
        onClick: () => finishChallenge(),
      };
      brick.props = newProps;
    }
    if (brick?.action === "handleSubmitPersonalReferences") {
      const newProps = {
        ...brick.props,
        onClick: () => setStep(step + 1),
      };
      brick.props = newProps;
    }
    if (brick?.action === "closeModal") {
      const newProps = {
        ...brick.props,
        isOpen: false,  // Establece isOpen en false para cerrar el modal
        onClick: () => {
          setUpdateBricks("x"); // Esto asegura que el estado de actualización se maneje correctamente
        },
      };
      brick.props = newProps;
    }

    if (brick?.action?.action === "openModalHostCheckbox"
    ) {
      let extraProps = {};
      if (brick?.type !== "button") {

        extraProps = {
          rightIcon: {
            ...brick.props.rightIcon,
            onClick: () => {
              setSelectedId(brick.id);
              setUpdateBricks(`${brick.action.id}-${brick.props.value}`);
            },
            ref: elementRef,
          },
        };
      } else {

        extraProps = {
          onClick: () => {
            setSelectedId(brick.id);
            setUpdateBricks(`${brick.action.id}`);
          },
        };
      }
      const newProps = {
        ...brick.props,
        ...extraProps,
      };
      brick.props = newProps;
    }
    if (
      typeof brick?.action === "object" &&
      brick?.action?.action === "openModalSelect"
    ) {
      let extraProps = {};
      if (brick?.type !== "button") {
        extraProps = {
          rightIcon: {
            ...brick.props.rightIcon,
            onClick: () => {
              setSelectedId(brick.id);
              setUpdateBricks(`${brick.action.id}-${brick.props.value}`);
            },
            ref: elementRef,
          },
        };
      } else {
        extraProps = {
          onClick: () => {
            setSelectedId(brick.id);
            // setUpdateBricks(`${brick.action.id}`);
          },
        };
      }
      const newProps = {
        ...brick.props,
        ...extraProps,
      };
      brick.props = newProps;
    }
    if (brick?.props?.items) {
      const newProps = {
        ...brick.props,
        items: brick?.props?.items?.map((item) => ({
          ...item,
          onClick: () => {
            setUpdateBricks(`${item.action.id}-${item.name}-${item.id}`);
            setTimeout(() => {
              setUpdateBricks(`x-${item.id}`);
            }, 100);
          },
        })),
      };
      brick.props = newProps;
    }
    if (brick?.action === "putAddToWhatSapp") {
      const newProps = {
        ...brick.props,
        onClick: (e) => {
          setUpdateBricks(
            `continuePutAddToWhatSapp-${e.target.checked}-${brick.props.id}`
          );
          // SERVICES[brick.action]?.(user?._id);
        },
      };
      brick.props = newProps;
    }
    if (brick.type === 'newCoordinatorSchedule') {
      const newProps = {
        ...brick.props,
        step,
        setStep,
      };
      brick.props = newProps;
    }
    if (brick?.type === 'groupRadio') {
      
    }

    return (
      typeof brick !== "string" && (
        <Component key={uuid} {...brick.props}>
          {brick.bricks
            ? brick.bricks.map((brick) => renderMap(brick))
            : brick.children}
        </Component>
      )
    );
  };

  return <>{bricks?.map((brick, index) => renderMap(brick, index))}</>;
};
