import { useContext, useEffect, useState, useCallback } from "react";
import { componentMap, ROLES } from "../../../middleware/utils/constants";
import { UserContext } from "../../../middleware/providers/user-context";
import * as SERVICES from "../../../middleware/services/useQueryListOfNewHosts";

const AuxComponent = ({ brick, renderMap, idUser, idChallenge }) => {
  const [bricks, setBricks] = useState([]);

  const fetchData = useCallback(async () => {
    try {
      const data = await SERVICES[brick]?.(idUser, idChallenge);
      setBricks(data || []);
    } catch (error) {
      console.error("Error fetching bricks:", error);
    }
  }, [brick, idUser, idChallenge]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return bricks.map(renderMap);
};

const handleButtonAction = async (
  action,
  step,
  setStep,
  finishChallenge,
  setOpenModals,
  value,
  setCounter,
  counter,
  idUser,
  setTextFieldList
) => {
  if (action === "next") {
    setStep(step + 1);
  } else if (action === "nextAndReset") {
    setStep(step + 1);

    setTextFieldList([]);
  } else if (action === "finish") {
    finishChallenge();
  } else if (typeof action === "object" && action.id) {
    if (value !== undefined && action.targetCounter) {
      let seletedCount = counter;
      if (value) {
        seletedCount = seletedCount + 1;
        setCounter(seletedCount);
      } else {
        seletedCount = seletedCount - 1;
        setCounter(seletedCount);
      }
      if (seletedCount === action.targetCounter) {
        setOpenModals((prev) => ({ ...prev, [action.id]: true }));
        if (action.action === "suggestedTextField") {
          setCounter(0);
        }
      } else {
        setOpenModals((prev) => ({ ...prev, [action.id]: false }));
      }
    } else {
      setOpenModals((prev) => ({ ...prev, [action.id]: true }));
      setCounter(0);
    }
    if (value && action.idService) {
      const data = await SERVICES[action.idService]?.(idUser);
    }
  }
};

export const DynamicChallengeTree = ({
  challenge,
  step,
  setStep,
  setChangeDay,
  setChangeRole,
  finishChallenge,
}) => {
  const { user } = useContext(UserContext);
  const [openModals, setOpenModals] = useState({});
  const [counter, setCounter] = useState(0);
  const [textFieldList, setTextFieldList] = useState([]);
  const currentChallenge =
    ROLES[user?.role]?.challenges[challenge?.day]?.[
      challenge?.typeChalleges - 1
    ];

  const renderMap = useCallback(
    (brick) => {
      const { type, bricks, props, children, isModal, action } = brick;
      const Component = componentMap[type];

      if (!Component) {
        console.warn(`Component type "${type}" not found in componentMap.`);
        //return null;
      }

      const renderChildren = () => (bricks ? bricks.map(renderMap) : children);
      if (typeof brick === "string") {
        return (
          <AuxComponent
            brick={brick}
            renderMap={renderMap}
            idUser={user?._id}
            idChallenge={challenge?.id}
          />
        );
      }
      if (typeof bricks === "string") {
        if (type === "gridGiftSwitch") {
          return (
            <Component
              key={brick.id}
              {...props}
              isModal={isModal}
              openModals={openModals}
              setOpenModals={setOpenModals}
            >
              <AuxComponent
                key={brick.id}
                brick={bricks}
                renderMap={renderMap}
                idUser={user?._id}
                idChallenge={challenge?.id}
              />
            </Component>
          );
        }
        return (
          <AuxComponent
            key={brick.id}
            brick={bricks}
            renderMap={renderMap}
            idUser={user?._id}
            idChallenge={challenge?.id}
          />
        );
      }

      switch (type) {
        case "step":
          return (
            <Component
              key={brick.id}
              visibility={props.order === step ? "visible" : "hidden"}
              {...props}
            >
              {renderChildren()}
            </Component>
          );

        case "button":
          return (
            <Component
              key={brick.id}
              {...props}
              onClick={() =>
                handleButtonAction(
                  action,
                  step,
                  setStep,
                  finishChallenge,
                  setOpenModals,
                  undefined,
                  counter,
                  setCounter,
                  user?._id,
                  setTextFieldList
                )
              }
              disabled={brick.id ? !openModals[brick.id] : false}
            >
              {renderChildren()}
            </Component>
          );

        case "checkbox":
          return (
            <Component
              key={brick.id}
              {...props}
              onChange={() =>
                handleButtonAction(
                  action,
                  step,
                  setStep,
                  finishChallenge,
                  setOpenModals
                )
              }
            >
              {renderChildren()}
            </Component>
          );
        case "giftSwitch":
          return (
            <Component
              key={brick.id}
              {...props}
              onClick={(e) =>
                handleButtonAction(
                  action,
                  step,
                  setStep,
                  finishChallenge,
                  setOpenModals,
                  e.target.checked,
                  setCounter,
                  counter,
                  user?._id
                )
              }
            >
              {renderChildren()}
            </Component>
          );
        case "textField":
          return (
            <Component
              key={brick.id}
              {...props}
              onChange={(e) => {
                const isExistText = textFieldList.includes(brick.id);
                if (!isExistText) {
                  setTextFieldList([...textFieldList, brick.id]);
                  handleButtonAction(
                    action,
                    step,
                    setStep,
                    finishChallenge,
                    setOpenModals,
                    e.target.value,
                    setCounter,
                    counter,
                    user?._id,
                    setTextFieldList
                  );
                }
              }}
            >
              {renderChildren()}
            </Component>
          );

        default:
          return isModal ? (
            <Component
              key={brick.id}
              {...props}
              isOpen={openModals[brick.id]}
              onClose={() =>
                setOpenModals((prev) => ({ ...prev, [brick.id]: false }))
              }
            >
              {renderChildren()}
            </Component>
          ) : (
            <Component key={brick.id} {...props}>
              {renderChildren()}
            </Component>
          );
      }
    },
    [step, setStep, finishChallenge, user?._id, challenge?.id, openModals]
  );

  return <>{currentChallenge?.bricks()?.map(renderMap)}</>;
};
