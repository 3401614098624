import { bool, func, node, string } from "prop-types";

const TextField = ({ ref, className,type = 'text', name, label, placeholder, value, onChange, required, pattern, leftIcon, rightIcon, outline, ...props }) => {

    return (
        <label className={`text-field ${className}`}>
            <input
                type={type}
                name={name}
                className={`text-field--input ${leftIcon ? `padding-36` : ''} ${outline ? `outline` : ''}`}
                value={value} 
                onChange={onChange}
                placeholder={placeholder} 
                required={required}
                pattern={pattern}
                ref={ref}
                {...props} />
            <label className="error">Invalid value</label>
            {leftIcon && <span 
            className={`text-field--icon text-field--icon__left ${leftIcon?.icon}`} 
            onClick={leftIcon?.onClick} 
            onKeyDown={() => {}}
            ></span>}
            {rightIcon && <span 
            className={`text-field--icon text-field--icon__right ${rightIcon?.icon}`} 
            onClick={rightIcon?.onClick} style={{fontSize: `${rightIcon?.size}px`, color: `${rightIcon?.color}`}}
            onKeyDown={() => {}}
            ></span>}
        </label>
    );
};


TextField.displayName = 'TextField';

TextField.propTypes = {
    type: string,
    name: string,
    label: string,
    placeholder: string,
    value: string,
    onChange: func,
    required: bool,
    pattern: string,
    leftIcon: node,
    rightIcon: node,
    outline: bool
};

export default TextField;