import classNames from "classnames";
import { Toggle } from "../../atoms/switch";
import MenuUserInfo from "../menu-user-info";
import Checkbox from "../../atoms/checkbox";

const GiftSwitch = ({ name, role, nameRole, range, onClick, items, orientation, size, checkbox, actives, style }) => {
    const classes = classNames('gift-switch', {
        [`gift-switch--${orientation}`]: orientation,
        [`gift-switch--${size}`]: size,
    });

    return (
        <div className={classes} style={style}>
            <MenuUserInfo name={name} role={role} nameRole={nameRole} range={range} orientation={orientation} size={size} />
            {!items && !checkbox && <Toggle onClick={onClick} />}
            {checkbox && <div className="gift-switch__gifts">
                {actives?.map((item) => <span className={`icon-gift ${item ? 'active' : ''}`}></span>)}
            </div>}
            {checkbox && <Checkbox onClick={onClick} />}
            {items?.map((item) => <div className={`content-buy ${item.wait && 'wait'}`}>
                <img src={item.image} alt={item.title} style={{width: item.width || '30px'}} />
                <span className={`${!item.wait && 'icon-state-selected'} icon`}>{item.wait && '...'}</span>
            </div>)}
        </div>
    );
};

export default GiftSwitch;