import AlignmentDay from "../../components/organisms/alignment-day";
import FilterDay from "../../components/organisms/filter-day";
import FirstDay from "../../components/organisms/first-day";
import HostAlignmentDay from "../../components/organisms/host-alignment-day";
import HostFilterDay from "../../components/organisms/host-filter-day";
import HostFirstDay from "../../components/organisms/host-first-day";
import HostInductionDay from "../../components/organisms/host-induction-day";
import HostRulesDay from "../../components/organisms/host-rules-day";
import HostSecondDay from "../../components/organisms/host-second-day";
import HostThirdDay from "../../components/organisms/host-third-day";
import RulesDay from "../../components/organisms/rules-day";
import SecondDay from "../../components/organisms/second-day";
import ThirdDay from "../../components/organisms/third-day";

// Images
import IconTools from "../../resources/images/icon-tools.png";
import IconLens from "../../resources/images/icon-lens.png";
import IconBulb from "../../resources/images/icon-bulb.png";
import IconHeart from "../../resources/images/icon-heart.png";
import IconMicrophone from "../../resources/images/icon-microphone.png";
import IconShield from "../../resources/images/icon-shield.png";
import Background from "../../components/templates/background";
import Header from "../../components/molecules/header";
import Tabs from "../../components/molecules/tabs";
import Tab from "../../components/atoms/tab";
import { TabsContext, TabsProvider } from "../providers/tabs-context";
import MyProgress from "../../components/organisms/my-progress";
import MyValidatorsTab from "../../components/organisms/my-validators-tab";
import { ReactComponent as PdlBadge } from '../../resources/images/pdl-badge.svg';
import MyPerformance from "../../components/organisms/my-performance";
import EpaDay from "../../components/organisms/epa-day";
import HostEpaDay from "../../components/organisms/host-epa-day";
import EpaEndDay from "../../components/organisms/epa-end-day";
import HostEpaEndDay from "../../components/organisms/host-epa-end-day";
import CoordinatorEpaDay from "../../components/organisms/coordinator-epa-day";
import DirectorEpaEndDay from "../../components/organisms/director-epa-end-day";
import CaptainEpaEndDay from '../../components/organisms/captain-epa-end-day';
import DirectorEpaDay from "../../components/organisms/director-epa-day";
import { DynamicChallenge } from "../../components/organisms/dynamic-challenge";
import Step from "../../components/molecules/step";
import Button from "../../components/atoms/button";
import Paragraph from "../../components/atoms/paragraph";
import { Image } from "../../components/atoms/image";
import GiftSwitch from "../../components/molecules/gift-switch";
import { GridGiftSwitch } from "../../components/molecules/grid-gift-switch";
import { getBricksChallengeA } from "../screen-maps/coordinatorInductionChallengeA";
import { getBricksChallengeB } from "../screen-maps/coordinatorInductionChallengeB";
import { CardCopyKey } from "../../components/molecules/card-copy-key";
import { getBricksChallengeC } from "../screen-maps/coordinatorInductionChallengeC";
import MenuUserInfo from "../../components/molecules/menu-user-info";
import { CounterBadges } from "../../components/molecules/counter-badges";
import { TextFieldList } from "../../components/molecules/text-field-list";
import TextField from "../../components/atoms/text-field";
import { getBricksChallengeA as getBricksCoordinatorRulesDayA } from "../screen-maps/coordinatorRulesDayChallengeA";
import { ReadingSection } from "../../components/molecules/reading-section";
import { Quote } from "../../components/molecules/quote";
import { getBricksChallengeB as getBricksCoordinatorRulesDayB } from "../screen-maps/coordinatorRulesDayChallengeB";
import Card from "../../components/atoms/card";
import Modal from "../../components/atoms/modal";
import { ListModal } from "../../components/organisms/list-modal";
import Checkbox from "../../components/atoms/checkbox";
import { GenerationTable } from "../../components/molecules/generation-table";
import { getBricksChallengeC as getBricksCoordinatorRulesDayC } from "../screen-maps/coordinatorRulesDayChallengeC";
import { getBricksChallengeA as getBricksCoordinatorFirstDayA } from "../screen-maps/coordinatorFirstDayChallengeA";
import { getBricksChallengeB as getBricksCoordinatorFirstDayB } from "../screen-maps/coordinatorFirstDayChallengeB";
import { getBricksChallengeC as getBricksCoordinatorFirstDayC } from "../screen-maps/coordinatorFirstDayChallengeC";
import { getBricksChallengeA as getBricksCoordinatorSecondDayA } from "../screen-maps/coordinatorSecondDayChallengeA";
import { getBricksChallengeB as getBricksCoordinatorSecondDayB } from "../screen-maps/coordinatorSecondDayChallengeB";
import { getBricksChallengeC as getBricksCoordinatorSecondDayC } from "../screen-maps/coordinatorSecondDayChallengeC";
import { getBricksChallengeA as getBricksCoordinatorThirdDayA } from "../screen-maps/coordinatorThirdDayChallengeA ";
import { getBricksChallengeB as getBricksCoordinatorThirdDayB } from "../screen-maps/coordinatorThirdDayChallengeB";
import { getBricksChallengeC as getBricksCoordinatorThirdDayC } from "../screen-maps/coordinatorThirdDayChallengeC";
import { TextFieldPoints } from "../../components/molecules/textfield-points";

import { getBricksChallengeA as getBricksCoordinatorFilterDayA } from "../screen-maps/coordinatorFilterDayChallengeA";
import { getBricksChallengeB as getBricksCoordinatorFilterDayB } from "../screen-maps/coordinatorFilterDayChallengeB";
import { getBricksChallengeC as getBricksCoordinatorFilterDayC } from "../screen-maps/coordinatorFilterDayChallengeC";
import { getBricksChallengeA as getBricksCoordinatorAlignmentDayA } from "../screen-maps/coordinatorAlignmentDayChallengeA";
import { getBricksChallengeB as getBricksCoordinatorAlignmentDayB } from "../screen-maps/coordinatorAlignmentDayChallengeB";
import { getBricksChallengeC as getBricksCoordinatorAlignmentDayC } from "../screen-maps/coordinatorAlignmentDayChallengeC";
import { getBricksChallengeA as getBricksDirectorInductionDayDayA } from "../screen-maps/directorInductionDayChallengeA";
import { getBricksChallengeB as getBricksDirectorInductionDayDayB } from "../screen-maps/directorInductionDayChallengeB";
import { getBricksChallengeC as getBricksDirectorInductionDayDayC } from "../screen-maps/directorInductionDayChallengeC";

import { UserCardInfo } from "../../components/molecules/user-card-info";
import { PdlList } from "../../components/molecules/pdl-list";
import { Grid } from "../../components/templates/grid";
import { AssignationMedalList } from "../../components/organisms/assignation-medal-list";
import { RoomsMedalList } from "../../components/organisms/rooms-medal-list";
import CustomDatePicker from "../../components/molecules/date-picker";
import { GridDatePicker } from "../../components/molecules/grid-date-picker";
import { UserInfoDate } from "../../components/molecules/user-info-date";
import { NewCoordinatorsSchedule } from "../../components/organisms/director-epa-end-day/NewCoordinatorsSchedule";
import { PreviewEpa } from "../../components/organisms/preview-epa";
import { CardImage } from "../../components/molecules/card-image";
import { BuyDate } from "../../components/molecules/buy-date";
import CopyField from "../../components/atoms/copy-field";
import { CompleteRequirements } from "../../components/organisms/complete-requirements";
import { Switch, Toggle } from "../../components/atoms/switch";
import { PackagesInfoModal } from "../../components/molecules/packages-info-modal";
import GroupRadio from "../../components/molecules/group-radio";
import { DynamicChallengeTree } from "../../components/organisms/dynamic-challenge-tree";


const {
    REACT_APP_API_BASE_AUTH_URL,
    REACT_APP_API_BASE_CANDIDATE_URL,
    REACT_APP_API_BASE_CHALLENGE_URL,
    REACT_APP_API_BASE_VALIDATOR_URL,
    REACT_APP_API_BASE_RULES_CHALLENGE_URL,
    REACT_APP_API_BASE_RULES_VALIDATOR_URL,
    REACT_APP_API_BASE_RULES_HOST_URL,
    REACT_APP_API_BASE_RULES_EPA_URL,
    REACT_APP_API_BASE_PAYMENT_URL,
    REACT_APP_API_BASE_RULES_COORDINATOR_URL,
    REACT_APP_API_BASE_RULES_CART_URL,
} = process.env;

const API = {
    LOGIN: {
        url: `${REACT_APP_API_BASE_AUTH_URL}/login`,
        method: 'POST',
    },
    GOOGLE: {
        url: `${REACT_APP_API_BASE_AUTH_URL}/auth-google`,
        method: 'GET',
    },
    FACEBOOK: {
        url: `${REACT_APP_API_BASE_AUTH_URL}/auth-facebook`,
        method: 'GET',
    },
    MICROSOFT: {
        url: `${REACT_APP_API_BASE_AUTH_URL}/auth-microsoft`,
        method: 'GET',
    },
    INSTAGRAM: {
        url: `${REACT_APP_API_BASE_AUTH_URL}/auth-instagram`,
        method: 'GET',
    },
    TWITTER: {
        url: `${REACT_APP_API_BASE_AUTH_URL}/auth-twitter`,
        method: 'GET',
    },
    RECOVERY: {
        url: `${REACT_APP_API_BASE_AUTH_URL}/password-recovery`,
        method: 'POST',
    },
    UPDATE_PASSWORD: {
        url: `${REACT_APP_API_BASE_AUTH_URL}/update-password`,
        method: 'POST',
    },
    GET_INFORMATION: {
        url: `${REACT_APP_API_BASE_AUTH_URL}/information`,
        method: 'GET',
    },
    REGISTER_CANDIDATE: {
        url: `${REACT_APP_API_BASE_AUTH_URL}/register-candidate`,
        method: 'POST',
    },
    REGISTRATION_CONFIRMATION: {
        url: `${REACT_APP_API_BASE_AUTH_URL}/registration-confirmation`,
        method: 'POST',
    },
    CREATE_CANDIDATE: {
        url: `${REACT_APP_API_BASE_CANDIDATE_URL}/create-candidate`,
        method: 'POST',
    },
    PROGRESS_POINT: {
        url: `${REACT_APP_API_BASE_CANDIDATE_URL}/applicant-progress-point`,
        method: 'GET',
    },
    SEND_VALIDATION_EMAIL: {
        url: `${REACT_APP_API_BASE_CANDIDATE_URL}/send-validation-email`,
        method: 'GET',
    },
    VALIDATOR_CANDIDATE: {
        url: `${REACT_APP_API_BASE_CANDIDATE_URL}/validation-and-user-details-by-token`,
        method: 'GET',
    },
    GET_CHALLENGE: {
        url: `${REACT_APP_API_BASE_CHALLENGE_URL}`,
        method: 'GET',
    },
    GET_MEDALS: {
        url: `${REACT_APP_API_BASE_CHALLENGE_URL}/medals-by-role`,
        method: 'GET',
    },
    CHALLENGE_COMPLETED: {
        url: `${REACT_APP_API_BASE_CHALLENGE_URL}/challenge-completed`,
        method: 'PUT',
    },
    REGISTER_MEDAL: {
        url: `${REACT_APP_API_BASE_CHALLENGE_URL}/register-medal`,
        method: 'PUT',
    },
    CHALLENGE_B_RULES_DAY: {
        url: `${REACT_APP_API_BASE_CHALLENGE_URL}/challenge-b-rulesday`,
        method: 'PUT',
    },
    SAVE_RESPONSE: {
        url: `${REACT_APP_API_BASE_CHALLENGE_URL}/save-response`,
        method: 'PUT',
    },
    SAVE_PERSONAL_REFERENCES: {
        url: `${REACT_APP_API_BASE_CHALLENGE_URL}/save-personal-references`,
        method: 'PUT',
    },
    APPLICANT_CHALLENGES_SCHEDULE: {
        url: `${REACT_APP_API_BASE_CHALLENGE_URL}/applicant-challenges-schedule`,
        method: 'PUT',
    },
    SAVE_VALIDATION: {
        url: `${REACT_APP_API_BASE_VALIDATOR_URL}/save-validation`,
        method: 'PUT',
    },
    SAVE_INCOMES_INFO: {
        url: `${REACT_APP_API_BASE_RULES_CHALLENGE_URL}/save-pojection-according-to-host`,
        method: 'PUT',
    },
    GET_PROJECTION: {
        url: `${REACT_APP_API_BASE_RULES_CHALLENGE_URL}/get-pojection`,
        method: 'GET',
    },
    GET_SCHEDULE_INFORMATION: {
        url: `${REACT_APP_API_BASE_RULES_VALIDATOR_URL}/general-schedule-information`,
        method: 'GET',
    },
    SAVE_RULES_DAY_CHALLENGE_B: {
        url: `${REACT_APP_API_BASE_RULES_CHALLENGE_URL}/challenge-b-rules-day-host`,
        method: 'POST',
    },
    SAVE_SITUATIONS_TO_CHANGE: {
        url: `${REACT_APP_API_BASE_RULES_CHALLENGE_URL}/save-situations-to-change`,
        method: 'POST',
    },
    LIST_CANDIDATES_KEYS: {
        url: `${REACT_APP_API_BASE_RULES_CHALLENGE_URL}/list-candidate-keys`,
        method: 'GET',
    },
    LIST_CANDIDATES_WITH_TRAINING: {
        url: `${REACT_APP_API_BASE_RULES_CHALLENGE_URL}/list-candidate-with-training`,
        method: 'GET',
    },
    GET_CART_TOTALS_COMMISSIONS: {
        url: `${REACT_APP_API_BASE_RULES_CART_URL}/cart-totals-commissions`,
        method: 'GET',
    },
    GET_CALCULATE_PURCHASE: {
        url: `${REACT_APP_API_BASE_RULES_CHALLENGE_URL}/purchase-of-jeunesse-and-gift-to-applicants`,
        method: 'GET',
    },
    GET_TIME_MANAGEMENT_CANDIDATE_WINNERS: {
        url: `${REACT_APP_API_BASE_RULES_HOST_URL}/candidates-who-won-time-management-medal`,
        method: 'GET',
    },
    GET_TIME_MANAGEMENT_HOST_WINNERS: {
        url: `${REACT_APP_API_BASE_RULES_HOST_URL}/host-who-won-time-management-medal`,
        method: 'GET',
    },
    CHANGE_PROCESS_STATUS: {
        url: `${REACT_APP_API_BASE_RULES_VALIDATOR_URL}/change-process-status-Validator`,
        method: 'PUT',
    },
    GET_CHALLENGES_BY_DAY_ROLE: {
        url: `${REACT_APP_API_BASE_RULES_CHALLENGE_URL}/per-role-and-day`,
        method: 'GET',
    },
    EVENT_MEDAL_ALLOCATION: {
        url: `${REACT_APP_API_BASE_RULES_CHALLENGE_URL}/event-medal-allocation`,
        method: 'POST',
    },
    GENERIC_EVENT: {
        url: `${REACT_APP_API_BASE_RULES_CHALLENGE_URL}/generic-event-for-the-epa`,
        method: 'POST',
    },
    LIST_NEW_ROLES: {
        url: `${REACT_APP_API_BASE_RULES_HOST_URL}/list-new-roles`,
        method: 'GET',
    },
    NETWORK_MARKETING: {
        url: `${REACT_APP_API_BASE_RULES_CHALLENGE_URL}/network-marteking`,
        method: 'GET',
    },
    CHANGE_OF_ROLE: {
        url: `${REACT_APP_API_BASE_RULES_HOST_URL}/change-of-role`,
        method: 'PUT',
    },
    EPA_DATE_COORDINATING_SCHEDULES_BY_ID: {
        url: `${REACT_APP_API_BASE_RULES_EPA_URL}/coordinating-schedules`,
        method: 'GET',
    },
    LIST_OF_NEW_HOSTS: {
        url: `${REACT_APP_API_BASE_RULES_COORDINATOR_URL}/list-of-new-hosts`,
        method: 'GET',
    },
    LIST_OF_NEW_COORDINATORS_WITH_THEIR_NEW_HOSTS: {
        url: `${REACT_APP_API_BASE_RULES_COORDINATOR_URL}/list-of-new-coordinators-with-their-new-hosts`,
        method: 'GET',
    },
    ADD_TO_WHATSAPP_GROUP: {
        url: `${REACT_APP_API_BASE_RULES_COORDINATOR_URL}/update-property-host`,
        method: 'PUT',
    },
    MARK_GIFT_GIVING: {
        url: `${REACT_APP_API_BASE_RULES_COORDINATOR_URL}/mark-gift-giving`,
        method: 'PUT',
    },
    ///api/v1/acdc-rules/coordinator/mark-gift-giving/{idCoordinator}
    //add-to-whatsapp-group
    ///api/v1/acdc-rules/coordinator/update-property-host/{idHost}
    //list-of-new-hosts
    EPA_DATE_SAVE_DATE_AVAILABLE: {

        url: `${REACT_APP_API_BASE_RULES_EPA_URL}/save-date-available`,
        method: 'PUT',
    },
    EPA_PARTICIPATION_REQUIREMENTS: {
        url: `${REACT_APP_API_BASE_CHALLENGE_URL}/epa-participation-requirements`,
        method: 'PUT',
    },
    SHORTLISTED_APPLICANTS:
    {
        url: `${REACT_APP_API_BASE_RULES_HOST_URL}/shortlisted-applicants`,
        method: 'GET',
    },
    SELECT_FINAL_EPA_CANDIDATES: {
        url: `${REACT_APP_API_BASE_RULES_HOST_URL}/select-final-epa-candidates`,
        method: 'POST',
    },
    LIST_DATE_EPA_AVAILABLE: {
        url: `${REACT_APP_API_BASE_RULES_EPA_URL}/list-date-epa-available`,
        method: 'GET',
    },
    SAVE_SELECTED_DATES_FOR_EPA: {
        url: `${REACT_APP_API_BASE_RULES_EPA_URL}/save-selected-dates-for-epa`,
        method: 'PUT',
    },
    SUGGESTED_DATE_FOR_EPA: {
        url: `${REACT_APP_API_BASE_RULES_EPA_URL}/suggested-date-for-epa`,
        method: 'GET',
    },
    THE_HOSTS_ARE_READY: {
        url: `${REACT_APP_API_BASE_RULES_EPA_URL}/the-hosts-are-ready-with-the-dates-selected-for-the-epa`,
        method: 'GET',
    },
    LIST_RESIDUAL_DATES: {
        url: `${REACT_APP_API_BASE_RULES_EPA_URL}/list-reidual-dates`,
        method: 'GET',
    },
    DATE_FOR_EPA_SELECTED_BY_THE_COORDINATORS: {
        url: `${REACT_APP_API_BASE_RULES_EPA_URL}/dates-for-the-epa-selected-by-the-coordinators`,
        method: 'GET',
    },

    READY_EPA_AVAILABLE_DATES_COORDINATOR: {
        url: `${REACT_APP_API_BASE_RULES_EPA_URL}/ready-epa-available-dates-selected-by-the-new-coordinator`,
        method: 'GET',
    },

    SINGLE_COORDINATOR_SCHEDULE: {
        url: `${REACT_APP_API_BASE_RULES_EPA_URL}/single-coordinator-schedule`,
        method: 'GET',
    },

    FOUNDATIONS_SELECTED_BY_NEW_CANDIDATES: {
        url: `${REACT_APP_API_BASE_RULES_CHALLENGE_URL}/foundations-selected-by-new-candidates`,
        method: 'GET',
    },
    CHALLENGES_WITH_MEDALS_BE_ASSIGNED: {
        url: `${REACT_APP_API_BASE_RULES_CHALLENGE_URL}/challenges-with-medals-that-must-be-assigned`,
        method: 'GET',
    },
    LIST_OF_TIMES_FOR_SYNCHRONIZATION_WITH_THE_HOST: {
        url: `${REACT_APP_API_BASE_RULES_CHALLENGE_URL}/list-of-times-for-synchronization-with-the-host`,
        method: 'GET',
    },
    ADD_CARD: {
        url: `${REACT_APP_API_BASE_PAYMENT_URL}/cards/add-card`,
        method: 'POST',
    },
    VALIDATE: {
        url: `${REACT_APP_API_BASE_PAYMENT_URL}/cards/validate`,
        method: 'POST',
    },
    GET_ACCEPTANCE_KEY: {
        url: `${REACT_APP_API_BASE_PAYMENT_URL}/cards/get-acceptance-key`,
        method: 'GET',
    },
    GET_PRODUCTS_ALEGRA: {
        url: `${REACT_APP_API_BASE_PAYMENT_URL}/cards/get-products-alegra`,
        method: 'GET'
    },
    GET_COUNTRIES: {
        url: `${REACT_APP_API_BASE_PAYMENT_URL}/cards/countries`,
        method: 'GET'
    },
    GET_STATES: {
        url: `${REACT_APP_API_BASE_PAYMENT_URL}/cards/states`,
        method: 'GET'
    },

    // GET_STATES_DETAILS_TRANSACTION_BY_USER: {
    //     url: `${REACT_APP_API_BASE_PAYMENT_URL}/cards/details-transaction-by-user`,
    //     method: 'GET'
    // },
    GET_CITIES: {
        url: `${REACT_APP_API_BASE_PAYMENT_URL}/cards/cities`,
        method: 'GET',
    },
    UPDATE_WAITING_LIST: {
        url: `${REACT_APP_API_BASE_RULES_HOST_URL}/update-waiting-list-or-end-of-the-game`,
        method: 'PUT',
    },
    CHECK_GRATITUDE_DONE_VALIDATOR: {
        url: `${REACT_APP_API_BASE_RULES_VALIDATOR_URL}/check-gratitude-done-validator`,
        method: 'POST',
    },
    COMPLETED_TRAINING_OF_KEY_A: {
        url: `${REACT_APP_API_BASE_RULES_COORDINATOR_URL}/completed-training-of-key-a`,
        method: 'POST',
    },

    EPA_START_FLAG: {
        url: `${REACT_APP_API_BASE_RULES_CHALLENGE_URL}/epa-start-flag`,
        method: 'PUT',
    },
    GET_CAPTAIN_AND_HOST_WITH_APPLICANTS: {
        url: `${REACT_APP_API_BASE_RULES_COORDINATOR_URL}/get-director-and-coordinator-with-host-with-the-ir-applicants`,
        method: 'GET',
    },

    LIST_OF_HOSTS_WITH_THEIR_SELECTED_APPLICANTES: {
        url: `${REACT_APP_API_BASE_RULES_COORDINATOR_URL}/list-of-hosts-with-their-selected-applicants`,
        method: 'GET',
    },
    USERS_BELOW: {
        url: `${REACT_APP_API_BASE_RULES_COORDINATOR_URL}/users-below`,
        method: 'GET',
    },

    AWARDING_MEDALS_TO_THE_ROOMS: {
        url: `${REACT_APP_API_BASE_RULES_CHALLENGE_URL}/awarding-medals-to-the-rooms`,
        method: 'POST',
    },
    GET_SCHEDULE_BY_USER: {
        url: `${REACT_APP_API_BASE_RULES_EPA_URL}/schedule-by-any-user`,
        method: 'GET',
    },
    GET_CANDIDATES_FOR_THE_COORDINATOR: {
        url: `${REACT_APP_API_BASE_RULES_COORDINATOR_URL}/get-candidates-for-the-coordinator`,
        method: 'GET',
    },
    GET_GENERAL_USER_TABLE: {
        url: `${REACT_APP_API_BASE_RULES_COORDINATOR_URL}/general-user-table`,
        method: 'GET',
    },
    SAVE_STEP_OF_A_CHALLENGE: {
        url: `${REACT_APP_API_BASE_RULES_CHALLENGE_URL}/save-step-of-a-challenge`,
        method: 'PUT',
    },
};

const MESSAGES = {
    ERROR: {
        "Password does not match": "La contraseña no es correcta",
        "User does not exist": "El usuario no existe",
        "Incorrect code.": "El código es incorrecto",
        "Validation already sent.": "El correo de validación ya fue enviado",
        "The user does not belong to the host.": "No encontramos el correo electrónico que ingresaste. Por favor, verifica y asegúrate de ingresar el correo con el que realizaste la validación",
        "You have already sent the maximum number of emails.": 'Ya has enviado el correo a este Validador(a) 3 veces. Puedes agregarlo(a) desde el botón "Agregar Referencia" para continuar avanzando.',
    }
};


const ROLES = {
    candidate: {
        roleName: 'Aspirante',
        level: 'Aprendizaje',
        description: 'Nivel de influencia 1',
        levelDescription: 'Rol de Liderazgo',
        circle: '⚫',
        challenges: {
            'induction day': [
                {
                    id: 1,
                    name: 'Desafío A',
                    description: 'Challenge A description',
                    completed: false,
                    icon: 'calendar',
                    position: 'first',
                    nameDay: 'Inducción',
                },
                {
                    id: 2,
                    name: 'Desafío B',
                    description: 'Challenge B description',
                    completed: false,
                    icon: 'mic',
                    position: 'first',
                    nameDay: 'Inducción',
                },
                {
                    id: 3,
                    name: 'Desafío C',
                    description: 'Challenge C description',
                    completed: false,
                    icon: 'sync',
                    position: 'first',
                    nameDay: 'Inducción',
                },
            ],
            'rules day': [
                {
                    id: 1,
                    name: 'Desafío A',
                    description: 'Challenge A description',
                    completed: false,
                    icon: 'mic',
                    position: 'second',
                    nameDay: 'Día de reglas',
                },
                {
                    id: 2,
                    name: 'Desafío B',
                    description: 'Challenge B description',
                    completed: false,
                    icon: 'list',
                    position: 'second',
                    nameDay: 'Día de reglas',
                },
                {
                    id: 3,
                    name: 'Desafío C',
                    description: 'Challenge C description',
                    completed: false,
                    icon: 'list',
                    position: 'second',
                    nameDay: 'Día de reglas',
                }
            ],
            'day one': [
                {
                    id: 1,
                    name: 'Desafío A',
                    description: 'Challenge A description',
                    completed: false,
                    icon: 'mic',
                    position: 'third',
                    nameDay: 'Día 1',
                },
                {
                    id: 2,
                    name: 'Desafío B',
                    description: 'Challenge B description',
                    completed: false,
                    icon: 'rocket-icon',
                    position: 'third',
                    nameDay: 'Día 1',
                },
                {
                    id: 3,
                    name: 'Desafío C',
                    description: 'Challenge C description',
                    completed: false,
                    icon: 'victory-hand',
                    position: 'third',
                    nameDay: 'Día 1',
                }
            ],
            'day two': [
                {
                    id: 1,
                    name: 'Desafío A',
                    description: 'Challenge A description',
                    completed: false,
                    icon: 'mic',
                    position: 'fourth',
                    nameDay: 'Día 2',
                },
                {
                    id: 2,
                    name: 'Desafío B',
                    description: 'Challenge B description',
                    completed: false,
                    icon: 'rocket-icon',
                    position: 'fourth',
                },
                {
                    id: 3,
                    name: 'Desafío C',
                    description: 'Challenge C description',
                    completed: false,
                    icon: 'victory-hand',
                    position: 'fourth',
                }
            ],
            'day three': [
                {
                    id: 1,
                    name: 'Desafío A',
                    description: 'Challenge A description',
                    completed: false,
                    icon: 'mic',
                    position: 'fifth',
                    nameDay: 'Día 3',
                },
                {
                    id: 2,
                    name: 'Desafío B',
                    description: 'Challenge B description',
                    completed: false,
                    icon: 'rocket-icon',
                    position: 'fifth',
                },
                {
                    id: 3,
                    name: 'Desafío C',
                    description: 'Challenge C description',
                    completed: false,
                    icon: 'victory-hand',
                    position: 'fifth',
                }
            ],
            'filter day': [
                {
                    id: 1,
                    name: 'Desafío A',
                    description: 'Challenge A description',
                    completed: false,
                    icon: 'mic',
                    position: 'sixth',
                    isShowValidatorForm: true,
                    nameDay: 'Día de filtro',
                },
                {
                    id: 2,
                    name: 'Desafío B',
                    description: 'Challenge B description',
                    completed: false,
                    icon: 'rocket-icon',
                    position: 'sixth',
                },
                {
                    id: 3,
                    name: 'Desafío C',
                    description: 'Challenge C description',
                    completed: false,
                    icon: 'victory-hand',
                    position: 'sixth',
                }
            ],
            'alignment day': [
                {
                    id: 1,
                    name: 'Desafío A',
                    description: 'Challenge A description',
                    completed: false,
                    icon: 'mic',
                    position: 'seventh',
                    isShowValidatorForm: true,
                    nameDay: 'Día de alineamiento',
                },
                {
                    id: 2,
                    name: 'Desafío B',
                    description: 'Challenge B description',
                    completed: false,
                    icon: 'rocket-icon',
                    position: 'seventh',
                },
                {
                    id: 3,
                    name: 'Desafío C',
                    description: 'Challenge C description',
                    completed: false,
                    icon: 'victory-hand',
                    position: 'seventh',
                }
            ],
            'epa day': [
                {
                    id: 1,
                    name: 'Desafío E',
                    description: 'Challenge A description',
                    completed: false,
                    icon: 'mic',
                    position: 'eighth',
                    isShowValidatorForm: true,
                    nameDay: 'Día de EPA',
                },
                {
                    id: 2,
                    name: 'Desafío P',
                    description: 'Challenge B description',
                    completed: false,
                    icon: 'good-hand'
                },
                {
                    id: 3,
                    name: 'Desafío A',
                    description: 'Challenge C description',
                    completed: false,
                    icon: 'list'
                }
            ],
            'end of epa': [
                {
                    id: 1,
                    name: 'Desafío Sala E',
                    description: 'Challenge A description',
                    completed: false,
                    icon: 'calendar',
                    position: 'ninth',
                    isShowValidatorForm: true,
                    nameDay: 'Final del EPA',
                },
                {
                    id: 2,
                    name: 'Desafío Sala P',
                    description: 'Challenge B description',
                    completed: false,
                    icon: 'good-hand'
                },
                {
                    id: 3,
                    name: 'Desafío Sala A',
                    description: 'Challenge C description',
                    completed: false,
                    icon: 'list'
                }
            ]
        },
        days: {
            'rules day': RulesDay,
            'day one': FirstDay,
            'day two': SecondDay,
            'day three': ThirdDay,
            'filter day': FilterDay,
            'alignment day': AlignmentDay,
            'epa day': EpaDay,
            'end of epa': EpaEndDay,
        },
    },
    'new host': {
        isShowManagement: true,
        circle: '⚪',
        challenges: {
            'end of epa': [
                {
                    id: 1,
                    name: 'Desafío Honor',
                    description: 'Challenge A description',
                    completed: false,
                    icon: 'calendar',
                    position: 'ninth',
                    isShowValidatorForm: true,
                    nameDay: 'Final del EPA',
                },
                {
                    id: 2,
                    name: 'Desafío Humildad',
                    description: 'Challenge B description',
                    completed: false,
                    icon: 'good-hand'
                },
                {
                    id: 3,
                    name: 'Desafío Hambre',
                    description: 'Challenge C description',
                    completed: false,
                    icon: 'list'
                }
            ]
        },
        days: {
            'end of epa': EpaEndDay,
        },
        goals: [
            {
                id: 1,
                name: 'Meta 1',
                description: 'Agendar 6 Aspirante para asistir al EPA',
                infoUserKey: 'personalReferences',
                target: 6,
                filter: ['scheduled', 'registered'],
                position: 'first',
            },
            {
                id: 2,
                name: 'Meta 2',
                description: 'Pre-seleccionar 3 Aspirantes para asistir al EPA',
                filter: 'status',
                extra: '3',
                position: 'second',
            },
            {
                id: 3,
                name: 'Meta 3',
                description: 'Seleccionar 2 Aspirantes que pasarán a ser Anfitriones',
                filter: 'status',
                extra: '2',
                position: 'third',
            }
        ],
    },
    host: {
        roleName: 'Anfitrión',
        level: 'Desempeño',
        description: 'Nivel de influencia 2',
        levelDescription: 'Rol de Liderazgo',
        circle: '⚪',
        isShowUpdate: true,
        isShowManagement: true,
        days: {
            'induction day': HostInductionDay,
            'rules day': HostRulesDay,
            'day one': HostFirstDay,
            'day two': HostSecondDay,
            'day three': HostThirdDay,
            'filter day': HostFilterDay,
            'alignment day': HostAlignmentDay,
            'epa day': HostEpaDay,
        },
        challenges: {
            'induction day': [
                {
                    id: 1,
                    name: 'Desafío A',
                    description: 'Challenge A description',
                    completed: false,
                    icon: 'host-money-hand',
                    position: 'first',
                    isShowValidatorForm: true,
                    canUpdate: true,
                    nameDay: 'Inducción',
                },
                {
                    id: 2,
                    name: 'Desafío B',
                    description: 'Challenge B description',
                    completed: false,
                    icon: 'host-screen-tools'
                },
                {
                    id: 3,
                    name: 'Desafío C',
                    description: 'Challenge C description',
                    completed: false,
                    icon: 'host-mail'
                }
            ],
            'rules day': [
                {
                    id: 1,
                    name: 'Desafío A',
                    description: 'Challenge A description',
                    completed: false,
                    icon: 'mic',
                    position: 'second',
                    isShowValidatorForm: true,
                    canUpdate: true,
                    nameDay: 'Día de reglas',
                },
                {
                    id: 2,
                    name: 'Desafío B',
                    description: 'Challenge B description',
                    completed: false,
                    icon: 'list'
                },
                {
                    id: 3,
                    name: 'Desafío C',
                    description: 'Challenge C description',
                    completed: false,
                    icon: 'host-screen-tools'
                }
            ],
            'day one': [
                {
                    id: 1,
                    name: 'Desafío A',
                    description: 'Challenge A description',
                    completed: false,
                    icon: 'mic',
                    position: 'third',
                    nameDay: 'Día 1',
                },
                {
                    id: 2,
                    name: 'Desafío B',
                    description: 'Challenge B description',
                    completed: false,
                    icon: 'host-key-hand',
                },
                {
                    id: 3,
                    name: 'Desafío C',
                    description: 'Challenge C description',
                    completed: false,
                    icon: 'mic'
                }
            ],
            'day two': [
                {
                    id: 1,
                    name: 'Desafío A',
                    description: 'Challenge A description',
                    completed: false,
                    icon: 'mic',
                    position: 'fourth',
                    nameDay: 'Día 2',
                },
                {
                    id: 2,
                    name: 'Desafío B',
                    description: 'Challenge B description',
                    completed: false,
                    icon: 'host-key-hand'
                },
                {
                    id: 3,
                    name: 'Desafío C',
                    description: 'Challenge C description',
                    completed: false,
                    icon: 'mic'
                }
            ],
            'day three': [
                {
                    id: 1,
                    name: 'Desafío A',
                    description: 'Challenge A description',
                    completed: false,
                    icon: 'mic',
                    position: 'fifth',
                    nameDay: 'Día 3',
                },
                {
                    id: 2,
                    name: 'Desafío B',
                    description: 'Challenge B description',
                    completed: false,
                    icon: 'host-key-hand'
                },
                {
                    id: 3,
                    name: 'Desafío C',
                    description: 'Challenge C description',
                    completed: false,
                    icon: 'mic'
                }
            ],
            'filter day': [
                {
                    id: 1,
                    name: 'Desafío A',
                    description: 'Challenge A description',
                    completed: false,
                    icon: 'sync',
                    position: 'sixth',
                    nameDay: 'Día de filtro',
                },
                {
                    id: 2,
                    name: 'Desafío B',
                    description: 'Challenge B description',
                    completed: false,
                    icon: 'host-screen-tools'
                },
                {
                    id: 3,
                    name: 'Desafío C',
                    description: 'Challenge C description',
                    completed: false,
                    icon: 'mic'
                }
            ],
            'alignment day': [
                {
                    id: 1,
                    name: 'Desafío A',
                    description: 'Challenge A description',
                    completed: false,
                    icon: 'mic',
                    position: 'seventh',
                    nameDay: 'Día de alineamiento',
                },
                {
                    id: 2,
                    name: 'Desafío B',
                    description: 'Challenge B description',
                    completed: false,
                    icon: 'calendar'
                },
                {
                    id: 3,
                    name: 'Desafío C',
                    description: 'Challenge C description',
                    completed: false,
                    icon: 'sync'
                }
            ],
            'epa day': [
                {
                    id: 1,
                    name: 'Desafío E',
                    description: 'Challenge A description',
                    completed: false,
                    icon: 'mic',
                    position: 'eighth',
                    nameDay: 'Día de EPA',
                },
                {
                    id: 2,
                    name: 'Desafío P',
                    description: 'Challenge B description',
                    completed: false,
                    icon: 'good-hand'
                },
                {
                    id: 3,
                    name: 'Desafío A',
                    description: 'Challenge C description',
                    completed: false,
                    icon: 'list'
                }
            ],
        },
        goals: [
            {
                id: 1,
                name: 'Meta 1',
                description: 'Agendar 6 Aspirante para asistir al EPA',
                infoUserKey: 'personalReferences',
                target: 6,
                filter: ['scheduled', 'registered'],
                position: 'first',
            },
            {
                id: 2,
                name: 'Meta 2',
                description: 'Pre-seleccionar 3 Aspirantes para asistir al EPA',
                filter: 'status',
                extra: '3',
                position: 'second',
            },
            {
                id: 3,
                name: 'Meta 3',
                description: 'Seleccionar 2 Aspirantes que pasarán a ser Anfitriones',
                filter: 'status',
                extra: '2',
                position: 'third',
            }
        ],
    },
    'new coordinator': {
        circle: '🔴',
        challenges: {
            'end of epa': [
                {
                    id: 1,
                    name: 'Desafío Honor',
                    description: 'Challenge A description',
                    completed: false,
                    icon: 'calendar',
                    position: 'ninth',
                    nameDay: 'Final del EPA',
                },
                {
                    id: 2,
                    name: 'Desafío Humildad',
                    description: 'Challenge B description',
                    completed: false,
                    icon: 'good-hand'
                },
                {
                    id: 3,
                    name: 'Desafío Hambre',
                    description: 'Challenge C description',
                    completed: false,
                    icon: 'list'
                }
            ]
        },
        days: {
            'end of epa': HostEpaEndDay,
        },
    },
    coordinator: {
        roleName: 'Coordinador',
        level: 'Desempeño',
        description: 'Nivel de influencia 3',
        levelDescription: 'Rol de Liderazgo',
        circle: '🔴',
        days: {
            'induction day': DynamicChallengeTree,
            'rules day': DynamicChallengeTree,
            'day one': DynamicChallenge,
            'day three': DynamicChallengeTree,
            'day two': DynamicChallenge,
            'filter day': DynamicChallenge,
            'alignment day': DynamicChallenge,
            'epa day': CoordinatorEpaDay,
        },
        challenges: {
            'induction day': [
                {
                    id: 1,
                    name: 'Desafío A',
                    description: 'Challenge A description',
                    completed: false,
                    icon: 'coordinator-sync',
                    position: 'first',
                    nameDay: 'Día de Inducción',
                    bricks: getBricksChallengeA,
                },
                {
                    id: 2,
                    name: 'Desafío B',
                    description: 'Challenge B description',
                    completed: false,
                    icon: 'coordinator-calendar',
                    bricks: getBricksChallengeB,
                },
                {
                    id: 3,
                    name: 'Desafío C',
                    description: 'Challenge C description',
                    completed: false,
                    icon: 'check',
                    bricks: getBricksChallengeC,
                }
            ],
            'rules day': [
                {
                    id: 1,
                    name: 'Desafío A',
                    description: 'Challenge A description',
                    completed: false,
                    icon: 'coordinator-calendar',
                    position: 'second',
                    nameDay: 'Día de reglas',
                    bricks: getBricksCoordinatorRulesDayA,
                },
                {
                    id: 2,
                    name: 'Desafío B',
                    description: 'Challenge B description',
                    completed: false,
                    icon: 'mic',
                    bricks: getBricksCoordinatorRulesDayB,
                },
                {
                    id: 3,
                    name: 'Desafío C',
                    description: 'Challenge C description',
                    completed: false,
                    icon: 'coordinator-sync',
                    bricks: getBricksCoordinatorRulesDayC,
                }
            ],
            'day one': [
                {
                    id: 1,
                    name: 'Desafío A',
                    description: 'Challenge A description',
                    completed: false,
                    icon: 'mic',
                    position: 'third',
                    nameDay: 'Día 1',
                    bricks: getBricksCoordinatorFirstDayA,
                },
                {
                    id: 2,
                    name: 'Desafío B',
                    description: 'Challenge B description',
                    completed: false,
                    icon: 'coordinator-hand-key',
                    bricks: getBricksCoordinatorFirstDayB,
                },
                {
                    id: 3,
                    name: 'Desafío C',
                    description: 'Challenge C description',
                    completed: false,
                    icon: 'book',
                    bricks: getBricksCoordinatorFirstDayC,
                }
            ],
            'day two': [
                {
                    id: 1,
                    name: 'Desafío A',
                    description: 'Challenge A description',
                    completed: false,
                    icon: 'mic',
                    position: 'fourth',
                    nameDay: 'Día 2',
                    bricks: getBricksCoordinatorSecondDayA,
                },
                {
                    id: 2,
                    name: 'Desafío B',
                    description: 'Challenge B description',
                    completed: false,
                    icon: 'coordinator-hand-key',
                    bricks: getBricksCoordinatorSecondDayB,
                },
                {
                    id: 3,
                    name: 'Desafío C',
                    description: 'Challenge C description',
                    completed: false,
                    icon: 'book',
                    bricks: getBricksCoordinatorSecondDayC,
                }
            ],
            'day three': [
                {
                    id: 1,
                    name: 'Desafío A',
                    description: 'Challenge A description',
                    completed: false,
                    icon: 'mic',
                    position: 'third',
                    nameDay: 'Día 3',
                    bricks: getBricksCoordinatorThirdDayA,
                },
                {
                    id: 2,
                    name: 'Desafío B',
                    description: 'Challenge B description',
                    completed: false,
                    icon: 'coordinator-hand-key',
                    position: 'third',
                    nameDay: 'Día 3',
                    bricks: getBricksCoordinatorThirdDayB,
                },
                {
                    id: 3,
                    name: 'Desafío C',
                    description: 'Challenge C description',
                    completed: false,
                    icon: 'book',
                    bricks: getBricksCoordinatorThirdDayC,
                }
            ],
            'filter day': [
                {
                    id: 1,
                    name: 'Desafío A',
                    description: 'Challenge A description',
                    completed: false,
                    icon: 'touch-screen-coordinator',
                    position: 'fourth',
                    bricks: getBricksCoordinatorFilterDayA,
                },
                {
                    id: 2,
                    name: 'Desafío B',
                    description: 'Challenge B description',
                    completed: false,
                    icon: 'coordinator-screen-tools',
                    bricks: getBricksCoordinatorFilterDayB,
                },
                {
                    id: 3,
                    name: 'Desafío C',
                    description: 'Challenge C description',
                    completed: false,
                    icon: 'mic',
                    bricks: getBricksCoordinatorFilterDayC,
                }
            ],
            'alignment day': [
                {
                    id: 1,
                    name: 'Desafío A',
                    description: 'Challenge A description',
                    completed: false,
                    icon: 'touch-screen-coordinator',
                    position: 'fifth',
                    bricks: getBricksCoordinatorAlignmentDayA,
                },
                {
                    id: 2,
                    name: 'Desafío B',
                    description: 'Challenge B description',
                    completed: false,
                    icon: 'coordinator-calendar',
                    bricks: getBricksCoordinatorAlignmentDayB,
                },
                {
                    id: 3,
                    name: 'Desafío C',
                    description: 'Challenge C description',
                    completed: false,
                    icon: 'coordinator-sync',
                    bricks: getBricksCoordinatorAlignmentDayC,
                }
            ],
            'epa day': [
                {
                    id: 1,
                    name: 'Desafío E',
                    description: 'Challenge A description',
                    completed: false,
                    icon: 'mic',
                    position: 'eighth',
                    nameDay: 'Día de EPA',
                },
                {
                    id: 2,
                    name: 'Desafío P',
                    description: 'Challenge B description',
                    completed: false,
                    icon: 'good-hand'
                },
                {
                    id: 3,
                    name: 'Desafío A',
                    description: 'Challenge C description',
                    completed: false,
                    icon: 'list'
                }
            ],
        }
    },
    'new director': {
        circle: '🟡',
        challenges: {
            'end of epa': [
                {
                    id: 1,
                    name: 'Desafío Honor',
                    description: 'Challenge A description',
                    completed: false,
                    icon: 'calendar',
                    position: 'ninth',
                    nameDay: 'Final del EPA',
                },
                {
                    id: 2,
                    name: 'Desafío Humildad',
                    description: 'Challenge B description',
                    completed: false,
                    icon: 'good-hand'
                },
                {
                    id: 3,
                    name: 'Desafío Hambre',
                    description: 'Challenge C description',
                    completed: false,
                    icon: 'list'
                }
            ]
        },
        days: {
            'end of epa': DirectorEpaEndDay,
        },
    },
    'director': {
        roleName: 'Director',
        level: 'Desempeño',
        description: 'Nivel de influencia 3',
        levelDescription: 'Rol de Liderazgo',
        circle: '🟡',
        days: {
            'induction day': DynamicChallenge,
            'epa day': DirectorEpaDay,
        },
        challenges: {
            'induction day': [
                {
                    id: 1,
                    name: 'Desafío A',
                    description: 'Challenge A description',
                    completed: false,
                    icon: 'director-sync',
                    position: 'first',
                    nameDay: 'Día de Inducción',
                    bricks: getBricksDirectorInductionDayDayA,
                },
                {
                    id: 2,
                    name: 'Desafío B',
                    description: 'Challenge B description',
                    completed: false,
                    icon: 'coordinator-screen-tools',
                    bricks: getBricksDirectorInductionDayDayB,
                },
                {
                    id: 3,
                    name: 'Desafío C',
                    description: 'Challenge C description',
                    completed: false,
                    icon: 'mic',
                    bricks: getBricksDirectorInductionDayDayC,
                }
            ],
            'epa day': [
                {
                    id: 1,
                    name: 'Desafío E',
                    description: 'Challenge A description',
                    completed: false,
                    icon: 'mic',
                    position: 'eighth',
                    nameDay: 'Día de EPA',
                },
                {
                    id: 2,
                    name: 'Desafío P',
                    description: 'Challenge B description',
                    completed: false,
                    icon: 'good-hand'
                },
                {
                    id: 3,
                    name: 'Desafío A',
                    description: 'Challenge C description',
                    completed: false,
                    icon: 'list'
                }
            ],
        }
    },
    'new captain': {
        circle: '🔵',
        challenges: {
            'end of epa': [
                {
                    id: 1,
                    name: 'Desafío Honor',
                    description: 'Challenge A description',
                    completed: false,
                    icon: 'calendar',
                    position: 'ninth',
                    nameDay: 'Final del EPA',
                },
                {
                    id: 2,
                    name: 'Desafío Humildad',
                    description: 'Challenge B description',
                    completed: false,
                    icon: 'good-hand'
                },
                {
                    id: 3,
                    name: 'Desafío Hambre',
                    description: 'Challenge C description',
                    completed: false,
                    icon: 'list'
                }
            ]
        },
        days: {
            'end of epa': CaptainEpaEndDay,
        },
    },
    captain: {
        roleName: 'Captain',
        level: 'Desempeño',
        description: 'Nivel de influencia 4',
        levelDescription: 'Rol de Liderazgo',
        circle: '🔵',
    },
    days: {
        'epa day': CaptainEpaEndDay,
    },
    challenges: {
        'epa day': [
            {
                id: 1,
                name: 'Desafío E',
                description: 'Challenge A description',
                completed: false,
                icon: 'mic',
                position: 'eighth',
                nameDay: 'Día de EPA',
            },
            {
                id: 2,
                name: 'Desafío P',
                description: 'Challenge B description',
                completed: false,
                icon: 'good-hand'
            },
            {
                id: 3,
                name: 'Desafío A',
                description: 'Challenge C description',
                completed: false,
                icon: 'list'
            }
        ],
    }
};
;

const IMAGE_OPTIONS = {
    'challenge-icon-practice': {
        label: 'Práctica y sencilla',
        alt: 'Práctica y sencilla',
        image: IconTools,
    },
    'challenge-icon-criticism': {
        label: 'Crítica y analítica',
        alt: 'Crítica y analítica',
        image: IconLens,
    },
    'challenge-icon-creative': {
        label: 'Creativa y original',
        alt: 'Creativa y original',
        image: IconBulb,
    },
    'challenge-icon-helpful': {
        label: 'Servicial y amable',
        alt: 'Servicial y amable',
        image: IconHeart,
    },
    'challenge-icon-expressive': {
        label: 'Expresiva y segura de sí misma',
        alt: 'Expresiva y segura de sí misma',
        image: IconMicrophone,
    },
    'challenge-icon-efficient': {
        label: 'Eficiente y precavida',
        alt: 'Eficiente y precavida',
        image: IconShield,
    },
};

const SKILLS = {
    leadershipAndSelfManagement: 'Autogestión y Liderazgo:',
    effectiveCommunication: 'Comunicación Efectiva:',
    criticalThinking: 'Pensamiento Crítico:',
    teamwork: 'Trabajo en Equipo:',
    creativity: 'Creatividad:',
    empathy: 'Empatía:',
    education: 'Educación',
    entrepreneurship: 'Emprendimiento',
    other: 'Otro',
    professionalServices: 'Servicios Profesionales',
    projects: 'Proyectos',
    technicalServices: 'Servicios Técnicos',
};

const ALIAS_DAYS = {
    'induction day': 'Ind',
    'rules day': 'RJ',
    'day one': 'D1',
    'day two': 'D2',
    'day three': 'D3',
    'filter day': 'Fil',
    'alignment day': 'Alin',
}

const PROCESS_STATUS = {
    registered: 'Registrado',
    scheduled: 'Agendado',
    waiting: 'En espera',
    earring: 'Pendiente',
}

const PAGES = {
    missions: {
        bricks: [
            {
                type: TabsProvider,
                bricks: [{
                    type: Background,
                    props: {
                        className: 'missions-page',
                        full: true,
                    },
                    bricks: [
                        {
                            type: Header,
                            props: {
                                id: 'header',
                                title: 'Misiones Diarias',
                                background: 'primary',
                            }
                        },
                        {
                            type: Tabs,
                            bricks: [
                                {
                                    type: Tab,
                                    props: {
                                        children: 'Mi Progreso',
                                        active: true,
                                    }
                                },
                                {
                                    type: Tab,
                                    props: {
                                        children: 'Mis Validadores',
                                        active: false,
                                    }
                                },
                                {
                                    type: Tab,
                                    props: {
                                        children: 'Mi Desempeño',
                                        active: false,
                                    }
                                },
                            ],
                        },
                        {
                            type: MyProgress,
                            condition: {
                                context: TabsContext,
                                key: 'activeTab',
                                value: 0
                            },
                        },
                        {
                            type: MyValidatorsTab,
                            condition: {
                                context: TabsContext,
                                key: 'activeTab',
                                value: 1
                            },
                            bricks: [
                                {
                                    type: PdlBadge,
                                    props: {
                                        className: 'pdl-badge',
                                    },
                                },
                            ],
                        },
                        {
                            type: MyPerformance,
                            condition: {
                                context: TabsContext,
                                key: 'activeTab',
                                value: 2
                            },
                        }
                    ],
                }]
            }]
    }
};

const MONTH_MAP = {
    '1': 'Ene',
    '2': 'Feb',
    '3': 'Mar',
    '4': 'Abr',
    '5': 'May',
    '6': 'Jun',
    '7': 'Jul',
    '8': 'Ago',
    '9': 'Sep',
    '10': 'Oct',
    '11': 'Nov',
    '12': 'Dec',
};

const indexes = ['first', 'second', 'third', 'fourth', 'fifth', 'sixth', 'seventh', 'eighth'];

const componentMap = {
    assignationMedalList: AssignationMedalList,
    button: Button,
    buyDate: BuyDate,
    card: Card,
    cardImage: CardImage,
    cardCopyKey: CardCopyKey,
    completeRequirements: CompleteRequirements,
    copyField: CopyField,
    counterBadges: CounterBadges,
    checkbox: Checkbox,
    datePicker: CustomDatePicker,
    giftSwitch: GiftSwitch,
    grid: Grid,
    gridDatePicker: GridDatePicker,
    gridGiftSwitch: GridGiftSwitch,
    groupRadio: GroupRadio,
    generationTable: GenerationTable,
    image: Image,
    packagesInfoModal: PackagesInfoModal,
    paragraph: Paragraph,
    pdlList: PdlList,
    previewEpa: PreviewEpa,
    step: Step,
    switch: Switch,
    menuUserInfo: MenuUserInfo,
    newCoordinatorSchedule: NewCoordinatorsSchedule,
    quote: Quote,
    readingSection: ReadingSection,
    roomsMedalList: RoomsMedalList,
    textField: TextField,
    textFieldList: TextFieldList,
    textFieldPoints: TextFieldPoints,
    toggle: Toggle,
    modal: Modal,
    listModal: ListModal,
    userCardInfo: UserCardInfo,
    userInfoDate: UserInfoDate,
};

export {
    API,
    MESSAGES,
    ROLES,
    IMAGE_OPTIONS,
    SKILLS,
    PROCESS_STATUS,
    PAGES,
    MONTH_MAP,
    indexes,
    componentMap,
    ALIAS_DAYS,
};