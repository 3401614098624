import WarningIcon from '../../resources/images/warning.png';
import ShipCoordinator from "../../resources/images/ship-coordinator.png";
import GoodHandCoordinator from '../../resources/images/good-hand-coordinator.png';
import TimeManagementMedal from "../../resources/images/time-management-medal-coordinator.svg";
import ClapHandCoordinator from "../../resources/images/clap-hand-coordinator.png";

export const getBricksChallengeA = () => [
    {
        type: 'step',
        props: {
            order: 1,
        },
        bricks: [
            {
                type: 'paragraph',
                props: {
                    size: 's',
                    align: 'center',
                },
                children: '<b>¡Hey!</b>',
            },
            {
                type: 'image',
                props: {
                    src: WarningIcon,
                    alt: 'Warning',
                    style: {
                        marginTop: '28px'
                    }
                }
            },
            {
                type: 'paragraph',
                props: {
                    size: 's',
                    align: 'center',
                    withBackground: true,
                    style: {
                        backgroundColor: '#1A2233',
                        marginTop: '28px',
                        padding: '25px 27px 31px',
                        maxWidth: '289px',
                    }
                },
                children: `<b>Este desafío se realiza por fuera de la Plataforma ACDC.</b>
<br />
<br />
Cuando tu Director(a) reciba y confirme que le enviaste la nota de voz correspondiente al <b>Desafío A</b> podrás continuar con tus desafíos diarios.
`
            },
            {
                type: 'button',
                props: {
                    styles: {
                        position: 'relative',
                        marginTop: '120px',
                    },
                },
                children: '¡Vale!',
                action: 'next',
            }
        ],
    },
    {
        type: 'step',
        props: {
            order: 2,
        },
        bricks: [
            {
                type: 'image',
                props: {
                    src: ShipCoordinator,
                    alt: 'SpaceShip copordinator',
                    style: {
                        marginTop: '28px'
                    }
                }
            },
            {
                type: 'paragraph',
                props: {
                    size: 's',
                    align: 'center',
                },
                children: `<b>¡Prepárate para el EPA!</b><br /><br />Ahora vas a tener la oportunidad de:
<br /><br />
Familiarizarte con la Visualización de la tabla donde se muestra si tus Anfitriones le han otorgado la medalla de Manejo del Tiempo 🥇 correctamente a sus Aspirantes en su participación cuando estén en el EPA.`,
            },
            {
                type: 'button',
                props: {
                    styles: {
                        position: 'relative',
                        marginTop: '80px',
                    },
                },
                children: 'Continuar',
                action: 'next',
            }
        ]
    },
    'coordinatorAlignmentDayA',
    // {
    //     type: 'step',
    //     props: {
    //         order: 3,
    //     },
    //     bricks: [
    //         {
    //             type: 'paragraph',
    //             props: {
    //                 size: 's',
    //                 align: 'center',
    //             },
    //             children: `<b>Módulo simluador de medallas.</b><br /><br />Aquí podrás simular cómo se visualizan las medallas de los Aspirantes y a su vez cómo se  otorgan las <b>Medallas de Manejo del Tiempo</b> 🥇 a tus Anfitriones cuando estés en el EPA.`,
    //         },
    //         'coordinatorAlignmentDayA',
    //         {
    //             type: 'button',
    //             props: {
    //                 role: 'coordinator',
    //                 styles: {
    //                     position: 'relative',
    //                     marginTop: '80px',
    //                 },
    //             },
    //             children: 'Continuar',
    //             action: 'next',
    //         }
    //     ]
    // },
    {
        type: 'step',
        props: {
            order: 4,
        },
        bricks: [
            {
                type: 'paragraph',
                props: {
                    size: 's',
                    align: 'center',
                },
                children: `<b>¡Felicitaciones!</b><br />
Tu participación compartiendo tu PDL con el equipo fue perfecta.`,
            },
            {
                type: 'image',
                props: {
                    src: GoodHandCoordinator,
                    alt: 'Good hand coordinator',
                    style: {
                        marginTop: '28px'
                    }
                }
            },
            {
                type: 'paragraph',
                props: {
                    size: 's',
                    align: 'center',
                },
                children: `¡Has ganado una medalla de <b>Manejo del  Tiempo!</b>`,
            },
            {
                type: 'image',
                props: {
                    src: TimeManagementMedal,
                    alt: 'Time management medal',
                    className: 'coordinator',
                    style: {
                        marginTop: '28px',
                    }
                }
            },
            {
                type: 'button',
                props: {
                    styles: {
                        position: 'relative',
                        marginTop: '80px',
                    },
                },
                children: 'Ver video',
            },
            {
                type: 'button',
                props: {
                    size: 'small',
                    styles: {
                        position: 'relative',
                        marginTop: '40px',
                    },
                },
                children: 'Continuar',
                action: 'next',
            }
        ]
    },
    {
        type: 'step',
        props: {
            order: 5,
        },
        bricks: [
            {
                type: 'paragraph',
                props: {
                    size: 's',
                    align: 'center',
                },
                children: `<b>¡Coordinador(a)! Ahora vas a simular cómo dar Medallas en Equipo de Justo a Tiempo.</b><br /><br />Para ganar esta medalla de equipo, tu Director(a) debió haber completado la pausa #4 de la Sala Épica máximo 30 minutos después de la hora de inicio del EPA.`
            },
            {
                type: 'roomsMedalList',
                props: {
                    hostWinners: [
                        {
                            challenges: [
                                {
                                    nameChallenge: 'Sala Épica',
                                    winner: true,
                                },
                                {
                                    nameChallenge: 'Sala Plataforma',
                                    winner: false,
                                },
                                {
                                    nameChallenge: 'Sala Admisiones',
                                    winner: false,
                                },
                            ]
                        }
                    ],
                    medal: 'time-management-medal',
                }
            },
            {
                type: 'button',
                props: {
                    role: 'coordinator',
                    styles: {
                        position: 'relative',
                        marginTop: '80px',
                    },
                },
                children: 'Continuar',
                action: 'next',
            }
        ]
    },
    {
        type: 'step',
        props: {
            order: 6,
        },
        bricks: [
            {
                type: 'paragraph',
                props: {
                    size: 's',
                    align: 'center',
                },
                children: '¡Genial!<br/><br/>Has completado el Desafío A.'
            },
            {
                type: 'image',
                props: {
                    src: ClapHandCoordinator,
                    alt: 'clap-hand-coordinator',
                    style: {
                        marginTop: '10px'
                    }
                }
            },
            {
                type: 'paragraph',
                props: {
                    size: 's',
                    align: 'center',
                    type: 'secondary',
                    withBackground: true,
                },
                children: 'Ahora que ya hiciste la simulación de medallas del EPA, continúa con el siguiente ▶️ VIDEO, para seguir con tu Alineamiento.<br/><br/>👋🏻 Ya nos vemos.'
            },
            {
                type: 'button',
                props: {
                    styles: {
                        position: 'relative',
                        marginTop: '96px',
                    },
                },
                children: 'Ver video',
                action: 'finish',
            }
        ]
    }
];