import CoordinatorCheckList from '../../resources/images/coordinator-check-list.png';
import CoordinatorH2Key from '../../resources/images/coordinator-h2-key.png';
import Megaphone from '../../resources/images/megaphone.png';

export const getBricksChallengeB = () => [
    {
        type: 'step',
        props: {
            order: 1,
        },
        bricks: [
            {
                type: 'readingSection',
                props: {
                },
                bricks: [
                    {
                        type: 'paragraph',
                        props: {
                            size: 's',
                            align: 'center',
                        },
                        children: `Humildad`,
                    },
                    {
                        type: 'paragraph',
                        props: {
                            size: 's',
                            align: 'left',
                        },
                        children: `“Lo opuesto de arrogancia es la humildad ¿Con quién debe ser humilde el líder? La respuesta: con cualquiera que tenga algo que enseñarle.” “Un líder debe estar sinceramente interesado en aprender de una manera regular. El desinterés o la apatía llevarán a un conocimiento viejo y una pobre habilidad para tomar decisiones.”`,
                    }
                ]
            },
            {
                type: 'quote',
                bricks: [
                    {
                        type: 'paragraph',
                        props: {
                            size: 's',
                            align: 'left',
                            style: {
                                margin: '0'
                            }
                        },
                        children: 'Tomado del libro:'
                    },
                    {
                        type: 'paragraph',
                        props: {
                            size: 's',
                            align: 'left',
                            style: {
                                margin: '0',
                                fontWeight: '700'
                            }
                        },
                        children: 'Lanzando una Revolución sobre el Liderazgo.'
                    },
                ]
            },
            {
                type: 'button',
                props: {
                    styles: {
                        position: 'relative',
                        marginTop: '96px',
                    },
                },
                children: 'Continuar',
                action: 'next',
            }
        ]
    },
    {
        type: 'step',
        props: {
            order: 2,
        },
        bricks: [
            {
                type: 'image',
                props: {
                    src: Megaphone,
                    alt: 'clap-hand-coordinator',
                    style: {
                        marginTop: '54px'
                    }
                }
            },
            {
                type: 'paragraph',
                props: {
                    size: 's',
                    align: 'center',
                },
                children: 'Hoy has aprendido que el proveedor seleccionado no solo permite llegar a <strong style="color:#1EFF00">105 mil dólares</strong>  al estar Asegurado Con Dos Conectados, sino que además podemos ganar bonos de liderazgo adicionales si tenemos más de dos directos. '
            },
            {
                type: 'button',
                props: {
                    styles: {
                        position: 'relative',
                        marginTop: '196px',
                    },
                },
                children: 'Continuar',
                action: 'next',
            }
        ]
    },
    {
        type: 'step',
        props: {
            order: 3,
        },
        bricks: [
            {
                type: 'paragraph',
                props: {
                    size: 's',
                    align: 'center',
                },
                children: '<b>Actividad Selecciona solo una respuesta</b>'
            },
            {
                type: 'image',
                props: {
                    src: CoordinatorCheckList,
                    alt: 'coordinator-check-list',
                    style: {
                        marginTop: '10px'
                    }
                }
            },
            {
                type: 'paragraph',
                props: {
                    size: 's',
                    align: 'center',
                    style: {
                        marginTop: '46px',
                    }
                },
                children: 'Ten en cuenta antes de responder, las respuestas están en el video que acabas de ver y lo que te respondió TWOBOT.'
            },
            {
                type: 'button',
                props: {
                    styles: {
                        position: 'relative',
                        marginTop: '96px',
                    },
                },
                children: 'Continuar',
                action: 'next',
            }
        ]
    },
    
     'getBricksChallengeB',
    
    {
        type: 'step',
        props: {
            order: 5,
        },
        bricks: [
            {
                type: 'paragraph',
                props: {
                    size: 's',
                    align: 'center',
                },
                children: 'Con la estrategia de ACDC (Asegúrate Con Dos Conectados) ¿En cúal generación entran 2048 personas que compran a cada lado?'
            },
            {
                type: 'checkbox',
                props: {
                    label: 'En la generación 10 (g10)',
                    name: 'acdc-strategy',
                    style: {
                        width: '288px',
                        padding: '0px 0px 0px 35px',
                        marginTop: '30px',
                    }

                }
            },
            {
                type: 'checkbox',
                props: {
                    label: 'En la generación 11 (g11)',
                    name: 'acdc-strategy',
                    style: {
                        width: '288px',
                        padding: '0px 0px 0px 35px',
                        marginTop: '40px',
                    }
                }
            },
            {
                type: 'checkbox',
                props: {
                    label: 'En la generación 12 (g12)',
                    name: 'acdc-strategy',
                    style: {
                        width: '288px',
                        padding: '0px 0px 0px 35px',
                        marginTop: '40px',
                    }
                }
            },
            {
                type: 'checkbox',
                props: {
                    label: 'En la generación 13 (g13)',
                    name: 'acdc-strategy',
                    style: {
                        width: '288px',
                        padding: '0px 0px 0px 35px',
                        marginTop: '40px',
                    }
                }
            },
            {
                type: 'button',
                props: {
                    styles: {
                        position: 'relative',
                        marginTop: '226px',
                    },
                },
                children: 'Continuar',
                action: 'next',
            }
        ]
    },
    {
        type: 'step',
        props: {
            order: 6,
        },
        bricks: 'getBricksChallengeBStepSix',
    },
    {
        type: 'step',
        props: {
            order: 7,
        },
        bricks: [
            {
                type: 'paragraph',
                props: {
                    size: 's',
                    align: 'center',
                },
                children: `<span class="icon-info" style="display: inline-flex; width: 32px; height: 32px; background-color: #0063DD; color: white; border-radius: 50%; font-size: 32px; align-items: center; justify-content: center;"></span><br/><br/>Información Bonos Gen 5`,
            },
            {
                type: 'paragraph',
                props: {
                    size: 's',
                    align: 'center',
                    style: {
                        marginTop: '24px',
                    }
                },
                children: `En el caso anterior asumimos la aplicación de la estrategía de ACDC donde el crecimiento de cada ganeración es el doble de la anterior. Sin embargo el proveedor seleccionado permite tener ilimitadas personas en cada generación, es decir, si decides tener más de 2 en tu primera generación también <strong>te pagarán Bono de Inicio Rápido Gen 5 hasta la 5ta generación de cada persona</strong> que decidas tener en tu primera Generación. Sin embargo sólo podrás aplicar la estrategía de ACDC con tus primeros 2.
  <br /><br  />
Adicionalmente es importante tener en cuenta que si compras un paquete ESP 1 el  pago de comisiones del Bono Gen 5 por cada generación es el de la tabla anterior sin importar que tus generaciones  compren paquetes ESP 2 ó ESP 3.`
            },
            {
                type: 'button',
                props: {
                    styles: {
                        position: 'relative',
                        marginTop: '96px',
                    },
                },
                children: 'Continuar',
                action: 'next',
            }
        ]
    },
    {
        type: 'step',
        props: {
            order: 8,
        },
        bricks:'getBricksChallengeBStepEight',
    },
    {
        type: 'step',
        props: {
            order: 9,
        },
        bricks: [
            {
                type: 'paragraph',
                props: {
                    size: 's',
                    align: 'center',
                },
                children: `<span class="icon-info" style="display: inline-flex; width: 32px; height: 32px; background-color: #0063DD; color: white; border-radius: 50%; font-size: 32px; align-items: center; justify-content: center;"></span><br/><br/>Información Bonos Gen 5`,
            },
            {
                type: 'paragraph',
                props: {
                    size: 's',
                    align: 'center',
                    style: {
                        marginTop: '24px',
                    }
                },
                children: `El Proveedor seleccionado cuenta con un plan de compensación de 12 Bonos de los cuales hoy sólo nos hemos enfocado en 2, El bono de inicio rápido Gen5 y el bono binario,  sin embargo en el botón Descargar PDF que está abajo puedes conocer el detalle de todos los 12 Bonos.`
            },
            {
                type: 'button',
                props: {
                    size: 'small',
                    styles: {
                        position: 'relative',
                        marginTop: '56px',
                    },
                },
                children: 'Decargar PDF',
            },
            {
                type: 'paragraph',
                props: {
                    size: 'xs',
                    align: 'center',
                    style: {
                        marginTop: '193px',
                    }
                },
                children: 'Descarga el PDF para poder continuar',
            },
            {
                type: 'button',
                props: {
                    styles: {
                        position: 'relative',
                        marginTop: '32px',
                    },
                },
                children: 'Continuar',
                action: 'next',
            }
        ]
    },
    {
        type: 'step',
        props: {
            order: 10,
        },
        bricks: [
            {
                type: 'paragraph',
                props: {
                    size: 's',
                    align: 'center',
                },
                children: `<span class="icon-info" style="display: inline-flex; width: 32px; height: 32px; background-color: #0063DD; color: white; border-radius: 50%; font-size: 32px; align-items: center; justify-content: center;"></span><br/><br/>Información Bonos Gen 5`,
            },
            {
                type: 'paragraph',
                props: {
                    size: 's',
                    align: 'center',
                    style: {
                        marginTop: '24px',
                    }
                },
                children: `En la penúltima página del PDF encontrarás una tabla final que se llama “Rangos de Calificación y Potencial” donde en la fila 9 dice: <br /><br />Total de Potencial Máximo  de ganancias Semanales por Compensaciones de Equipo Binario por Centro de Negocios y por país es de: $10.000 dólares. <br /><br />Con la estrategía de ACDC Asumiendo sólo Un Centro De Negocios y suponiendo que sólo se haga la estrategia en 10 Paises el potencial sería de: <br /><br /><strong>$100.000 dólares a la semana</strong> <br />es decir<br /> <strong>$400.000 dólares mensuales</strong>`
            },
            {
                type: 'button',
                props: {
                    styles: {
                        position: 'relative',
                        marginTop: '96px',
                    },
                },
                children: 'Continuar',
                action: 'next',
            }
        ]
    },
    {
        type: 'step',
        props: {
            order: 11,
        },
        bricks: 'getBricksChallengeBStepEleven',
    },
    {
        type: 'step',
        props: {
            order: 12,
        },
        bricks: [
            {
                type: 'paragraph',
                props: {
                    size: 's',
                    align: 'center',
                },
                children:`Nombre Coordinador(a) si no realizas tu compra asegurada antes del EPA, quedarás en
                <br /> 
<strong style="color: #FF9090">GAME OVER.</strong> 
<br /><br /> 
<strong>¿Qué significa esto?</strong>
<br /><br /> 
Que <strong>NO</strong> podrás asistir al EPA, porque tu rol en el EPA es Liderazgo y no podrás ser un líder frente a tu equipo, si no has hecho la compra con el proveedor seleccionado. Por lo tanto, no estarás dentro de la red comercial del proveedor seleccionado y no te beneficiarás del crecimiento y las compras del equipo de tus Anfitriones porque estos pasarán a ser de tu Director(a). 
<br /><br /> 
Adicionalmente no podrás ser Capitán(a) y no serás parte del Ecosistema de Innovación ACDC en el que tendrás el apoyo de una red de profesionales, metodologías, tecnologías e inversionistas para materializar tu PDL.`,
            },
            {
                type: 'button',
                props: {
                    styles: {
                        position: 'relative',
                        marginTop: '96px',
                    },
                },
                children: 'Continuar',
                action: 'next',
            }
        ]
    },
    {
        type: 'step',
        props: {
            order: 13,
        },
        bricks: [
            {
                type: 'paragraph',
                props: {
                    size: 's',
                    align: 'center',
                },
                children: '¡Genial!<br/><br/>Has completado el Desafío B.'
            },
            {
                type: 'image',
                props: {
                    src: CoordinatorH2Key,
                    alt: 'H2 Key',
                    style: {
                        marginTop: '10px'
                    }
                }
            },
            {
                type: 'cardCopyKey',
                props: {
                    phrase: 'Desafío B Día 2 Coordinador',
                    nameChallenge: 'Desafío B',
                }
            },
            {
                type: 'button',
                props: {
                    styles: {
                        position: 'relative',
                        marginTop: '96px',
                    },
                },
                children: 'Finalizar',
                action: 'finish',
            }
        ]
    },
];