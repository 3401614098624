import { useContext, useEffect, useState } from "react";
import TextField from "../../atoms/text-field";
import MenuUserInfo from "../menu-user-info";
import Paragraph from "../../atoms/paragraph";
import Button from "../../atoms/button";
import MessageIcon from '../../../resources/images/message-icon.png';
import AnswerIcon from '../../../resources/images/answer-icon.png';
import axios from "axios";
import { ALIAS_DAYS, API, IMAGE_OPTIONS, MESSAGES, PROCESS_STATUS, ROLES, SKILLS } from "../../../middleware/utils/constants";
import { UserContext } from "../../../middleware/providers/user-context";
import Medals from "../medals";
import Pdl from "../../organisms/pdl";
import Select from "../../atoms/select";
import MedalControl from "../medal-control";
import { ReactComponent as TimeManagement } from "../../../resources/images/time-management-medal-new.svg";
import { ReactComponent as Recognition } from "../../../resources/images/recognition-medal-new.svg";
import { ReactComponent as JustInTime } from '../../../resources/images/just-in-time-medal-new.svg';
import { ReactComponent as ComodinMedal } from '../../../resources/images/comodin-medal.svg';
import GreenCheck from "../../../resources/images/green-check.png";
import { useParams } from "react-router-dom";
import Modal from "../../atoms/modal";
import Checkbox from "../../atoms/checkbox";
import TextArea from "../../atoms/text-area";
import Loader from "../loader";
import { assignMedal, getChallengesWithMedals, getInformation, getProgressPoint } from "../../../middleware/services/common";
import { changeProcessStatus } from "../../../middleware/services/managementServices";
import { BadgeDays } from "../badge-days";

const ValidatorInfo = ({
    name,
    role,
    nameRole,
    range,
    token,
    idChallenge,
    challenge,
    sent,
    perceptionValidator,
    skills,
    featuredSkills,
    experience,
    processStatus,
    status,
    other,
}) => {

    const { user } = useContext(UserContext);

    const { idUser } = useParams();

    const [tempStatus, setTempStatus] = useState(false);
    const [experienceState, setExperienceState] = useState('');
    const [screen, setScreen] = useState('info');
    const [changeDay, setChangeDay] = useState('');
    const [userInfo, setUserInfo] = useState({});
    const [discardedBy, setDiscardedBy] = useState(undefined);
    const [openChangeStatus, setOpenChangeStatus] = useState({ isOpen: false, content: {} });
    const [discardedByOptions, setDiscardedByOptions] = useState(false);
    const [challengesByDay, setChallengesByDay] = useState([]);
    const [modalMedals, setModalMedals] = useState('');
    const [medalName, setMedalName] = useState('');
    const [idChallengeSelected, setIdChallengeSelected] = useState('');
    const [auxActiveChallenge, setAuxActiveChallenge] = useState({});
    const [challengeSelected, setChallengeSelected] = useState(null);
    const [listChallenges, setListChallenges] = useState([]);

    const [loader, setLoader] = useState(false);

    const medalsToObtain = {
        justInTimeMedalsToGet: {
            component: <JustInTime width={90} height={81} />,
            medalName: 'justInTime'
        },
        timeManagementMedalsToObtain: {
            component: <TimeManagement width={90} height={81} />,
            medalName: 'timeManagement'
        },
        medalsToObtainForRecognition: {
            component: <Recognition width={90} height={81} />,
            medalName: 'recognition'
        },
        medalsToObtainWildcard: {
            component: <ComodinMedal width={90} height={81} />,
            medalName: 'wildcard'
        },
    };

    const fetchData = async () => {
        const info = await getInformation(idUser);
        setUserInfo(info);
        const progress = await getProgressPoint(idUser, undefined, role);
        const active = progress?.find((challenge, index) => {
            if (progress[index - 1]?.status && !challenge?.status) {
                return challenge;
            }
        }) || progress?.[0];
        setAuxActiveChallenge(active);
        handleSelect({ target: { value: active?.day } });
    };

    useEffect(() => {
        let experienceText = '';
        const keys = Object.keys(experience || {});
        keys?.forEach((key, index) => {
            if (SKILLS[key]) {
                experienceText += `${SKILLS[key]}`;
            }
            if (SKILLS[key] && 0 <= index && index < keys?.length - 1) {
                experienceText += ', ';
            }
        });
        setExperienceState(experienceText);
    }, [modalMedals]);

    useEffect(() => {
        if (idUser && (user?.role === 'host' || user?.role === 'candidate')) {
            fetchData();
        }
    }, [modalMedals, user, role]);

    useEffect(() => {
        if (changeDay === '') {
            setChallengesByDay([]);
        }
    }, [changeDay]);

    const changeStatus = (processStatus) => {

        const data = {
            processStatus,
            discardedBy: processStatus === 'discard' ? processStatus : undefined,
        };

        openChangeStatusModal(data);
    };

    const openChangeStatusModal = (data) => {
        if (data.processStatus === 'earring') {
            callChangeStatus(data);
        } else if (data.processStatus === 'discard') {
            setOpenChangeStatus({
                isOpen: true,
                content: {
                    question: '¿Estás seguro(a) que deseas descartar este Validador(a)?',
                    subTitle: 'Dejará de ser parte de tus validadores',
                    action: () => {
                        setOpenChangeStatus(false);
                        setDiscardedByOptions(true);
                    }
                }
            });
        } else if (data.processStatus === 'waiting list') {
            setOpenChangeStatus({
                isOpen: true,
                content: {
                    question: '¿Deseas agregar a tu Lista de Espera este Validador(a)?',
                    subTitle: 'Solo podrá ser agendado para un EPA futuro. Dejará de ser parte de tus validadores',
                    action: () => callChangeStatus(data)
                }
            });
        }
    };

    const callChangeStatus = async (data) => {
        const changeStatus = await changeProcessStatus(data, token);
        if (changeStatus === 200) {
            setScreen('info');
            setOpenChangeStatus(false);
        }
    };

    const handleSelect = async (e) => {
        setChangeDay(e.target.value);
        setListChallenges(null);
        setChallengeSelected(null);
        const withMedals = await getChallengesWithMedals(idUser, e.target.value);
        const challenges = withMedals?.filter((challenge) => {
            const obtain = Object.keys(medalsToObtain).filter((medal) => challenge[medal]);
            return obtain?.length > 0;
        }) || [];
        setListChallenges(withMedals);
        setChallengesByDay(challenges);
    };

    const handleSelectChallenge = (e) => {
        const challenge = listChallenges?.[e.target.value];
        setChallengeSelected(challenge);
    };

    const ValidatorForm = () => {

        const [email, setEmail] = useState('');
        const isShow = ROLES[user?.role]?.challenges[challenge?.day]?.[0]?.isShowValidatorForm;

        const renderStatus = () => {
            if (status) {
                return <span className="status validated">Validado</span>;
            } else if (sent || tempStatus) {
                return <span className="status sent">Esperando respuesta</span>;
            } else {
                return <span className="status no-sent">No enviado</span>;
            }
        };

        const sendEmail = () => {
            if (email?.length > 0) {
                setLoader(true);
                axios.post(`${API.SEND_VALIDATION_EMAIL.url}/${user?._id}/${encodeURIComponent(email)}/${token}/${idChallenge}`).then((response) => {
                    if (response.status === 201) {
                        setLoader(false);
                    }
                }).catch((error) => {
                    console.warn(error);
                    if (error.response.status === 400) {
                        alert(MESSAGES.ERROR[error.response.data.message]);
                        setTempStatus(true);
                        setLoader(false);
                    }
                });
            }
        };

        return isShow && <div className="validator-info__form">
            <span className="title">Correo de validación</span>
            <TextField
                name="email"
                placeholder="Ingresa aquí su correo electrónico"
                leftIcon={{ icon: 'icon-mail' }}
                value={email}
                pattern="[a-zA-Z0-9_%+-.]+@[a-zA-Z0-9]+.[a-zA-Z]{2,}"
                onChange={(e) => setEmail(e.target.value)}
                required
            />
            {renderStatus()}
            <Paragraph size="xs" align="center" >
                Enviaremos la solicitud de validación al correo personal de tu referencia.
            </Paragraph>
            <Button size="small" disabled={status || tempStatus} onClick={sendEmail}>Enviar</Button>
        </div>
    };

    const ThinkAboutMe = () => {
        return <>
            <span className="title with-image">
                <img src={MessageIcon} alt="message-icon" />
                ¿Qué dijo de mí?
            </span>
            <div className="validator-info__what-think-about-me">
                {!perceptionValidator ? 'Aún no te han validado' : <>
                    <div className="validator-info__what-think-about-me__item">
                        <span className="title">Experiencias:</span>
                        <Paragraph size="s" align="left" >
                            {experienceState}
                        </Paragraph>
                    </div>
                    <div className="validator-info__what-think-about-me__item">
                        <table>
                            <tr>
                                <th>Habilidades</th>
                                <th>Puntaje</th>
                            </tr>
                            {Object.keys(skills || {})?.map((skill, index) => <tr>
                                <td>{SKILLS[skill]}</td>
                                <td className="score">{skills[skill]}</td>
                            </tr>)}
                        </table>
                    </div>
                    <div className="validator-info__what-think-about-me__item">
                        <span className="title">Cualidades:</span>
                        <Paragraph size="s" align="left" >
                            {featuredSkills}
                        </Paragraph>
                    </div>
                    <div className="validator-info__what-think-about-me__item">
                        <span className="title">Motivo:</span>
                        <Paragraph size="s" align="left" >
                            {perceptionValidator}
                        </Paragraph>
                    </div>
                    {other && <div className="validator-info__what-think-about-me__item">
                        <span className="title">Otro:</span>
                        <Paragraph size="s" align="left" >
                            {other}
                        </Paragraph>
                    </div>}
                </>}
            </div>
        </>
    };

    const InfoScreen = () => {
        const canUpdate = ROLES[user?.role]?.challenges[challenge?.day]?.[0]?.canUpdate;
        const challenges = Object.keys(ALIAS_DAYS)?.map((key) => {
            return {
                challenges: userInfo?.challenges?.filter((challenge) => challenge?.day === key),
                alias: ALIAS_DAYS[key],
            };
        });

        return <>
            <MenuUserInfo
                name={name}
                role={role}
                nameRole={nameRole}
                range={range}
                icon={user?.role === 'host' && nameRole === 'Validador(a)' && <span className="icon-recognition"></span>}
            />
            <ValidatorForm />
            {PROCESS_STATUS[processStatus] && <div className="status"><span>Estado:</span> {PROCESS_STATUS[processStatus]}</div>}
            <BadgeDays days={challenges} role={userInfo?.role} />
            {canUpdate && <Button onClick={() => setScreen('update')} style="ghost">Actualizar estado</Button>}
            {user?.role !== 'candidate' && <Button onClick={() => setScreen('management')}>Gestionar medallas</Button>}
            {user?.role !== 'candidate' && <Button onClick={() => setScreen('answers')}>Ver respuestas desafíos</Button>}
            <ThinkAboutMe />
        </>
    };

    const ManagementScreen = () => {
        const isShow = ROLES[user?.role]?.isShowManagement;

        return isShow && <>
            <span className="title">
                <b>Medallas de:</b>
                <br />
                {name}
            </span>
            <Select onChange={handleSelect} disabled>
                <option value="">Elige un día del Cronograma</option>
                <option value="induction day" selected={auxActiveChallenge?.day === 'induction day'}>Día de Inducción</option>
                <option value="rules day" selected={auxActiveChallenge?.day === 'rules day'}>Día de Reglas</option>
                <option value="day one" selected={auxActiveChallenge?.day === 'day one'}>Día 1</option>
                <option value="day two" selected={auxActiveChallenge?.day === 'day two'}>Día 2</option>
                <option value="day three" selected={auxActiveChallenge?.day === 'day three'}>Día 3</option>
                <option value="filter day" selected={auxActiveChallenge?.day === 'filter day'}>Día de Filtro</option>
                <option value="alignment day" selected={auxActiveChallenge?.day === 'alignment day'}>Día de Alineamiento</option>
                <option value="epa day" selected={auxActiveChallenge?.day === 'epa day'}>EPA</option>
            </Select>
            {changeDay === '' || challengesByDay?.length === 0 ? <>
                <span className="icon-info"></span>
                <Paragraph size="s" align="center">
                    El símbolo <span className="icon-state-selected"></span>  en el selector, significa que ya cumpliste con las medallas correspondientes sl día seleccionado.
                </Paragraph>
            </> : <>
                {challengesByDay?.map((challenge) => {
                    return Object.keys(medalsToObtain)?.map((medal) => challenge[medal] && <MedalControl idUser={idUser} idChallenge={challenge.idChallenges} challengeName={challenge.nameChallenge} medal={medalsToObtain[medal]?.component} medalName={medalsToObtain[medal]?.medalName} disabled={challenge[medal] === challenge[medalsToObtain[medal]?.medalName] || challenge.nameChallenge !== auxActiveChallenge.nameChallenge} callBack={(value, medalName, idChallenge) => {
                        setModalMedals(value ? 'Si' : 'No');
                        setMedalName(medalName);
                        setIdChallengeSelected(idChallenge);
                    }} />);
                })}
            </>}
        </>
    };

    const UpdateScreen = () => {

        const isShow = ROLES[user?.role]?.isShowUpdate;

        return isShow && <>
            <img src={GreenCheck} alt="green-check" />
            <Paragraph size="s" align="center">
                Actualiza el estado de tu Validador(a):
                <br />
                <b>{name}</b>
            </Paragraph>
            <Button onClick={() => changeStatus('earring')} style="ghost">Pendiente</Button>
            <Button onClick={() => changeStatus('waiting list')} style="ghost">Lista de espera</Button>
            <Button onClick={() => changeStatus('discard')} style="ghost">Descartar</Button>
            <Paragraph size="s" align="left">
                <b>Pendiente:</b> Aún no te ha respondido, pero esperas su respuesta a más tardar, el Día de Reglas.
                <br />
                <br />
                <b>Lista de Espera:</b> Le interesa, pero no puede asistir al EPA en la fecha programada. Así que quieres poderlo agendar para un futuro EPA.
                <br />
                <br />
                <b>Descartar:</b> Por ahora, no tiene tiempo o no está interesado en ser parte del proyecto ACDC.
            </Paragraph>
        </>
    };

    const AnswersScreen = () => {
        const renderOption = (question) => {
            const prefix = 'Respuesta:';
            if (question?.options) {
                const option = question?.options.map((option, index) => { return { ...option, status: index === 0 } })?.find((option) => option.status);
                if (option?.option) {
                    return <>
                        <b>{prefix}</b>
                        <span className="result">{option.option}</span>
                    </>;
                } else if (option?.image) {
                    return <>
                        <b>{prefix}</b>
                        <span className="result">{IMAGE_OPTIONS[option?.image].label}</span>
                    </>;
                }
            } else if (question?.text) {
                return <>
                    <b>{prefix}</b>
                    <span className="result">{question.text}</span>
                </>;
            }

            return <>
                <b>{prefix}</b>
                <span className="result">Aún no hay respuesta</span>
            </>;
        };

        return <>
            <span className="title">
                <b>Respuestas de:</b>
                <br />
                {name}
            </span>
            <Select onChange={handleSelect}>
                <option value="">Elige un día del Cronograma</option>
                <option value="induction day" selected={changeDay === 'induction day'}>Día de Inducción</option>
                <option value="rules day" selected={changeDay === 'rules day'}>Día de Reglas</option>
                <option value="day one" selected={changeDay === 'day one'}>Día 1</option>
                <option value="day two" selected={changeDay === 'day two'}>Día 2</option>
                <option value="day three" selected={changeDay === 'day three'}>Día 3</option>
                <option value="filter day" selected={changeDay === 'filter day'}>Día de Filtro</option>
                <option value="alignment day" selected={changeDay === 'alignment day'}>Día de Alineamiento</option>
                <option value="epa day" selected={changeDay === 'epa day'}>EPA</option>
            </Select>
            <Select onChange={handleSelectChallenge}>
                <option value="">Elige un desafío</option>
                {listChallenges?.map((challenge, index) => {
                    return <option value={index} selected={challenge.nameChallenge === challengeSelected?.nameChallenge}>{challenge.nameChallenge}</option>
                })}
            </Select>
            {!challengeSelected || challengeSelected?.questions === null ? <>
                <span className="icon-info"></span>
                {challengeSelected?.questions !== null ? <Paragraph size="s" align="center">
                    Elige un día y un desafío para poder visualizar las respuestas.
                </Paragraph> : <Paragraph size="s" align="center">
                    El desafío seleccionado se realiza fuera de la plataforma.
                </Paragraph>}
            </> : challengeSelected?.questions?.map((question) =>
                <div className="validator-info__what-think-about-me answers-list">
                    {<Paragraph size="s" align="justify" className="answer">
                        <span className="title with-image">
                            <img src={AnswerIcon} alt="message-icon" />
                            <b>Actividad</b>
                        </span>
                        {question?.question}
                        <br />
                        <br />
                        {renderOption(question)}
                    </Paragraph>}
                </div>)}
        </>;
    };

    const renderScreen = () => {
        if (screen === 'info') {
            return <InfoScreen />;
        } else if (screen === 'management') {
            return <ManagementScreen />;
        } else if (screen === 'update') {
            return <UpdateScreen />;
        } else if (screen === 'answers') {
            return <AnswersScreen />;
        }
    };

    return (
        <>
            <div className="validator-info">
                {renderScreen()}
                {!['induction day', 'rules day'].find((day) => challenge?.day === day) && <>
                    <span className="title">
                        Medallería
                        <br />
                        Nivel 1: {nameRole}
                    </span>
                    <Medals role={role} recognition={userInfo?.recognition} timeManagement={userInfo?.timeManagement} justInTime={userInfo?.justInTime} />
                    <Pdl role={role} purpose={userInfo?.purpose} destination={userInfo?.destination} legacy={userInfo?.legacy} quatityOfStars={userInfo?.personalReferences?.filter((personalReference) => personalReference.status).length} />
                </>}
            </div>
            <Modal isOpen={openChangeStatus.isOpen}>
                <span className="title">{openChangeStatus?.content?.question}</span>
                <Paragraph size="s" align="center">{openChangeStatus?.content?.subTitle}</Paragraph>
                <div className="buttons">
                    <Button size="small" onClick={() => openChangeStatus?.content?.action?.()}>Sí</Button>
                    <Button size="small" onClick={() => setOpenChangeStatus(false)}>No</Button>
                </div>
            </Modal>
            <Modal isOpen={discardedByOptions} className="modal-discard-options">
                <span className="title">¡Acabas de descartar a tu Validador(a)!</span>
                <Paragraph size="s" align="center">Ahora cuéntanos ¿Por qué?</Paragraph>
                <div className="options">
                    <Checkbox label="No tiene tiempo" onChange={() => setDiscardedBy('do not have time')} checked={discardedBy === 'do not have time'} />
                    <Checkbox label="No está interesado" onChange={() => setDiscardedBy('he is not interested"')} checked={discardedBy === 'he is not interested"'} />
                    <Checkbox label="No está disponible" onChange={() => setDiscardedBy('not available')} checked={discardedBy === 'not available'} />
                    <Checkbox label="No cuenta con las herramientas" onChange={() => setDiscardedBy('does not have the tool')} checked={discardedBy === 'does not have the tool'} />
                    <Checkbox label="No tiene el perfil" onChange={() => setDiscardedBy('does not have the profile')} checked={discardedBy === 'does not have the profile'} />
                    <Checkbox label="No contestó" onChange={() => setDiscardedBy('I do not answer')} checked={discardedBy === 'I do not answer'} />
                    <Checkbox label="Otro" onChange={() => setDiscardedBy('other')} checked={discardedBy === 'other'} />
                </div>
                <TextArea placeholder="Escribe aquí el motivo" disabled={discardedBy !== 'other'} id="other-discarded" />
                <Button size="small" onClick={() => {
                    setDiscardedByOptions(false);
                    const data = {
                        processStatus: 'discard',
                        discardedBy,
                        other: discardedBy === 'other' ? document.getElementById('other-discarded').value : undefined
                    };

                    callChangeStatus(data);
                }}>Enviar</Button>
            </Modal>
            <Modal isOpen={modalMedals !== ''} onClose={() => setModalMedals('')}>
                <Paragraph size="s" align="center" >
                    ¿Estás seguro(a) que tu aspirante {modalMedals === 'Si' ? '' : 'NO'} ganó la medalla?
                </Paragraph>
                <Paragraph size="xs" align="center" >
                    Recuerda que tu Aspirante gana la medalla si su nota de voz tiene entre 50s y 1:10
                </Paragraph>
                <div className="buttons">
                    <Button size="small" onClick={async () => {
                        await assignMedal(medalName, modalMedals, idUser, idChallengeSelected);
                        setModalMedals('');
                        handleSelect({ target: { value: changeDay } });
                    }}>Sí</Button>
                    <Button size="small" onClick={() => setModalMedals('')}>No</Button>
                </div>
            </Modal>
            <Loader isShow={loader} />
        </>
    );
};

export default ValidatorInfo;