import TouchScreenCoordinator from "../../resources/images/touch-screen-coordinator.png";
import CheckHandCoordinator from "../../resources/images/check-hand-coordinator.png";
import ClapHandCoordinator from "../../resources/images/clap-hand-coordinator.png";
import WarningIcon from '../../resources/images/warning.png';
import CoordinatorTools from '../../resources/images/coordinator-tools.png';
import WhatsApp from "../../resources/images/whatsapp.png";
import YouTube from "../../resources/images/youtube-image.png";

export const getBricksChallengeB = () => [
    {
        type: 'step',
        props: { order: 1 },
        bricks: [
            {
                type: 'paragraph',
                props: {
                    size: 's',
                    align: 'center',
                },
                children: '<b>¡Prepárate para el EPA!</b><br /><br />Ahora es el momento de definir tu disponibilidad de fecha y hora para los próximos 2 EPAs, en los que asumirás el rol de Director(a). Define 4 opciones para que los Anfitriones y sus Aspirantes puedan seleccionar la que mejor se ajuste a sus horarios.',
            },
            {
                type: 'paragraph',
                props: {
                    size: 'xs',
                    align: 'center',
                },
                children: 'Verifica tu disponibilidad en la agenda para cada opción.',
            },
            {
                type: 'gridDatePicker',
                props: {
                }
            },
            {
                type: 'button',
                props: {
                    styles: {
                        position: 'relative',
                        marginTop: '120px',
                    },
                },
                children: 'Aceptar',
                action: 'next',
            }
        ]
    },
    {
        type: 'step',
        props: {
            order: 2,
        },
        bricks: [
            {
                type: 'paragraph',
                props: {
                    size: 's',
                    align: 'center',
                },
                children: `<b>Zona de aprendizaje.</b>`,
            },
            {
                type: 'image',
                props: {
                    src: TouchScreenCoordinator,
                    alt: 'touch image',
                    style: {
                        marginTop: '10px'
                    }
                }
            },
            {
                type: 'paragraph',
                props: {
                    size: 's',
                    align: 'center',
                    style: {
                        marginTop: '40px'
                    }
                },
                children: `Ahora experimentarás una simulación que replica los desafíos que desbloquearás para tu nuevo rol al final del EPA. <br/><strong style="color: #2FFC2B;">Presta atención para que puedas prepararte de manera óptima para cuando llegue el momento.</strong>
                <br/><br/><b>¡Tú serás el(la) Nuevo(a) Director(a)!<b>`,
            },
            {
                type: 'button',
                props: {
                    styles: {
                        position: 'relative',
                        marginTop: '80px',
                    },
                },
                children: 'Continuar',
                action: 'nextSimulate',
            }
        ]
    },
    'coodinatorWithDirectorAndCoordinatorAlignmentDayB',
    {
        type: 'step',
        props: {
            order: 4,
        },
        bricks: [
            {
                type: 'paragraph',
                props: {
                    size: 's',
                    align: 'center',
                },
                children: `<b>¡Nuevo(a) Director(a)!</b>
            <br /><br />
Estos son los cronogramas de tus Nuevos(as) Coordinadores(as). El día de Inducción será mañana y el Día de Alineamiento será un día antes del EPA.`
            },
            {
                type: 'newCoordinatorSchedule',
            }
        ]
    },
    {
        type: 'step',
        props: {
            order: 5,
        },
        bricks: [
            {
                type: 'image',
                props: {
                    src: WhatsApp,
                    alt: 'WhatsApp Logo',
                    style: {
                        marginTop: '65px'
                    }
                }
            },
            {
                type: 'paragraph',
                props: {
                    size: 's',
                    align: 'center',
                    style: {
                        marginTop: '49px',
                    }
                },
                children: `En este momento los Nuevos Coordinadores, deben estar realizando la invitación y agregando al grupo de WhatsApp a los Nuevos(as) Anfitriones(as).
                <br /><br />
            Tú, como Nuevo(a) Director(a), estás encargado(a) de verificar que se agreguen correctamente.`
            },
            {
                type: 'button',
                props: {
                    styles: {
                        position: 'relative',
                        marginTop: '80px',
                    },
                    size: 'small',
                    disabled: true,
                },
                children: 'Ver video',
            },
            {
                type: 'button',
                props: {
                    styles: {
                        position: 'relative',
                        marginTop: '20px',
                    },
                },
                children: 'Continuar',
                action: 'next',
            }
        ]

    },
    {
        type: 'step',
        props: {
            order: 6,
        },
        bricks: [
            {
                type: 'paragraph',
                props: {
                    size: 's',
                    align: 'center',
                },
                children: `A continuación deberás activar el switch de cada Nuevo(a) Anfitrión(a) para confirmar que ya se encuentran agregados al grupo de WhatsApp correspondiente.`,
            },
            {
                type: 'giftSwitch',
                props: {
                    name: 'Juan Perez',
                    role: 'host',
                    nameRole: 'Nuevo(a) Anfitrión',
                    range: 7,
                    orientation: 'row',
                    size: 'x-small',
                }
            },
            {
                type: 'giftSwitch',
                props: {
                    name: 'Juan Perez',
                    role: 'host',
                    nameRole: 'Nuevo(a) Anfitrión',
                    range: 7,
                    orientation: 'row',
                    size: 'x-small',
                }
            },
            {
                type: 'giftSwitch',
                props: {
                    name: 'Juan Perez',
                    role: 'host',
                    nameRole: 'Nuevo(a) Anfitrión',
                    range: 7,
                    orientation: 'row',
                    size: 'x-small',
                }
            },
            {
                type: 'giftSwitch',
                props: {
                    name: 'Juan Perez',
                    role: 'host',
                    nameRole: 'Nuevo(a) Anfitrión',
                    range: 7,
                    orientation: 'row',
                    size: 'x-small',
                }
            },
            {
                type: 'button',
                props: {
                    styles: {
                        position: 'relative',
                        marginTop: '80px',
                    },
                },
                children: 'Continuar',
                action: 'next',
            }
        ]
    },
    {
        type: 'step',
        props: {
            order: 7,
        },
        bricks: [
            {
                type: 'readingSection',
                props: {
                    size: 'small'
                },
                bricks: [
                    {
                        type: 'paragraph',
                        props: {
                            size: 's',
                            align: 'left',
                        },
                        children: `“Las palabras de un líder y los pasos que da, deben coincidir. Como dice el dicho: “Tu palabra es tu valor y tu valor está en tu palabra.”`,
                    }
                ]
            },
            {
                type: 'quote',
                bricks: [
                    {
                        type: 'paragraph',
                        props: {
                            size: 's',
                            align: 'left',
                            style: {
                                margin: '0'
                            }
                        },
                        children: 'Fragmento tomado del Libro'
                    },
                    {
                        type: 'paragraph',
                        props: {
                            size: 's',
                            align: 'left',
                            style: {
                                margin: '0',
                                fontWeight: '700'
                            }
                        },
                        children: 'Lanzando una Revolución sobre el Liderazgo.'
                    },
                ]
            },
            {
                type: 'button',
                props: {
                    styles: {
                        position: 'relative',
                        marginTop: '80px',
                    },
                },
                children: 'Ver video',
            },
            {
                type: 'button',
                props: {
                    size: 'small',
                    styles: {
                        position: 'relative',
                        marginTop: '26px',
                    },
                },
                children: 'Continuar',
                action: 'next',
            }
        ]
    },
    {
        type: 'step',
        props: {
            order: 8,
        },
        bricks: [
            {
                type: 'paragraph',
                props: {
                    size: 's',
                    align: 'center',
                },
                children: `Confirmación Canal de Youtube`
            },
            {
                type: 'image',
                props: {
                    src: YouTube,
                    alt: 'YouTube Logo',
                }
            },
            {
                type: 'paragraph',
                props: {
                    size: 's',
                    align: 'center',
                    style: {
                        marginTop: '20px',
                    }
                },
                children: `¡Nuevo(a) Director(a)!
                <br /><br />
                En este momento los dos Nuevos Anfitriones se deben unir al canal de YouTube de ACDC con el obsequio 🎁 que recibirán por parte de los Nuevos(as) Coordinadores(as). 
                <br /><br />
                Confirma que se dieron los obsequios en la siguiente pantalla usando como evidencia los pantallazos que te enviarán los Nuevos(a) Coordinadores(as).`
            },
            {
                type: 'button',
                props: {
                    style: 'ghost',
                    size: 'small',
                    styles: {
                        position: 'relative',
                        marginTop: '80px',
                    },
                },
                children: 'Manual de compra',
            },
            {
                type: 'button',
                props: {
                    styles: {
                        position: 'relative',
                        marginTop: '26px',
                    },
                },
                children: 'Continuar',
                action: 'next',
            }
        ]
    },
    {
        type: 'step',
        props: {
            order: 9,
        },
        bricks: [
            {
                type: 'giftSwitch',
                props: {
                    name: 'Juan Perez',
                    role: 'coordinator',
                    nameRole: 'Dio los obsequios',
                    range: 7,
                    orientation: 'row',
                    size: 'x-small',
                    checkbox: true,
                    actives: [true, false]
                }
            },
            {
                type: 'giftSwitch',
                props: {
                    name: 'Juan Perez',
                    role: 'host',
                    nameRole: 'Nuevo(a) Anfitrión',
                    range: 7,
                    orientation: 'row',
                    size: 'x-small',
                    checkbox: true,
                }
            },
            {
                type: 'giftSwitch',
                props: {
                    name: 'Juan Perez',
                    role: 'host',
                    nameRole: 'Nuevo(a) Anfitrión',
                    range: 7,
                    orientation: 'row',
                    size: 'x-small',
                    checkbox: true,
                }
            },
            {
                type: 'giftSwitch',
                props: {
                    name: 'Juan Perez',
                    role: 'coordinator',
                    nameRole: 'Dio los obsequios',
                    range: 7,
                    orientation: 'row',
                    size: 'x-small',
                    checkbox: true,
                    actives: [true, false],
                    style: {
                        marginTop: '40px',
                    }
                }
            },
            {
                type: 'giftSwitch',
                props: {
                    name: 'Juan Perez',
                    role: 'host',
                    nameRole: 'Nuevo(a) Anfitrión',
                    range: 7,
                    orientation: 'row',
                    size: 'x-small',
                    checkbox: true,
                }
            },
            {
                type: 'giftSwitch',
                props: {
                    name: 'Juan Perez',
                    role: 'host',
                    nameRole: 'Nuevo(a) Anfitrión',
                    range: 7,
                    orientation: 'row',
                    size: 'x-small',
                    checkbox: true,
                }
            },
            {
                type: 'button',
                props: {
                    styles: {
                        position: 'relative',
                        marginTop: '80px',
                    },
                },
                children: 'Continuar EPA',
                action: 'next',
            }
        ]
    },
    {
        type: 'step',
        props: {
            order: 10,
        },
        bricks: [
            {
                type: 'paragraph',
                props: {
                    size: 's',
                    align: 'center',
                },
                children: `¡Momento de los agradecimientos!`
            },
            {
                type: 'image',
                props: {
                    src: CoordinatorTools,
                    alt: 'Coordinator Tools',
                    props: {
                        width: '100px',
                        height: '112px',
                    }
                }
            },
            {
                type: 'paragraph',
                props: {
                    size: 's',
                    align: 'center',
                },
                children: `💡 Nuevo(a) Director(a), es momento de que los Nuevos Anfitriones comiencen a agrecer a sus Validadores. 
<br /><br />
Mañana en el Día de Inducción, iniciarán la Convocatoria. Requerirán el link de registro al EPA. 
<br /><br />
A continuación vas a generar el link de la convocatoria que usarán para aplicar el ABC.
Recuerda que los Anfitriones podrán acceder al enlace que generaste desde su perfil en la Plataforma ACDC.`
            },
            {
                type: 'button',
                props: {
                    role: 'director',
                    styles: {
                        position: 'relative',
                        marginTop: '80px',
                    },
                },
                children: 'Generar Links',
                action: 'next',
            },
        ]
    },
    {
        type: 'step',
        props: {
            order: 11,
        },
        bricks: [
            {
                type: 'previewEpa',
                props: {
                    user: {
                        name: 'Juan Perez',
                        role: 'coordinator',
                        nameRole: 'Nuevo(a) Coordinador(a)',
                        range: 7,
                        date: '10 de Nov. del 2024',
                        time: '8:00 AM',
                        items: [
                            {
                                name: 'Juan Perez',
                                role: 'host',
                                nameRole: 'Nuevo(a) Anfitrión',
                                range: 7,
                            }
                        ]
                    }
                }
            },
            {
                type: 'button',
                props: {
                    styles: {
                        position: 'relative',
                        marginTop: '80px',
                    },
                },
                children: 'Siguiente',
                action: 'nextFinishSimulate',
            }
        ]
    },
    {
        type: 'step',
        props: {
            order: 12,
        },
        bricks: [
            {
                type: 'paragraph',
                props: {
                    size: 's',
                    align: 'center',
                },
                children: `<b>Fin de la Zona de Aprendizaje del EPA.</b>`,
            },
            {
                type: 'image',
                props: {
                    src: CheckHandCoordinator,
                    alt: 'Check Hand',
                    style: {
                        marginTop: '10px'
                    }
                }
            },
            {
                type: 'paragraph',
                props: {
                    size: 's',
                    align: 'center',
                },
                children: `¡Excelente!
                <br /><br />
                <strong style="color: #00FF29;">Ya estas preparado(a) para asumir tus responsabilidades como Nuevo(a) Director(a). Recuerda cuidadosamente lo que has aprendido y aplícalo mientras enfrentas los desafíos que tendrás en el EPA. </strong>`,
            },
            {
                type: 'button',
                props: {
                    styles: {
                        position: 'relative',
                        marginTop: '80px',
                    },
                },
                children: 'Continuar',
                action: 'next',
            }
        ]
    },
    {
        type: 'step',
        props: {
            order: 13,
        },
        bricks: [
            {
                type: 'paragraph',
                props: {
                    size: 's',
                    align: 'center',
                    style: {
                        marginTop: '40px',
                        marginBottom: '80px',
                    }
                },
                children: `Hoy cuando completes tu Meta 2 al finalizar el Alineamiento y estén confirmados los asistentes  del EPA, deberás realizar la compra de productos que te habilitan como líder en ACDC.
<br /><br />
Vas a comprar tu paquete de inicio, este paquete te permitirá activar un activo financiero que comercializa productos que tienen disponibilidad en más de 80 países.`
            },
            {
                type: 'cardImage',
                props: {
                    image: WarningIcon,
                    type: 'quaternary',
                    width: '106px',
                    height: '106px',
                },
                bricks: [
                    {
                        type: 'paragraph',
                        props: {
                            size: 's',
                            align: 'center',
                            style: {
                                marginTop: '40px',
                            }
                        },
                        children: `Coordinador(a), recuerda que hoy debes tener listo el dinero que requieres para realizar tu compra del paquete de inicio.`
                    }
                ]
            },
            {
                type: 'button',
                props: {
                    styles: {
                        position: 'relative',
                        marginTop: '80px',
                    },
                },
                children: 'Continuar',
                action: 'next',
            }
        ]
    },
    {
        type: 'step',
        props: {
            order: 14,
        },
        bricks: [
            {
                type: "checkbox",
                props: {
                    label: 'PSE.',
                    name: "payment-method",
                    labelBackground: true,
                    style: {
                        width: '100%',
                        maxWidth: '267px',
                        marginTop: "12px",
                        padding: '0 40px',
                    },
                },
            },
            {
                type: "checkbox",
                props: {
                    label: 'Tarjeta débito.',
                    name: "payment-method",
                    labelBackground: true,
                    style: {
                        width: '100%',
                        maxWidth: '267px',
                        marginTop: "12px",
                        padding: '0 40px',
                    },
                },
            },
            {
                type: "checkbox",
                props: {
                    label: 'Tarjeta crédito.',
                    name: "payment-method",
                    labelBackground: true,
                    style: {
                        width: '100%',
                        maxWidth: '267px',
                        marginTop: "12px",
                        padding: '0 40px',
                    },
                },
            },
            {
                type: "checkbox",
                props: {
                    label: 'Efectivo (Consignación bancaria).',
                    name: "payment-method",
                    labelBackground: true,
                    style: {
                        width: '100%',
                        maxWidth: '267px',
                        marginTop: "12px",
                        padding: '0 40px',
                    },
                },
            },
            {
                type: 'button',
                props: {
                    styles: {
                        position: 'relative',
                        marginTop: '174px',
                    },
                },
                children: 'Aceptar',
                action: 'next',
            }
        ]
    },
    {
        type: 'step',
        props: {
            order: 15,
        },
        bricks: [
            {
                type: 'paragraph',
                props: {
                    size: 's',
                    align: 'center',
                },
                children: `¡Coordinador(a)! 
                <br /><br />
¿Cuándo y a qué hora vas a realizar la compra de tu paquete de inicio?¿Cuánto vas a pagar?`
            },
            {
                type: 'buyDate',
            },
            {
                type: 'button',
                props: {
                    styles: {
                        position: 'relative',
                        marginTop: '80px',
                    },
                },
                children: 'Continuar',
                action: 'next',
            }
        ]
    },
    {
        type: 'step',
        props: {
            order: 16,
        },
        bricks: [
            {
                type: 'paragraph',
                props: {
                    size: 's',
                    align: 'center',
                },
                children: '¡Genial!<br/><br/>Has completado el <b>Desafío B.</b>'
            },
            {
                type: 'image',
                props: {
                    src: ClapHandCoordinator,
                    alt: 'clap-hand-coordinator',
                    style: {
                        marginTop: '10px'
                    }
                }
            },
            {
                type: 'cardCopyKey',
                props: {
                    phrase: 'Sync Alineamiento Coordinador',
                    nameChallenge: 'Desafío B',
                }
            },
            {
                type: 'button',
                props: {
                    styles: {
                        position: 'relative',
                        marginTop: '96px',
                    },
                },
                children: 'Finalizar',
                action: 'finish',
            }
        ]
    }
];