import MenuUserInfo from "../menu-user-info";
import { ReactComponent as StarRating } from "../../../resources/images/star-rating.svg";
import { ReactComponent as H1 } from '../../../resources/images/h1.svg';
import { ReactComponent as H2 } from '../../../resources/images/h2.svg';
import { ReactComponent as H3 } from '../../../resources/images/h3.svg';
import { useContext, useEffect } from "react";
import { UserContext } from "../../../middleware/providers/user-context";
import Paragraph from "../../atoms/paragraph";
import { PROCESS_STATUS } from "../../../middleware/utils/constants";

const ValidatorItem = ({ 
    index,
    newIndex, 
    name, 
    role, 
    otherRole,
    nameRole, 
    range, 
    onSelect, 
    epaParticipationRequirements, 
    personalReferences, 
    keys, 
    challenge,
    idUser,
    token,
    disabled,
    status,
}) => {

    const { user } = useContext(UserContext);
    const countValidations = Object.keys(personalReferences || {}).filter((key) => personalReferences[key]?.status && personalReferences[key]?.role !== 'host').length;

    useEffect(() => {
        document.getElementById(`star-rating-${index}`)?.querySelectorAll("[id^='Star']").forEach((star, indexKey) => {
            const sortedPersonalReferences = personalReferences?.filter((personalReferences, index) => index > 0 && personalReferences.status !== undefined).sort((a, b) => b?.status - a?.status);
            console.log('sortedPersonalReferences', name, sortedPersonalReferences);
            star?.setAttribute('opacity', sortedPersonalReferences?.[indexKey]?.status ? '1' : '0.3');
        });
    }, []);

    const renderExtraContent = () => {
        if (user?.role === 'host' && !['induction day', 'rules day'].find((day) => challenge?.day === day)) {
            return (
                <div className="extra-content">
                    <H1 className="h1" opacity={keys?.[0]?.status ? 1 : 0.3} />
                    <H2 className="h2" opacity={keys?.[1]?.status ? 1 : 0.3} />
                    <H3 className="h3" opacity={keys?.[2]?.status ? 1 : 0.3} />
                </div>
            );
        }
    }

    return (
        <div className={`validator-item ${disabled ? 'disabled' : ''}`} onClick={() => onSelect(newIndex || index, idUser, token)} onKeyDown={() => { }}>
            <div className={`validator-item__border ${otherRole || 'candidate'}`} style={{ opacity: status ? 1 : 0.5 }}></div>
            <div className={`validator-item__content ${otherRole || 'candidate'}`}>
                <span className="index">{index}</span>
                <MenuUserInfo
                    name={name}
                    role={otherRole}
                    nameRole={nameRole}
                    range={range}
                    extraContent={renderExtraContent()}
                    headingAlign="left"
                    icon={user?.role === 'host' && nameRole === 'Validador(a)' && <span className="icon-recognition"></span>}
                    isNoVisble={index > 0 && nameRole === 'Validador(a)'}
                >
                    {user?.role === 'host' && !['induction day', 'rules day'].find((day) => challenge?.day === day)  && <>
                        <span className="title">Validaciones: {countValidations}/6 </span>
                        <StarRating id={`star-rating-${index}`} />
                    </>}
                    {user?.role === 'host' && PROCESS_STATUS[user?.personalReferences?.[index]?.processStatus] &&
                        <span className="title">
                            <b>Estado:</b> {PROCESS_STATUS[user?.personalReferences?.[index]?.processStatus]}
                        </span>}
                    {index > 5 && <Paragraph size="xs" align="left" >Nueva referencia</Paragraph>}
                    {user?.role === 'candidate' && (role === 'candidate' || !role) && <span className="icon-recognition"></span>}
                </MenuUserInfo>
            </div>
            <span className={`icon-chevron-back icon right ${otherRole || 'candidate'}`}></span>
        </div>
    );
};

export default ValidatorItem;