import SyncCoordinator from "../../resources/images/sync-coordinator.png";
import ClapHandCoordinator from "../../resources/images/clap-hand-coordinator.png";
import WhatsApp from "../../resources/images/whatsapp.png";
import WhattsAppGroupCoordinator from "../../resources/images/whatsapp-group-coordinator.png";
import ShopBag from "../../resources/images/shop-bag.png";

export const getBricksChallengeC = () => [
    {
        type: 'step',
        props: {
            order: 1,
        },
        bricks: [
            {
                type: 'paragraph',
                props: {
                    size: 's',
                    align: 'center',
                    style: {
                        marginBottom: '52px',
                    }
                },
                children: `A continuación, encontrarás la hora y el link de la Meet a la que deberás conectarte para realizar una sincronización de 10 minutos con el resto del equipo.`
            },
            {
                type: 'cardImage',
                props: {
                    image: SyncCoordinator,
                    type: 'quaternary',
                    width: '106px',
                    height: '106px',
                    gap: '0',
                },
                bricks: [
                    {
                        type: 'paragraph',
                        props: {
                            size: 's',
                            align: 'center',
                            style: {
                                marginTop: '40px',
                                marginBottom: '0px',
                            }
                        },
                        children: `<b>Hora: 9:30 pm</b>`
                    },
                    {
                        type: 'paragraph',
                        props: {
                            size: 's',
                            align: 'center',
                            style: {
                                marginTop: '0px',
                                marginBottom: '0px',
                            }
                        },
                        children: `Link de la Meet:`
                    },
                    {
                        type: 'paragraph',
                        props: {
                            size: 's',
                            align: 'center',
                            style: {
                                width: '303px',
                                marginTop: '0px',
                                backgroundColor: '#0D233B',
                                padding: '9px 0',
                                borderRadius: '20px',
                                alignSelf: 'center',
                            }
                        },
                        children: `meet.google.com/abc-123-def`
                    },
                    {
                        type: 'copyField',
                        props: {
                            value: 'meet.google.com/abc-123-def',
                            style: {
                                width: '150px',
                                marginTop: '0px',
                                backgroundColor: '#0D233B',
                                borderRadius: '20px',
                                alignSelf: 'center',
                            }
                        }

                    }
                ]
            },
            {
                type: 'paragraph',
                props: {
                    size: 'xs',
                    align: 'center',
                    style: {
                        marginTop: '40px',
                    }
                },
                children: `Este botón se habilitará en medio de la reunión para que puedas realizar el resto del desafío.`
            },
            {
                type: 'button',
                props: {
                    role: 'coordinator',
                    styles: {
                        position: 'relative',
                        marginTop: '40px',
                    }
                },
                children: 'Continuar',
                action: 'next',
            }
        ]
    },
    {
        type: 'step',
        props: {
            order: 2,
        },
        bricks: [
            {
                type: 'paragraph',
                props: {
                    size: 's',
                    align: 'center',
                },
                children: `Requisitos de participación en un EPA.
                <br /><br />
                Estos son los primeros tres requisitos que se encuentran confirmando los(as) Aspirantes en este momento:`,
            },
            {
                type: 'paragraph',
                props: {
                    size: 's',
                    align: 'justify',
                    withBackground: true,
                    style: {
                        width: '313px',
                        display: 'flex',
                        alignItems: 'flex-start',
                    }
                },
                children: `<span style="text-indent: 0; display: inline-block;">1.</span><span style="display: inline-block;">Disposición de 2 Horas para participar enfocado(a) en el EPA y sin interrupciones.</span>`,
            },
            {
                type: 'paragraph',
                props: {
                    size: 's',
                    align: 'justify',
                    withBackground: true,
                    style: {
                        width: '313px',
                        display: 'flex',
                        alignItems: 'flex-start',
                    }
                },
                children: `<span style="text-indent: 0; display: inline-block;">2.</span> <span style="display: inline-block;">Tener computador y celular cargados, audífonos y buena conexión a internet.</span>`
            },
            {
                type: 'paragraph',
                props: {
                    size: 's',
                    align: 'justify',
                    withBackground: true,
                    style: {
                        width: '313px',
                        display: 'flex',
                        alignItems: 'flex-start',
                    }
                },
                children: `<span style="text-indent: 0; display: inline-block;">3.</span><span style="display: inline-block;">Aceptar el Acuerdo de Confidencialidad.</span>`
            },
            {
                type: 'button',
                props: {
                    styles: {
                        position: 'relative',
                        marginTop: '154px',
                    }
                },
                children: 'Continuar',
                action: 'next',
            }

        ]
    },
    {
        type: 'step',
        props: {
            order: 3,
        },
        bricks: [
            {
                type: 'paragraph',
                props: {
                    size: 's',
                    align: 'center',
                },
                children: `Visualiza cómo van los(as) Aspirantes confirmando los últimos DOS requisitos:`
            },
            {
                type: 'completeRequirements',
                props: {
                    user: {
                        name: 'Juan Perez',
                        role: 'host',
                        nameRole: 'Anfitrión(a)',
                        range: 7,
                        items: [
                            {
                                name: 'Juan Perez',
                                role: 'candidate',
                                nameRole: 'Aspirante',
                                range: 7,
                                requirements: [true, false, false, false, false]
                            }
                        ]
                    }
                }
            },
            {
                type: 'button',
                props: {
                    role: 'coordinator',
                    styles: {
                        position: 'relative',
                        marginTop: '154px',
                    }
                },
                children: 'Continuar',
                action: 'next',
            }
        ],
    },
    {
        type: 'step',
        props: {
            order: 4,
        },
        bricks: [
            {
                type: 'paragraph',
                props: {
                    size: 's',
                    align: 'center',
                },
                children: `¡Excelente!
<br /><br />
Estos son los preseleccionados
de cada Anfitrión:`
            },
            {
                type: 'card',
                props: {
                    className: 'user-card-info user-info-date',
                    type: 'quaternary',
                    style: {
                        marginTop: '20px',
                    }
                },
                bricks: [
                    {
                        type: 'menuUserInfo',
                        props: {
                            name: 'Juan Perez',
                            role: 'host',
                            nameRole: 'Anfitrión(a)',
                            range: 7,
                            orientation: 'row',
                            size: 'x-small',
                        }
                    }
                ]
            },
            {
                type: 'card',
                props: {
                    className: 'user-card-info user-info-date',
                    type: 'secondary',
                    style: {
                        marginTop: '20px',
                    }
                },
                bricks: [
                    {
                        type: 'menuUserInfo',
                        props: {
                            name: 'Juan Perez',
                            role: 'candidate',
                            nameRole: 'Candidate',
                            range: 7,
                            orientation: 'row',
                            size: 'x-small',
                        }
                    }
                ]
            },
            {
                type: 'card',
                props: {
                    className: 'user-card-info user-info-date',
                    type: 'secondary',
                    style: {
                        marginTop: '20px',
                    }
                },
                bricks: [
                    {
                        type: 'menuUserInfo',
                        props: {
                            name: 'Juan Perez',
                            role: 'candidate',
                            nameRole: 'Candidate',
                            range: 7,
                            orientation: 'row',
                            size: 'x-small',
                        }
                    }
                ]
            },
            {
                type: 'card',
                props: {
                    className: 'user-card-info user-info-date',
                    type: 'quaternary',
                    style: {
                        marginTop: '50px',
                    }
                },
                bricks: [
                    {
                        type: 'menuUserInfo',
                        props: {
                            name: 'Juan Perez',
                            role: 'host',
                            nameRole: 'Anfitrión(a)',
                            range: 7,
                            orientation: 'row',
                            size: 'x-small',
                        }
                    }
                ]
            },
            {
                type: 'card',
                props: {
                    className: 'user-card-info user-info-date',
                    type: 'secondary',
                    style: {
                        marginTop: '20px',
                    }
                },
                bricks: [
                    {
                        type: 'menuUserInfo',
                        props: {
                            name: 'Juan Perez',
                            role: 'candidate',
                            nameRole: 'Candidate',
                            range: 7,
                            orientation: 'row',
                            size: 'x-small',
                        }
                    }
                ]
            },
            {
                type: 'card',
                props: {
                    className: 'user-card-info user-info-date',
                    type: 'secondary',
                    style: {
                        marginTop: '20px',
                    }
                },
                bricks: [
                    {
                        type: 'menuUserInfo',
                        props: {
                            name: 'Juan Perez',
                            role: 'candidate',
                            nameRole: 'Candidate',
                            range: 7,
                            orientation: 'row',
                            size: 'x-small',
                        }
                    }
                ]
            },
            {
                type: 'button',
                props: {
                    role: 'coordinator',
                    styles: {
                        position: 'relative',
                        marginTop: '154px',
                    }
                },
                children: 'Continuar',
                action: 'next',
            }
        ]
    },
    {
        type: 'step',
        props: {
            order: 5,
        },
        bricks: [
            {
                type: 'image',
                props: {
                    src: WhatsApp,
                    alt: 'WhatsApp',
                    width: '32px',
                    height: '32px',
                    style: {
                        marginTop: '40px',
                    }
                }
            },
            {
                type: 'paragraph',
                props: {
                    size: 's',
                    align: 'center',
                },
                children: `En este momento tus Anfitriones deben agregar al grupo del EPA a sus Aspirantes preseleccionados.
                <br /><br />
                <ul>
                    <li>En caso de que estos sean de tu Lista de Espera, los debes agregar tú.</li>
                    <li style="margin-top: 26px;">En caso de que estos sean de la Lista de Espera de tu Director(a), los debe agregar el Director(a).</li>
                </ul>`
            },
            {
                type: 'button',
                props: {
                    styles: {
                        position: 'relative',
                        marginTop: '104px',
                    }
                },
                children: 'Continuar',
                action: 'next',
            }
        ]
    },
    {
        type: 'step',
        props: {
            order: 6,
        },
        bricks: [
            {
                type: 'image',
                props: {
                    src: WhattsAppGroupCoordinator,
                    alt: 'WhatsApp',
                    width: '32px',
                    height: '32px',
                    style: {
                        marginTop: '40px',
                        marginBottom: '40px',
                    }
                }
            },
            {
                type: 'paragraph',
                props: {
                    size: 's',
                    align: 'center',
                },
                children: `En este momento debes crear dos nuevos grupos de WhatsApp.
                <br /><br />
                Agrega a un(una) Anfitrión(a) en cada grupo, en estos grupos tú serás el(la) Nuevo(a) Director(a), tus Anfitriones serán tus Nuevos Coordinadores, y al final del EPA cada uno de ellos agregará sus  dos Nuevos Anfitriones.`
            },
            {
                type: 'button',
                props: {
                    styles: {
                        position: 'relative',
                        marginTop: '104px',
                    }
                },
                children: 'Continuar',
                action: 'next',
            }
        ]
    },
    {
        type: 'step',
        props: {
            order: 7,
        },
        bricks: [
            {
                type: 'image',
                props: {
                    src: ShopBag,
                    alt: 'Shop Bag',
                    width: '32px',
                    height: '32px',
                    style: {
                        marginTop: '10px',
                    }
                }
            },
            {
                type: 'paragraph',
                props: {
                    size: 's',
                    align: 'center',
                },
                children: `En este momento debes sincronizarte con tu Director(a) para efectuar la compra de tu paquete de inicio, podrás continuar cuando tu Director(a) confirme que efectuaste el pago.`
            },
            {
                type: 'paragraph',
                props: {
                    size: 's',
                    align: 'center',
                    style: {
                        border: '1px solid #0063DD',
                        borderRadius: '8px',
                        paddingTop: '13px',
                        paddingBottom: '29px',
                    }
                },
                children: `Manual de paso a paso para la compra del Paquete de Inicio.`,
            },
            {
                type: 'button',
                props: {
                    size: 'small',
                    styles: {
                        position: 'relative',
                        marginTop: '8px',
                    }
                },
                children: 'Ver manual',
            },
            {
                type: 'button',
                props: {
                    role: 'coordinator',
                    styles: {
                        position: 'relative',
                        marginTop: '40px',
                    }
                },
                children: 'Continuar',
                action: 'next',
            },
            {
                type: 'button',
                props: {
                    style: 'abort',
                    styles: {
                        position: 'relative',
                        marginTop: '30px',
                    }
                },
                children: 'Game Over',
            },
        ]
    },
    {
        type: 'step',
        props: {
            order: 8,
        },
        bricks: [
            {
                type: 'paragraph',
                props: {
                    size: 's',
                    align: 'center',
                },
                children: '¡Genial!<br/><br/>Has completado el <b>Desafío C.</b>'
            },
            {
                type: 'image',
                props: {
                    src: ClapHandCoordinator,
                    alt: 'clap-hand-coordinator',
                    style: {
                        marginTop: '10px'
                    }
                }
            },
            {
                type: 'cardCopyKey',
                props: {
                    phrase: 'Cierre Alineamiento Coordinador',
                    nameChallenge: 'Desafío C',
                }
            },
            {
                type: 'button',
                props: {
                    styles: {
                        position: 'relative',
                        marginTop: '96px',
                    },
                },
                children: 'Finalizar',
                action: 'finish',
            }
        ]
    }
];