export const getBricksChallengeB = () => [
    {
        type: 'step',
        props: {
            order: 1,
        },
        bricks: [
            {
                type: 'paragraph',
                props: {
                    size: 's',
                    align: 'center',
                },
                children: `<b>Visualización de entrenamientos ABC Filtro</b>
                <br />
                <br />
                Te encuentras en tu Desafío B. Tus dos Anfitriones están realizando el Entrenamiento ABC Filtro, con sus Aspirantes.
<br />
<br />
No te permitas perder tu enfoque 🎯 en el Liderazgo.
<ul  style="text-align: left; padding-left: 26px;">
<li>Mantente atento(a) a cómo puedes apoyar a tus Anfitriones.</li>
<li>Revisa constantemente la cantidad de Aspirantes que ya terminaron el Entrenamiento. Este resultado es clave para que tus Anfitriones logren su Meta 2.</li>
</ul>`
            },
            {
                type: 'paragraph',
                props: {
                    size: 's',
                    align: 'center',
                    withBackground: true,
                    style: {
                        padding: '20px 16px 22px'
                    }
                },
                children: `¡Podrás iniciar tu <strong>Desafío C</strong>, cuando al menos uno de tus Anfitriones haya realizado el <strong>Entrenamiento ABC Filtro</strong>, con al menos <strong>3 de sus Aspirantes</strong>!
¡Apoya a tus Anfitriones a lograr el objetivo!`
            },
            {
                type: 'button',
                props: {
                    styles: {
                        position: 'relative',
                        marginTop: '120px',
                    },
                },
                children: 'Continuar',
                action: 'next',
            }
        ]
    },
    {
        type: 'step',
        props: {
            order: 2,
        },
        bricks: 'coordinatorFilterDayChallengeB'
    }
];