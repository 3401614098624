import ClapHandDirector from '../../resources/images/clap-hand-director.png';
import CoordinatorTools from '../../resources/images/coordinator-tools.png';
import CalendarIcon from '../../resources/images/calendar-icon.png';

export const getBricksChallengeB = () => [
    {
        type: 'step',
        props: {
            order: 1
        },
        bricks: [
            {
                type: 'image',
                props: {
                    src: CoordinatorTools,
                    alt: 'Empty search',
                    style: {
                        marginTop: '20px'
                    }
                }
            },
            {
                type: 'paragraph',
                props: {
                    size: 's',
                    align: 'center',
                },
                children: `Este Desafío se completa una vez tus Coordinadores hayan hecho el entrenamiento del ABC Convocatoria simulando ser un “Aspirante 0” de sus respectivos Anfitriones en la Plataforma. 
<br /><br />
En ese momento habrán terminado el Entrenamiento ABC Convocatoria de los Anfitriones y estos ya estarán listos para empezar a convocar a sus Validadores.`,
            },
            {
                type: 'button',
                props: {
                    styles: {
                        position: 'relative',
                        marginTop: '80px',
                    },
                },
                children: 'Continuar',
                action: 'next',
            }
        ],
    },
    {
        type: 'step',
        props: {
            order: 2
        },
        bricks: [
            {
                type: 'giftSwitch',
                props: {
                    role: 'coordinator',
                    nameRole: 'Coordinador(a)',
                    name: 'Karime Castañeda',
                    range: 7,
                    items: [
                        {
                            image: CalendarIcon,
                            wait: true,
                            width: '24px',
                        },
                    ],
                    style: {
                        marginTop: '30px'
                    }
                }
            },
            {
                type: 'paragraph',
                props: {
                    size: 's',
                    align: 'center',
                    style: {
                        marginTop: '47px'
                    }
                },
                children: `Tu Coordinador(a) aún no se encuentra agendado(a) como parte del Entrenamiento ABC Convocatoria de sus Anfitriones.`
            },
            {
                type: 'paragraph',
                props: {
                    size: 'xs',
                    align: 'center',
                    style: {
                        marginTop: '52px'
                    }
                },
                children: `Podrás continuar una vez el agendamiento se haya realizado.`
            },
            {
                type: 'button',
                props: {
                    styles: {
                        position: 'relative',
                        marginTop: '40px',
                    },
                },
                children: 'Continuar',
                action: 'next',
            }
        ]
    },
    {
        type: 'step',
        props: {
            order: 3,
        },
        bricks: [
            {
                type: 'paragraph',
                props: {
                    size: 's',
                    align: 'center',
                },
                children: '¡Genial!<br/><br/>Has completado el <b>Desafío B.</b>'
            },
            {
                type: 'image',
                props: {
                    src: ClapHandDirector,
                    alt: 'clap-hand-coordinator',
                    style: {
                        marginTop: '10px'
                    }
                }
            },
            {
                type: 'paragraph',
                props: {
                    size: 's',
                    align: 'center',
                    type: 'secondary',
                    withBackground: true,
                },
                children: 'Ya puedes continuar con el <b>Desafío C</b> del Día de Inducción.<br/><br/>👋🏻 Ya nos vemos.'
            },
            {
                type: 'button',
                props: {
                    styles: {
                        position: 'relative',
                        marginTop: '96px',
                    },
                },
                children: 'Ver video',
                action: 'finish',
            }
        ]
    }
];