import classNames from "classnames";
import Card from "../../atoms/card";

export const CardImage = ({ children, type, image, width, height, gap }) => {
    const classes = classNames('card-image', {
        [`card-image--gap--${gap}`]: gap,
    });

    console.log('children', gap);

    return <Card className={classes} type={type}>
        <img className="card-image-img" src={image} alt="" width={width} height={height}/>
        {children}
    </Card>
};