import Button from "../../atoms/button";
import Card from "../../atoms/card";
import Checkbox from "../../atoms/checkbox";
import MenuUserInfo from "../../molecules/menu-user-info";
import { Grid } from "../../templates/grid";

export const PreviewEpa = ({ user }) => {
    return (
        <div className="preview-epa">
            <Card className={`user-card-info user-info-date ${user?.role}`} type="tertiary">
                <MenuUserInfo name={user?.name} role={user?.role} nameRole={user?.nameRole} range={user?.range} orientation="row" size="x-small" />
                <span className="icon-chevron-back"></span>
            </Card>
            <Card className={`user-card-info user-info-date ${user?.role}`} type="tertiary">
                <Grid className="user-info-date-grid" columns={2}>
                    <span className="user-info-date-grid__title">EPA:</span>
                    <span className="user-info-date-grid__date">{user?.date}</span>
                    <span className="user-info-date-grid__time">{user?.time}</span>
                </Grid>
            </Card>
            {user?.items?.map((item) => <Card className={`user-card-info user-info-date ${item?.role}`} type="tertiary">
                <MenuUserInfo name={item?.name} role={item?.role} nameRole={item?.nameRole} range={item?.range} orientation="row" size="x-small" />
                <Grid className="preview-epa-training" columns={2}>
                    <span className="preview-epa-training__item">Entrenamiento completado</span>
                    <Checkbox />
                    <span className="preview-epa-training__item">Agradecimientos Realizados</span>
                    <span className="preview-epa-training__item__small">2</span>
                </Grid>
                <Button className="preview-epa-training__button" size="small">Ver validadores</Button>
            </Card>)}
        </div>
    );
};