import { useContext } from "react";
import { ReactComponent as TimeManagementMedal } from "../../../resources/images/time-management-medal.svg";
import { UserContext } from "../../../middleware/providers/user-context";
import Paragraph from "../../atoms/paragraph";
import MedalSwitch from "../../molecules/medal-switch";
import { ROLES } from "../../../middleware/utils/constants";

export const AssignationMedalList = ({ users, medal }) => {
    const { user } = useContext(UserContext);

    return (<div className="assignation-meda-list">
        <div className="assignation-meda-list__info">
            <TimeManagementMedal className={user?.role} width="33.75" height="29.9" />
            <Paragraph size="s" align="center">
                Presiona SÍ, en el participante que haya ganado medalla de Manejo del Tiempo
                y No, en el caso contrario.
            </Paragraph>
        </div>
        {users?.map((candidate) => <MedalSwitch
            roleName={ROLES[candidate.role]?.roleName}
            name={candidate.name}
            circle={ROLES[candidate.role]?.circle}
            disabled={candidate.disabled}
            onClick={(value) => {
            }} />)}
    </div>);
};