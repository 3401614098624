import Modal from "../../atoms/modal";
import { Grid } from "../../templates/grid";

export const PackagesInfoModal = ({ isOpen, onClose, items }) => {

    const handleKeyDown = (e) => {
        if (e.key === 'Escape') {
            onClose();
        }
    }

    return (
        <Modal className="packages-info-modal" isOpen={isOpen} skipStarsBackground>
            <span className="packages-info-modal-title">PAQUETES DE ESP</span>
            <span className="close icon-close" onClick={onClose} onKeyDown={handleKeyDown}></span>
            <Grid className="packages-info-modal-grid headings" columns={4}>
                <span className="packages-info-modal-grid-description headings">Descripción</span>
                <span className="packages-info-modal-grid-quantity headings">ESP-1</span>
                <span className="packages-info-modal-grid-quantity headings">ESP-2</span>
                <span className="packages-info-modal-grid-quantity headings">ESP-3</span>
            </Grid>
            <Grid className="packages-info-modal-grid" columns={4}>
                {items.map((item) => (
                    <>
                        <span className="packages-info-modal-grid-description item">{item.description}</span>
                        <span className="packages-info-modal-grid-quantity item">{item.ESP1}</span>
                        <span className="packages-info-modal-grid-quantity item">{item.ESP2}</span>
                        <span className="packages-info-modal-grid-quantity item">{item.ESP3}</span>
                    </>
                ))}
            </Grid>
        </Modal>
    )
};