import {ReactComponent as AvatarMenu} from '../../../resources/images/circular-progress.svg';

const MenuUserInfo = ({ name, role, nameRole, range, orientation = 'column', children, extraContent, icon, className = '', style, headingAlign = 'center', size, isNoVisble}) => {

    const classIsNoVisible = isNoVisble ? 'no-visible' : '';

    return (
        <div className={`menu-user-info menu-user-info--${role} ${orientation} ${className}`} style={style}>
            <div className={`menu-user-info-avatar progress-${range}-8 ${size} ${classIsNoVisible}`}>
                <AvatarMenu />
            </div>
            <div className="menu-user-info-title">
                <h4 className={`heading ${headingAlign}`}>{name}</h4>
                <span>{nameRole}{icon}</span>
                {children}
            </div>
            {extraContent}
        </div>
    );
};

export default MenuUserInfo;