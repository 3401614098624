import Button from "../../atoms/button";
import Card from "../../atoms/card";
import { Grid } from "../../templates/grid";
import MenuUserInfo from "../menu-user-info";

export const UserInfoDate = ({ user, date, time, callback }) => {
    return (
        <Card className={`user-card-info user-info-date ${user?.role}`} type="quinary">
            <MenuUserInfo
                name={user?.name}
                role={user?.role}
                nameRole={user?.nameRole}
                range={user?.range}
                orientation="row"
                size="small"
            >
            </MenuUserInfo>
            <Grid className="user-info-date-grid" columns={2}>
                <span className="user-info-date-grid__date">{date}</span>
                <span className="user-info-date-grid__time">{time}</span>
                <Button className="user-info-date-grid__edit" onClick={callback}>Editar</Button>
                <Button className="user-info-date-grid__accept" onClick={callback}>Aceptar</Button>
            </Grid>
        </Card>
    );
};