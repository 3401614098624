import GoodHandCoordinator from '../../resources/images/good-hand-coordinator.png';

export const getBricksChallengeC = () => [
    {
        type: 'step',
        props: {
            order: 1,
        },
        bricks: [
            {
                type: 'paragraph',
                props: {
                    size: 's',
                    align: 'center',
                    withBackground: true,
                    style: {
                        width: '100%',
                        maxWidth: '343px',
                        paddingTop: '7px',
                        paddingBottom: '7px',
                    }
                },
                children: 'Top 3 (Validaciones)',
            },
            {
                type: 'grid',
                props: {
                    columns: 2,
                },
                bricks: [
                    {
                        type: 'paragraph',
                        props: {
                            size: 's',
                            align: 'center',
                            style: {
                                fontWeight: '700'
                            }
                        },
                        children: 'Paula Inés Panesso Umbarila'
                    },
                    {
                        type: 'paragraph',
                        props: {
                            size: 's',
                            align: 'center',
                            style: {
                                fontWeight: '700'
                            }
                        },
                        children: 'Juan David Monsalve Arroave'
                    },
                    {
                        type: 'pdlList',
                        props: {
                            pdlList: [
                                {
                                    name: 'Luisa',
                                    role: 'host',
                                    quatityOfStars: 6,
                                },
                                {
                                    name: 'Luisa 2',
                                    role: 'host',
                                    quatityOfStars: 5,
                                },
                                {
                                    name: 'Luisa 3',
                                    role: 'host',
                                    quatityOfStars: 2,
                                },
                            ]
                        }
                    },
                    {
                        type: 'pdlList',
                        props: {
                            pdlList: [
                                {
                                    name: 'Luisa 4',
                                    role: 'host',
                                    quatityOfStars: 6,
                                },
                                {
                                    name: 'Luisa 5',
                                    role: 'host',
                                    quatityOfStars: 6,
                                },
                                {
                                    name: 'Luisa 6',
                                    role: 'host',
                                    quatityOfStars: 3,
                                },
                            ]
                        }
                    }
                ],
            },
            {
                type: 'button',
                props: {
                    styles: {
                        marginTop: '40px',
                        position: 'relative',
                    }
                },
                children: 'Continuar',
                action: 'next',
            }
        ]
    },
    {
        type: 'step',
        props: {
            order: 2,
        },
        bricks: [
            {
                type: 'paragraph',
                props: {
                    size: 's',
                    align: 'center',
                },
                children: '¡Vas súper!<br/><br/>Te queda poco para completar el Desafío C.'
            },
            {
                type: 'image',
                props: {
                    src: GoodHandCoordinator,
                    alt: 'good-hand-coordinator',
                    style: {
                        marginTop: '10px'
                    }
                }
            },
            {
                type: 'cardCopyKey',
                props: {
                    phrase: 'Cierre Filtro Coordinador',
                    nameChallenge: 'Desafío C',
                }
            },
            {
                type: 'button',
                props: {
                    styles: {
                        position: 'relative',
                        marginTop: '96px',
                    },
                },
                children: 'Finalizar',
                action: 'finish',
            }
        ]
    },
];