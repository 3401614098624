import WarningIcon from '../../resources/images/warning.png';
import ScreenToolsDirector from '../../resources/images/screen-tools-director.png';
import TutorialMyCoordinators from '../../resources/images/tutorial-my-coordinators.png';
import TutorialCoordinatorsList from '../../resources/images/tutorial-coordinators-list.png';
import GoodHandDirector from '../../resources/images/good-hand-director.png';

export const getBricksChallengeC = () => [
    {
        type: 'step',
        props: {
            order: 1
        },
        bricks: [
            {
                type: 'image',
                props: {
                    src: WarningIcon,
                    alt: 'Warning',
                    style: {
                        marginTop: '40px'
                    }
                }
            },
            {
                type: 'paragraph',
                props: {
                    size: 's',
                    align: 'center',
                },
                children: `Recuerda que hoy los Anfitriones podrán habilitar el Plan B que consiste en la posibilidad de volver a aplicar el ABC Filtro por parte de los Anfitriones, lo que permitirá a los Coordinadores y sus Anfitriones tener nuevos Validadores en la Plataforma hoy mismo. Estos nuevos Validadores podrán ser convocados y agendados al EPA hasta el <b>Día de Reglas del Juego</b>.
<br /><br />
En el <b>Desafío C</b> de los Anfitriones podrán agregar dos validaciones adicionales por cada cupo disponible.`
            },
            {
                type: 'button',
                props: {
                    styles: {
                        position: 'relative',
                        marginTop: '80px',
                    },
                },
                children: 'Continuar',
                action: 'next',
            }
        ]
    },
    {
        type: 'step',
        props: {
            order: 2
        },
        bricks: [
            {
                type: 'paragraph',
                props: {
                    size: 's',
                    align: 'center',
                },
                children: `Zona de Aprendizaje.
<br /><br />
En tu rol como Director(a) debes estar pendiente del Desempeño de tus dos equipos, cuentas con el menú Coordinadores, ahí podrás revisar la cantidad de Aspirantes agendados para cada EPA.
<br /><br />
A continuación te enseñamos cómo.`
            },
            {
                type: 'image',
                props: {
                    src: ScreenToolsDirector,
                    alt: 'Warning',
                }
            },
            {
                type: 'button',
                props: {
                    styles: {
                        position: 'relative',
                        marginTop: '80px',
                    },
                },
                children: 'Continuar',
                action: 'next',
            }
        ]
    },
    {
        type: 'step',
        props: {
            order: 3
        },
        bricks: [
            {
                type: 'paragraph',
                props: {
                    size: 's',
                    align: 'center',
                },
                children: `Dirígete a la pestaña <b>Coordinadores</b> en el menú que encuentras en la parte superior.`
            },
            {
                type: 'image',
                props: {
                    src: TutorialMyCoordinators,
                    alt: 'Warning',
                }
            },
            {
                type: 'paragraph',
                props: {
                    size: 's',
                    align: 'center',
                },
                children: `Luego encontrarás a tus Coordinadores, dando clic en alguno de ellos podrás acceder a sus Anfitriones y a los aspirantes de sus Anfitriones.`
            },
            {
                type: 'image',
                props: {
                    src: TutorialCoordinatorsList,
                    alt: 'Warning',
                }
            },
            {
                type: 'button',
                props: {
                    styles: {
                        position: 'relative',
                        marginTop: '80px',
                    },
                },
                children: 'Continuar',
                action: 'next',
            }
        ]
    },
    {
        type: 'step',
        props: {
            order: 4,
        },
        bricks: [
            {
                type: 'paragraph',
                props: {
                    size: 's',
                    align: 'center',
                },
                children: '¡Vas súper!<br/><br/>Te queda poco para completar el <b>Desafío C.</b>'
            },
            {
                type: 'image',
                props: {
                    src: GoodHandDirector,
                    alt: 'good-hand-coordinator',
                    style: {
                        marginTop: '10px'
                    }
                }
            },
            {
                type: 'cardCopyKey',
                props: {
                    phrase: 'Desafío C Director',
                    nameChallenge: 'Desafío C',
                }
            },
            {
                type: 'button',
                props: {
                    styles: {
                        position: 'relative',
                        marginTop: '96px',
                    },
                },
                children: 'Finalizar',
                action: 'finish',
            }
        ]
    }
];