import React, { useState } from "react";
import Modal from "../../atoms/modal";

export const ListModal = ({ isOpen, onClose, title, items }) => {
    const [currentSlide, setCurrentSlide] = useState(0);

    const handleKeyDown = (e) => {
        if (e.key === 'Escape') {
            onClose();
        }
    };

    const handleNavigation = (plus) => {
        const navigation = currentSlide + plus;
        if (navigation < 2 && navigation >= 0) {
            setCurrentSlide(navigation);
        }
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose} className="list-modal">
            <span className="close icon-close" onClick={onClose} onKeyDown={handleKeyDown}></span>
            {currentSlide === 0 && <>
                <span className="title">{title[0]}</span>
                <ul className="list-modal--list">
                    {items?.map((item) => (
                        <li key={`unique${item.id}`} className={`list-modal--list--item ${item.disabled ? 'disabled' : ''}`} onClick={item.onClick}>
                            {item.name}
                        </li>
                    ))}
                </ul>
            </>}
            {currentSlide === 1 && <>
                <span className="title">{title[1]}</span>
                <ul className="list-modal--list">
                    {items?.map((item) => (
                        <li key={`unique${item.id}`} className="list-modal--list--item">
                            {item.name}
                        </li>
                    ))}
                </ul>
            </>}
            <div className="carousel-controls">
                <div className={`carousel-control carousel-control--prev ${currentSlide === 0 ? 'disabled' : ''}`} onClick={() => handleNavigation(-1)} onKeyDown={() => { }}>
                    <span className="icon icon-previous"></span>
                </div>
                <ul className="carousel-control carousel-control--pagination">
                    <li key={0} className={`item ${0 === currentSlide ? 'active' : ''}`}></li>
                    <li key={1} className={`item ${1 === currentSlide ? 'active' : ''}`}></li>
                </ul>
                <div className={`carousel-control carousel-control--next ${currentSlide === 2 - 1 ? 'disabled' : ''}`} onClick={() => handleNavigation(1)} onKeyDown={() => { }}>
                    <span className="icon icon-next"></span>
                </div>
            </div>
        </Modal>
    )
};