const Checkbox = ({ label, name, checked, onChange, disabled, labelBackground, className, style, dynamic, isRadio, ariaLabel }) => {
    const customerClassName = `checkbox ${className}`
    return !dynamic ? (
        <label className={customerClassName} style={{ ...style, pointerEvents: disabled ? 'none' : 'all', opacity: disabled ? '0.5' : '1' }}>
            <input type="checkbox" name={name} checked={checked} onChange={onChange} disabled={disabled} aria-label={ariaLabel} />
            <span className="checkbox--checkmark"></span>
            {!labelBackground ? label : <span className="background-label">{label}</span>}
        </label>
    ) : (
        <label className={`${customerClassName} dynamic`} style={{ ...style, pointerEvents: disabled ? 'none' : 'all', opacity: disabled ? '0.5' : '1' }}>
            <input type={isRadio ? 'radio' : 'checkbox'} name={name} checked={checked} onChange={onChange} disabled={disabled}/>
            <span className="checkbox--checkmark"></span>
            {!labelBackground ? label : <span className="background-label">{label?.map((item) => <span dangerouslySetInnerHTML={{ __html: item }}></span>)}</span>}
        </label>
    );
};

Checkbox.displayName = 'Checkbox';

export default Checkbox;