import classNames from "classnames";
import Card from "../../atoms/card";
import MenuUserInfo from "../../molecules/menu-user-info";
import Checkbox from "../../atoms/checkbox";

export const CompleteRequirements = ({ className, user }) => {
    const classes = classNames('user-card-info user-info-date complete-requirements', {
        [className]: className,
    });

    const baseCheckboxes = [false, false, false, false, false];

    return (
        <>
            <Card className={classes} type="quaternary">
                <MenuUserInfo name={user?.name} role={user?.role} nameRole={user?.nameRole} range={user?.range} orientation="row" size="x-small" />
            </Card>
            {user?.items?.map((item) => <Card className={`user-card-info user-info-date ${item?.role}`} type="tertiary" role={item?.role} outline>
                <MenuUserInfo name={item?.name} role={item?.role} nameRole={item?.nameRole} range={item?.range} orientation="row" size="x-small" />
                <ul className="complete-requirements-checkboxes">
                    {baseCheckboxes.map((checkbox,index) => <li className="complete-requirements-checkboxes-item"><Checkbox checked={item.requirements[index]}/></li>)}
                </ul>
            </Card>)}
        </>
    );
};