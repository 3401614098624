import React, { useContext, useState } from 'react'
import Paragraph from '../../atoms/paragraph';
import Button from '../../atoms/button';
import Modal from '../../atoms/modal';
import { UserContext } from '../../../middleware/providers/user-context';
import { QueriesEpaDateCoordinatingSchedulesById } from '../../../middleware/services/useQueryEpaDateCoordinatingSchedules';

const circle = {
    host: '⚪',
    'new host': '⚪',
    coordinator: '🔴',
    'new coordinator': '🔴',
    director: '🟡',
    captain: '🔵',
};
export const NewCoordinatorsSchedule = ({ setModalSchedule, setAcceptData, setStep, step }) => {
    const { user } = useContext(UserContext)
    const { data } = QueriesEpaDateCoordinatingSchedulesById(user?._id)


    const handleDateSelection = (coordinator) => {
        setAcceptData(coordinator.schedule)


    }
    return (
        <>
            <span className="title">¡Nuevo(a) Director(a)! </span>
            <Paragraph size="s" align="center">
                Estos son los cronogramas de tus Nuevos(as) Coordinadores(as). El día de Inducción será mañana y el Día de Alineamiento será un día antes del EPA.
            </Paragraph>
            <div className="event-container">
                {data?.map((coordinator) => (

                    <div key={coordinator._id}>
                        <div className="card-host-user">
                            <span className="coordinator-name">{circle.coordinator} {coordinator.nameCoordinator}</span>
                        </div>
                        <>
                            {coordinator.schedule.map((event, index) => (

                                <>
                                    <div className="event">
                                        <span className='event-title'>{event.day}</span>
                                        <span className='event-date' onClick={() => {
                                            handleDateSelection(coordinator)
                                            setModalSchedule(true)
                                        }}>{event.date}.<a href="#" className="edit-icon">✎</a></span>
                                    </div>
                                </>
                            ))}
                        </>
                    </div>
                ))}
            </div>


            <Button className="director" onClick={() => setStep(step + 1)} >Continuar EPA</Button>

        </>
    )
}
