import SyncDirector from '../../resources/images/sync-director-182-182.png';
import ClapHandDirector from '../../resources/images/clap-hand-director.png';

export const getBricksChallengeA = () => [
    {
        type: 'step',
        props: {
            order: 1
        },
        bricks: [
            {
                type: 'paragraph',
                props: {
                    align: 'center',
                    size: 's',
                },
                children: `<b>¡Asegura la sincronización del equipo!</b>`
            },
            {
                type: 'image',
                props: {
                    src: SyncDirector,
                    alt: 'Brick 1'
                }
            },
            {
                type: 'paragraph',
                props: {
                    align: 'center',
                    size: 's',
                },
                children: `Para completar este desafío debes asegurar que tu Coordinador(a) está sincronizado(a) con sus Anfitriones y contigo.
                <br/><br/>
Ingresa al grupo de WhatsApp para cada EPA y verifica que tanto los dos Anfitriones como el(la) Coordinador(a) han enviado la captura de pantalla correspondiente.
                <br/><br/>
A continuación vas a encontrar dos switch que deben estar activados para poder continuar con tus actividades.`
            },
            {
                type: "button",
                props: {
                    styles: {
                        position: "relative",
                        marginTop: "80px",
                    },
                },
                children: "Continuar",
                action: "next",
            },
        ],
    },
    {
        type: 'step',
        props: {
            order: 2
        },
        bricks: [
            {
                type: 'menuUserInfo',
                props: {
                    name: 'Juan Perez',
                    role: 'coordinator',
                    nameRole: 'Coordinador(a)',
                    range: 7,
                    style: {
                        marginTop: '40px',
                    },
                }
            },
            {
                type: 'card',
                props: {
                    type: 'tertiary',
                    style: {
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        padding: '16px 19px',
                        marginBottom: '0px',
                    }
                },
                bricks: [
                    {
                        type: 'paragraph',
                        props: {
                            align: 'left',
                            size: 's',
                            style: {
                                margin: 0,
                            }
                        },
                        children: `Se encuentra sincronizado con sus Anfitriones.`
                    },
                    {
                        type: 'toggle',
                        props: {
                            checked: false,
                        }
                    }
                ]
            },
            {
                type: 'paragraph',
                props: {
                    align: 'center',
                    size: 'xs',
                },
                children: `Este switch se activa automáticamente cuando el(la) Coordinador(a) termine su Desafío A.`
            },
            {
                type: 'card',
                props: {
                    type: 'tertiary',
                    style: {
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        padding: '16px 19px',
                        marginTop: '48px',
                        marginBottom: '0px',
                    }
                },
                bricks: [
                    {
                        type: 'paragraph',
                        props: {
                            align: 'left',
                            size: 's',
                            style: {
                                margin: 0,
                            }
                        },
                        children: `Se encuentra sincronizado contigo.`
                    },
                    {
                        type: 'toggle',
                        props: {
                            checked: false,
                        }
                    }
                ]
            },
            {
                type: 'paragraph',
                props: {
                    align: 'center',
                    size: 'xs',
                },
                children: `Este switch debes activarlo manualmente cuando verifiques las capturas de pantalla.`
            },
            {
                type: 'button',
                props: {
                    styles: {
                        position: 'relative',
                        marginTop: '80px',
                    },
                },
                children: 'Continuar',
                action: 'next',
            }
        ]
    },
    {
        type: 'step',
        props: {
            order: 3,
        },
        bricks: [
            {
                type: 'paragraph',
                props: {
                    size: 's',
                    align: 'center',
                },
                children: '¡Genial!<br/><br/>Has completado el Desafío A.'
            },
            {
                type: 'image',
                props: {
                    src: ClapHandDirector,
                    alt: 'clap-hand-coordinator',
                    style: {
                        marginTop: '10px'
                    }
                }
            },
            {
                type: 'paragraph',
                props: {
                    size: 's',
                    align: 'center',
                    type: 'secondary',
                    withBackground: true,
                },
                children: 'Ahora que ya realizaste las actividades, continúa con el siguiente ▶️ VIDEO, para seguir con tu Inducción.<br/><br/>👋🏻 Ya nos vemos.'
            },
            {
                type: 'button',
                props: {
                    styles: {
                        position: 'relative',
                        marginTop: '96px',
                    },
                },
                children: 'Ver video',
                action: 'finish',
            }
        ]
    }
];