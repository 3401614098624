import { useState } from "react";

const CopyField = ({ value, setStep, text = "Copiar clave", style }) => {
    const [copied, setCopied] = useState(false);

    const handleCopy = () => {
        navigator.clipboard.writeText(value);
        setCopied(true);
        setStep?.();
        setTimeout(() => {
            setCopied(false);
        }, 2000);
    };
    
    return (
        <>
            <div className="copy-field" style={style}>
                <span className="icon-copy"></span>
                <button onClick={handleCopy}>{text}</button>
            </div>
            {copied && <span className="password-copied">Clave copiada</span>}
        </>
    );
};

export default CopyField;
