import KeyHandCoordinator from '../../resources/images/key-hand-coordinator-large.png';
import CoordinatorCheckList from '../../resources/images/coordinator-check-list.png';

export const getBricksChallengeB = () => [
    {
        type: 'step',
        props: {
            order: 1,
        },
        bricks: [
            {
                type: 'readingSection',
                props: {
                },
                bricks: [
                    {
                        type: 'paragraph',
                        props: {
                            size: 's',
                            align: 'left',
                        },
                        children: `Hambre`,
                    },
                    {
                        type: 'paragraph',
                        props: {
                            size: 's',
                            align: 'left',
                        },
                        children: `“Es responsabilidad del líder mantenerse siempre con Hambre. Todo liderazgo comienza con Hambre. En el momento en que el líder no tiene hambre, el líder deja de funcionar como tal. En el momento en que el líder deja de moverse, el líder no está dirigiendo y se requiere ambición para mantener al líder en movimiento.”`,
                    }
                ]
            },
            {
                type: 'quote',
                bricks: [
                    {
                        type: 'paragraph',
                        props: {
                            size: 's',
                            align: 'left',
                            style: {
                                margin: '0'
                            }
                        },
                        children: 'Tomado del libro:'
                    },
                    {
                        type: 'paragraph',
                        props: {
                            size: 's',
                            align: 'left',
                            style: {
                                margin: '0',
                                fontWeight: '700'
                            }
                        },
                        children: 'Lanzando una Revolución sobre el Liderazgo.'
                    },
                ]
            },
            {
                type: 'button',
                props: {
                    styles: {
                        position: 'relative',
                        marginTop: '96px',
                    },
                },
                children: 'Continuar',
                action: 'next',
            }
        ]
    },
    {
        type: 'step',
        props: {
            order: 2,
        },
        bricks: [
            {
                type: 'image',
                props: {
                    src: CoordinatorCheckList,
                    alt: 'Checklist',
                    style: {
                        marginTop: '10px'
                    }
                },
            },
            {
                type: 'paragraph',
                props: {
                    size: 's',
                    align: 'center',
                },
                children: `Ahora vas a poder revisar las respuestas que puso cada Anfitrión en su <b>Desafío B</b> del <b>Día 1</b> en las actividades de:`
            },
            {
                type: 'card',
                props: {
                    type: 'tertiary',
                    style: {
                        display: 'flex',
                        width: '284px',
                        padding: '10px 27px 10px 12px',
                        alignItems: 'center',
                        marginBottom: '0',
                        height: '50px',
                    }
                },
                bricks: [
                    {
                        type: 'paragraph',
                        props: {
                            size: 's',
                            align: 'center',
                            style: {
                                margin: '0',
                                display: 'flex',
                                alignItems: 'center',
                                gap: '17px',
                            }
                        },
                        children: '<span class="icon-state-selected" style="display: inline-flex; align-items: center; border: 2px solid white; border-radius: 4px; width: 22px; height: 22px; font-size: 12px;"></span> Selección Multiple de Hambre',
                    },
                ]
            },
            {
                type: 'card',
                props: {
                    type: 'tertiary',
                    style: {
                        display: 'flex',
                        width: '284px',
                        padding: '10px 27px 10px 12px',
                        alignItems: 'center',
                        marginTop: '16px',
                        marginBottom: '0',
                        height: '50px',
                    }
                },
                bricks: [
                    {
                        type: 'paragraph',
                        props: {
                            size: 's',
                            align: 'left',
                            style: {
                                margin: '0',
                                display: 'flex',
                                alignItems: 'center',
                                gap: '17px',
                            }
                        },
                        children: '<span class="icon-state-selected" style="display: inline-flex; flex: 1; align-items: center; border: 2px solid white; border-radius: 4px; width: 22px; height: 22px; font-size: 12px; padding: 0 4.62px 0 0.62px;"></span> Selección múltiple del avance de la Meta que esperan cumplir.',
                    },
                ]
            },
            {
                type: 'card',
                props: {
                    type: 'tertiary',
                    style: {
                        display: 'flex',
                        width: '284px',
                        padding: '10px 27px 10px 12px',
                        alignItems: 'center',
                        marginTop: '16px',
                        marginBottom: '0',
                        height: '50px',
                    }
                },
                bricks: [
                    {
                        type: 'paragraph',
                        props: {
                            size: 's',
                            align: 'left',
                            style: {
                                margin: '0',
                                display: 'flex',
                                alignItems: 'center',
                                gap: '17px',
                            }
                        },
                        children: '<span class="icon-pencil" style="display: inline-flex; flex: 1; align-items: center; width: 29px; height: 29px; font-size: 24px;"></span> Actividad escrita de cómo va a lograr un alto desempeño.',
                    },
                ]
            },
            {
                type: 'button',
                props: {
                    styles: {
                        position: 'relative',
                        marginTop: '96px',
                    },
                },
                children: 'Continuar',
                action: 'next',
            }
        ],
    },
   
    {
        type: 'step',
        props: {
            order: 3,
        },

        bricks: 'CoordinatorFirstDayChallengeCardB'
    },
    {
        type: 'step',
        props: {
            order: 4,
        },
        bricks: [
            {
                type: 'paragraph',
                props: {
                    size: 's',
                    align: 'center',
                },
                children: '¡Genial!<br/><br/>Has completado el Desafío B.'
            },
            {
                type: 'image',
                props: {
                    src: KeyHandCoordinator,
                    alt: 'good-hand-coordinator',
                    style: {
                        marginTop: '10px'
                    }
                }
            },
            {
                type: 'cardCopyKey',
                props: {
                    phrase: 'Desafío B Día 1 Coordinador',
                    nameChallenge: 'Desafío B',
                }
            },
            {
                type: 'button',
                props: {
                    styles: {
                        position: 'relative',
                        marginTop: '96px',
                    },
                },
                children: 'Finalizar',
                action: 'finish',
            }
        ]
    },
    {
        type: 'modal',
        id: 'candidateList0',
        props: {
          isOpen: false,
          skipStarsBackground: true,
          style: {
            width: '311px',
            height: '393px',
            padding: '40px 16px 0',
          },
        },
        bricks:'openModalCheckboxSelect',
    },
    {
        type: 'modal',
        id: 'candidateListOne',
        props: {
          isOpen: false,
          skipStarsBackground: true,
          style: {
            width: '311px',
            height: '393px',
            padding: '40px 16px 0',
          },
        },
        bricks:'openModalCheckboxSelectOne',
    },
    {
        type: 'modal',
        id: 'candidateListThree',
        props: {
          isOpen: false,
          skipStarsBackground: true,
          style: {
            width: '311px',
            height: '393px',
            padding: '40px 16px 0',
          },
        },
        bricks:'openModalCheckboxSelectThree',
    }
];