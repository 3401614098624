import { PdlItem } from "../../atoms/pdl-item";

export const PdlList = ({ pdlList }) => {
    return (
        <ul className="pdl-list">
            {pdlList.map((pdl, index) => (
                <PdlItem
                    key={`pdl-${index}`}
                    index={index + 1}
                    name={pdl.name}
                    role={pdl.role}
                    quatityOfStars={pdl.quatityOfStars}
                />
            ))}
        </ul>
    );
};